import React from 'react';
import StarRatingComponent from 'react-star-rating-component';
import Button from "../../components/UI/Forms/Button/Button";
import {ButtonsWrapper} from "../../components/BetSlipView/BetSlipView";
import * as actions from "../../store/actions";
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {setRating} from "../../store/actions";

class GiveARating extends React.Component {
    constructor() {
        super();

        this.state = {
            rating: 1
        };
    }

    onStarClick(nextValue, prevValue, name) {
        this.setState({rating: nextValue});
    }

    render() {
        const { rating } = this.state;
        const { setIsToBeRated, oponentUserId } = this.props;

        return (
            <div style={{fontSize: 30}}>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <label style={{fontSize: 18}}>Please rate your oponent: {rating}</label>
                <StarRatingComponent
                    name="rate1"
                    starCount={5}
                    value={rating}
                    onStarClick={this.onStarClick.bind(this)}
                    editing={true}
                />
                    <Button
                        type="button"
                        color="secondary"
                        margin
                        contain
                        onClick={async () => {
                            await this.props.setRating(oponentUserId, rating);
                            setIsToBeRated(false);
                        }}
                    >
                        Send rating
                    </Button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ firebase, firestore }) => ({
    userId: firebase.auth.uid,
    firestore: firestore
});

const mapDispatchToProps = {
    setRating: actions.setRating
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ))(GiveARating);

// export default GiveARating;
