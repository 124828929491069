import React, {useState} from "react";
import styled from "styled-components";
import { SideWrapper } from "../../../hoc/layout/elements";
import { withRouter } from "react-router-dom";

import NavItem from "../NavItems/NavItem/NavItem";
import Logo from "../../Logo/Logo";
import SearchIcon from "../../../assets/SearchIcon.png";
import MyBetsIcon from "../../../assets/MyBetsIcon.png";
import MyBetsIconActive from "../../../assets/MyBetsIconActive.png";
// import HomeActive from "../../../assets/HomeActive.png";
import RankingsIcon from "../../../assets/RankingsIcon.png";
// import NotificationsIcon from "../../../assets/NotificationsIcon.png";
import SettingsIcon from "../../../assets/SettingsIcon.png";
import InviteIcon from "../../../assets/InviteIcon.png";
// import HomeIcon from "../../../assets/HomeIcon.png";
import Img from "../../UI/Img";
import ItemLink from "../../UI/ItemLink";
// import * as actions from "../../../store/actions/index";
// import Badge from '@material-ui/core/Badge';
import CustomBadge from "../../UI/CustomBadge";
import Invitation from "../../Invitation/Invitation";
import FinalConfirmation from "../../BetSlipView/FinalConfirmation";

const NavItemWrapper = styled.ul`
  margin-top: 4.5rem;
  display: flex;
  flex-direction: column;
`;

const SideLeft = ({
  loggedIn,
  location,
  emailVerified,
  // profileIsLoaded,
  // profileIsEmpty
}) => {

  const [invitation, setInvitation] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);


  return loggedIn &&
  emailVerified &&
  (location.pathname === "/" ||
      location.pathname.toLowerCase() === "/mybets" ||
      location.pathname === "/explore" ||
      location.pathname.toLowerCase().includes("/explore/") ||
      location.pathname === "/notifications" ||
      location.pathname.toLowerCase().includes("/profile/") ||
      location.pathname === "/rankings" ||
      location.pathname === "/settings" || location.pathname === "/basicSettings" || location.pathname === "/advancedSettings" ||
      location.pathname === "/betSlipWallet" || location.pathname === "/reportBug" || location.pathname === "/tokenWallet" ||
      location.pathname === "/invite"
  ) ? (
      <SideWrapper color="white">
        <Logo/>
        <NavItemWrapper>
          <ItemLink side active={location.pathname === "/"}>
            <Img
                src={SearchIcon}
                dimensions={{
                  width: "1.4rem",
                  height: "auto"
                }}
            />
            <NavItem side link="/">
              Explore
            </NavItem>
          </ItemLink>
          <ItemLink side active={location.pathname === "/myBets"}>
            <Img
                dimensions={{
                  width: "1.4rem",
                  height: "auto"
                }}
                src={
                  location.pathname === "/myBets" ? MyBetsIconActive : MyBetsIcon
                }
            />
            <NavItem side link="/myBets">
              My Bets
            </NavItem>
          </ItemLink>

          <ItemLink side active={location.pathname === "/rankings"}>
            <Img
                dimensions={{
                  width: "1.4rem",
                  height: "auto"
                }}
                src={RankingsIcon}
                active={location.pathname === "/rankings"}
            />
            <NavItem side link="/rankings">
              Rankings
            </NavItem>
          </ItemLink>

          <ItemLink side active={location.pathname === "/notifications"}>
            <CustomBadge />
            <NavItem side link="/notifications">
              Notifications
            </NavItem>
          </ItemLink>

          <ItemLink side active={location.pathname === "/settings"}>
            <Img
                dimensions={{
                  width: "1.4rem",
                  height: "auto"
                }}
                src={SettingsIcon}
            />
            <NavItem side link="/settings">
              Settings
            </NavItem>
          </ItemLink>

          <ItemLink side active={location.pathname === "/mock"}>
            <Img
                dimensions={{
                  width: "1.4rem",
                  height: "auto"
                }}
                src={InviteIcon}
            />
            <NavItem side css={{color: 'black', backgroundColor: 'red'}} color={'black'} clicked={() => setInvitation(true)} link={{pathname: "/", invitation: true}}>
              Invite
            </NavItem>
          </ItemLink>

        </NavItemWrapper>

        {
          invitation  ?
              <Invitation
                  isOpened={invitation}
                  close={() => setInvitation(false)}
                  setIsFinalConfirmation={setIsFinalConfirmation}
              />
              :
              null
        }

        {
          isFinalConfirmation ?
              <FinalConfirmation
                  opened={isFinalConfirmation}
                  close={() => setIsFinalConfirmation(false)}
                  type={'invitation'}
              />
              :
              null
        }



      </SideWrapper>
    )
      : null
};



export default withRouter(SideLeft);
