import React, {useEffect, useState} from 'react';
import theme from '../../utils/theme';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {firestoreConnect} from 'react-redux-firebase';
import {makeStyles} from '@material-ui/core/styles';
import {Button} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import * as actions from '../../store/actions';
import FinalConfirmation from '../../components/BetSlipView/FinalConfirmation';
import {
    countries,
    countriesForDropdown,
    CRYPTO_WALLETS,
    CURRENCIES,
    SEXES,
    TRANSACTION_METHODS,
} from '../../utils/utilFile';
import {TIMEZONES} from '../../utils/timezones';
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown';

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%',
    },
    backButton: {
        marginTop: '20px',
        backgroundColor: theme.colors.main,
        color: theme.colors.secondaryColor,
        fontSize: '12px',
        border: 'none',
    },
    globalTextH1: {
        color: theme.colors.secondaryColor,
        textAlign: 'center',
        fontSize: '18px',
        marginTop: '-25px',
    },
    saveButtonContainer: {
        float: 'right',
    },
    saveButton: {
        marginTop: '-40px',
        backgroundColor: theme.colors.main,
        color: theme.colors.secondaryColor,
        fontSize: '12px',
        border: 'none',
        width: 'auto',
        marginRight: '0px',
        paddingRight: '0px',
    },
    pinkBarContainer: {
        backgroundColor: theme.colors.secondaryColor,
        width: '100%',
        height: '40px',
        alignItems: 'center',
        display: 'flex',
    },
    pinkBarText: {
        color: theme.colors.main,
        fontSize: '16px',
        marginLeft: '10px',
    },
    lineContainer: {
        width: '90%',
        margin: 'auto',
    },
    lineWithTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px',
    },
    lineText: {
        marginLeft: '5px',
    },
    lineIconContainer: {
        height: '100%',
    },
    input: {
        border: '1px',
        borderStyle: 'groove',
        borderColor: theme.colors.fourthColor,
        marginRight: '5px',
        borderRadius: '3px',
        height: '30px',
        width: '150px',
        display: 'flex',
        alignItems: 'center',
    },
    dropdownContainer: {
        width: '150px',
        marginRight: '5px',
        border: '1px',
        borderStyle: 'groove',
        borderRadius: '3px',
        borderColor: theme.colors.fourthColor,
    },
    dropdown: {
        border: 'none !important',
        backgroundColor: '#f3f4f6 !important',
    },
});

const DATE_REGEX_DD_MM_YYYY = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;
const DATE_REGEX_YYYY_MM_DD = /^\d{4}[\-/](0?[1-9]|1[012])([\-/])(0?[1-9]|[12][0-9]|3[01])$/;

const AdvancedSettings = ({profile, updateProfile}) => {
    const classes = useStyles();

    const [phoneNumber, setPhoneNumber] = useState(profile.phoneNumber || '');
    const [birthDay, setBirthDay] = useState(profile.birthDay || '');
    const [transactionMethod, setTransactionMethod] = useState(
        profile.transactionMethod || '',
    );
    const [cryptoWallet, setCryptoWallet] = useState(profile.cryptoWallet || '');
    const [currency, setCurrency] = useState(profile.currency || '');
    const [address, setAddress] = useState(profile.address || '');
    const [sex, setSex] = useState(profile.sex || '');
    const [timezone, setTimezone] = useState(profile.timezone || '');
    const [hasChanges, setHasChanges] = useState(false);
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
    const [updateProfileModal, setUpdatedProfileModal] = useState(false);

    const PHONE_NUMBER_REGEX = /^\+?[1-9]\d{8,14}$/;
    const regex = new RegExp(PHONE_NUMBER_REGEX);
    const dateRegexDD_MM_YYYY = new RegExp(DATE_REGEX_DD_MM_YYYY);
    const dateRegexYYYY_MM_DD = new RegExp(DATE_REGEX_YYYY_MM_DD);

    useEffect(() => {
        const hasPassedPhoneNumberRegexTest = regex.test(phoneNumber);
        const isValidDate =
            dateRegexDD_MM_YYYY.test(birthDay) || dateRegexYYYY_MM_DD.test(birthDay);

        if (
            ((phoneNumber !== profile.phoneNumber && phoneNumber.length > 0) ||
                (birthDay.length !== 0 && birthDay !== profile.birthDay) ||
                (transactionMethod !== profile.transactionMethod &&
                    transactionMethod.length !== 0) ||
                (cryptoWallet !== profile.cryptoWallet && cryptoWallet.length !== 0) ||
                (currency !== profile.currency && currency.length !== 0) ||
                (address !== profile.address && address.length !== 0) ||
                (sex !== profile.sex && sex.length !== 0) ||
                (timezone !== profile.timezone && timezone.length !== 0)) &&
            (birthDay.length === 0 || isValidDate)
        ) {
            if (hasPassedPhoneNumberRegexTest) {
                setInvalidPhoneNumber(false);
                setHasChanges(true);
            } else if (phoneNumber.length === 0) {
                setInvalidPhoneNumber(false);
                setHasChanges(true);
            } else {
                setInvalidPhoneNumber(true);
                setHasChanges(false);
            }
        } else {
            if (phoneNumber.length === 0) setInvalidPhoneNumber(false);
            setHasChanges(false);
        }
    }, [
        profile,
        phoneNumber,
        birthDay,
        transactionMethod,
        cryptoWallet,
        currency,
        address,
        sex,
        timezone,
    ]);

    const save = async () => {
        let changedBirthDay = await editBirthDate();

        const editedProfile = {
            phoneNumber: phoneNumber,
            birthDay: changedBirthDay || birthDay,
            transactionMethod: transactionMethod,
            cryptoWallet: cryptoWallet,
            currency: currency,
            address: address,
            sex: sex,
            timezone: timezone,
        };
        await updateProfile(editedProfile, 'advancedSettings');

        setUpdatedProfileModal(true);
    };

    const editBirthDate = async () => {
        let changedBirthDay;
        if (birthDay.length > 7) {
            changedBirthDay = birthDay.split(/[-/]+/).join('-');

            if (changedBirthDay.split('-')[2].length === 4) {
                changedBirthDay = changedBirthDay.split('-').reverse().join('-');
            }

            let changedBirthDayArray = changedBirthDay.split('-');
            if (changedBirthDayArray[0].length < 4) {
                if (changedBirthDayArray[0].length === 3) {
                    changedBirthDayArray[0] = '0' + changedBirthDayArray[0];
                } else if (changedBirthDayArray[0].length === 2) {
                    changedBirthDayArray[0] = '00' + changedBirthDayArray[0];
                } else if (changedBirthDayArray[0].length === 1) {
                    changedBirthDayArray[0] = '000' + changedBirthDayArray[0];
                }
            }
            if (changedBirthDayArray[1].length < 2) {
                changedBirthDayArray[1] = '0' + changedBirthDayArray[1];
            }
            if (changedBirthDayArray[2].length < 2) {
                changedBirthDayArray[2] = '0' + changedBirthDayArray[2];
            }

            changedBirthDay =
                changedBirthDayArray[0] +
                '-' +
                changedBirthDayArray[1] +
                '-' +
                changedBirthDayArray[2];
            setBirthDay(changedBirthDay);
            return changedBirthDay;
        }
    };

    const onFieldChanges = async (field, e) => {

        switch (field) {
            case 'phoneNumber':
                setPhoneNumber(e.target.value);
                break;
            case 'birthDay':
                setBirthDay(e.target.value);
                break;
            case 'transactionMethod':
                setTransactionMethod(e.target.innerText);
                break;
            case 'cryptoWallet':
                setCryptoWallet(e.target.innerText);
                break;
            case 'currency':
                setCurrency(e.target.innerText);
                break;
            case 'sex':
                setSex(e.target.innerText);
                break;
            case 'timezone':
                setTimezone(e.target.innerText);
                break;
            case 'address':
                setAddress(e.target.value);
                break;
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.backButton}>
                <Link to={'/settings'}> {'< Back'} </Link>
            </div>
            <h1 className={classes.globalTextH1}>Advanced Settings</h1>
            {hasChanges ? (
                <div className={classes.saveButtonContainer}>
                    <Button className={classes.saveButton} onClick={() => save()}>
                        {' '}
                        Save{' '}
                    </Button>
                </div>
            ) : (
                <></>
            )}

            <div className={classes.pinkBarContainer}>
                <h1 className={classes.pinkBarText}> @{profile.username} </h1>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Phone Number</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input
                                type="text"
                                value={phoneNumber}
                                placeholder={profile.phoneNumber || 'Phone number..'}
                                style={{
                                    marginLeft: '10px',
                                    borderStyle: 'none',
                                    backgroundColor: theme.colors.main,
                                }}
                                onChange={(e) => onFieldChanges('phoneNumber', e)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Birthdate</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input
                                type="date"
                                value={birthDay}
                                placeholder={'yyyy-mm-dd'}
                                style={{
                                    marginLeft: '10px',
                                    borderStyle: 'none',
                                    backgroundColor: theme.colors.main,
                                }}
                                onChange={(e) => onFieldChanges('birthDay', e)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Transaction Methods</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.dropdownContainer}>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={TRANSACTION_METHODS}
                                value={transactionMethod}
                                placeholder={profile.transactionMethod || 'Select method..'}
                                className={classes.dropdown}
                                onChange={(event) => onFieldChanges('transactionMethod', event)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Crypto Wallets</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.dropdownContainer}>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={CRYPTO_WALLETS}
                                value={cryptoWallet}
                                placeholder={profile.cryptoWallet || 'Select wallet..'}
                                className={classes.dropdown}
                                onChange={(event) => onFieldChanges('cryptoWallet', event)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Preferred Currency</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.dropdownContainer}>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={CURRENCIES}
                                value={currency}
                                placeholder={profile.currency || 'Select currency..'}
                                className={classes.dropdown}
                                onChange={(event) => onFieldChanges('currency', event)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Address</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input
                                type="text"
                                value={address}
                                placeholder={profile.address || 'Enter address..'}
                                style={{
                                    marginLeft: '10px',
                                    borderStyle: 'none',
                                    backgroundColor: theme.colors.main,
                                }}
                                onChange={(e) => onFieldChanges('address', e)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Sex</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.dropdownContainer}>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={SEXES}
                                value={currency}
                                placeholder={profile.sex || 'Select gender..'}
                                className={classes.dropdown}
                                onChange={(event) => onFieldChanges('sex', event)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Timezone</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.dropdownContainer}>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={TIMEZONES}
                                value={timezone}
                                placeholder={profile.timezone || 'Select timezone..'}
                                className={classes.dropdown}
                                onChange={(event) => onFieldChanges('timezone', event)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            {invalidPhoneNumber ? (
                <div style={{textAlign: 'center', color: 'red', marginTop: '10px'}}>
                    {' '}
                    Invalid phone number{' '}
                </div>
            ) : (
                <></>
            )}

            {updateProfileModal ? (
                <FinalConfirmation
                    opened={updateProfileModal}
                    close={() => setUpdatedProfileModal(false)}
                    type={'updatedProfile'}
                />
            ) : null}
        </div>
    );
};

const mapStateToProps = ({firebase, firestore}) => ({
    profile: firebase.profile,
    usernames: firestore.ordered.usernames,
});

const mapDispatchToProps = {
    updateProfile: actions.updateProfile,
    uploadProfilePicture: actions.uploadProfilePicture,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AdvancedSettings,
);
