import React, { useState } from 'react';
import Modal from '../../../components/UI/Modal/Modal';
import ConfirmBet from '../../../components/BetSlipView/ConfirmBet';
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";

const ConfirmBetModal = ({
  type,
  close,
  opened,
  newBet,
  users,
  bet,
  openedConfirmBet,
  closeConfirmBet,
  setFinalConfirmation
}) => {
  return (
    <>
      <Modal opened={opened} close={close}>
        <ConfirmBet
          opened={opened}
          closeBetSlip={close}
          close={close}
          newBet={newBet}
          // bet={bet}
          type={type}
          users={users}
          // isFinalConfirmation={isFinalConfirmation}
          setFinalConfirmation={setFinalConfirmation}
        />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ bets, users, firebase, firestore }) => ({
  users: firestore.ordered.users,
});

export default compose(
    connect(mapStateToProps),
    firestoreConnect(()=>{
      return [
        {
          collection: 'users'
        }
      ]
    }))
(ConfirmBet);
