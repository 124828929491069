export const keywordRemover = (s) => {
  var words = [
    'of',
    'the',
    'in',
    'on',
    'at',
    'to',
    'a',
    'is',
    'wins',
    'beats',
    'destroys',
    'with',
    'from',
    'for',
    'tops',
    'loses',
    'by',
    'bet',
    'bets',
    'another',
    '',
    ' ',
  ];
  var re = new RegExp('\\b(' + words.join('|') + ')\\b', 'g');
  return (s || '').replace(re, '').replace(/[ ]{2,}/, ' ');
};

export const populateTagsArray = (bets) => {
  const tagsFunc = [];
  //const sendBetFunc = [];
  const tagsRepeated = [];
  console.log('bets1: ', bets);

  //console.log('Global/Direct: ', JSON.stringify(bets));
  //console.log('Global/Direct: ', bets[7].sendBet);
  //console.log('Tags: ', bets[7].tags);
  bets
    .map((bet) => {
      return bet.tags;
    })

    .forEach((tags) => {
      tags.forEach((element) => {
        tagsFunc.push(element);
      });
    });



    //function getFields(input, field) {
    //var output = [];
    //for (var i=0; i < input.length ; ++i)
        //output.push(input[i][field]);
    //return output;
  //}

  //var resultvince = getFields(bets, "sendBet"); // returns [ 1, 3, 5 ]


  //console.log('result2: ', tagsFunc);
  const result = {};
  for (var i = 0; i < tagsFunc.length; ++i) {
    if (!result[tagsFunc[i]]) result[tagsFunc[i]] = 0;
    ++result[tagsFunc[i]];
  }
  console.log('result: ', result);
  const resultsSorted = Object.keys(result).sort(function (a, b) {
    return result[b] - result[a]; /*no use*/
    console.log('result[b]: ', result[b]); /*no use*/
  });

  //console.log('resultvince: ', resultvince);
  console.log('resultsSorted: ', resultsSorted);


  //const check = [
    //{ tags: resultsSorted, sendBet: resultvince },
  //];

  //console.log('check: ', check);

  return resultsSorted;


};





const countries_list = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre &amp; Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts &amp; Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad &amp; Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks &amp; Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

const transactionMethods = ['Cash', 'Paypal', 'Venmo', 'E-transfer'];

const cryptoWallets = ['BTC', 'ETH'];

const currencies = ['USD', 'CDN'];

const sex = ['Male', 'Female'];

export const countries = countries_list.map((country) => {
  return { value: country, label: country };
});

export const countriesForDropdown = countries_list.map((country) => {
  return { value: country, text: country };
});

export const TRANSACTION_METHODS = transactionMethods.map((method) => {
  return { value: method, text: method };
});

export const CRYPTO_WALLETS = cryptoWallets.map((method) => {
  return { value: method, text: method };
});

export const CURRENCIES = currencies.map((currency) => {
  return { value: currency, text: currency };
});

export const SEXES = sex.map((sex) => {
  return { value: sex, text: sex };
});
