const theme = {
  colors: {
    main: "#f3f4f6",
    mainDark: "#201C38",
    secondaryColor: "#723eff",
    tertiaryColor: "#59d4ff",
    textColor: "#201c38",
    whiteColor: "#fff",
    greyColor: "#969697",
    errorRed: "#ff5757",
    shadow: "rgba(0,0,0,.2)",
    shadowSecondary: "rgba(114, 62, 255, 0.4)",
    fourthColor: "rgba(166, 135, 251, .5)",
  },

  mediaQueries: {
    smallest: `only screen and (max-width: 23.5em)`,
    smallerSmallestHeight: `only screen and (max-height: 28em)`,
    biggerSmall: `only screen and (min-width: 35em)`,
    smallerSmall: `only screen and (max-width: 35em)`,
    smallerSmallishHeight: `only screen and (max-height: 43.75em)`,

    biggerMedium: `only screen and (min-width: 56em)`,
    biggerMediumHeight: `only screen and (min-height: 56em)`,
    smallerMediumHeight: `only screen and (max-height: 56em)`,

    medium: `only screen and (max-width: 56em)`,
    large: `only screen and (max-width: 80em)`,
    biggerLarge: `only screen and (min-width: 80em)`,
    largeHeight: `only screen and (min-height: 80em)`,

    largest: `only screen and (max-width: 90em)`,

    ipadPortrait: `all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait)`,

    ipadLandscape: `all and (device-width: 768px) and (device-height: 1024px) and (orientation: landscape)`,

    iphoneX: `all and (device-width: 812px) and (device-height: 375px)`,
    iphoneXr: `all and (device-width: 896px) and (device-height: 414px)`,
    iphone6Plus: `all and (device-width: 736px) and (device-height: 414px)`,
    iphone6: `all and (device-width: 667px) and (device-height: 375px)`,
    iphone5: `all and (device-width: 568px) and (device-height: 320px)`,
    ipad: `all and (device-width: 1024px) and (device-height: 768px)`,
    ipadPro: `all and (device-width: 1336px) and (device-height: 1024px)`
  }
};

export default theme;
