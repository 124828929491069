export function getCoinsAmountFromJurorVote(user, bet){

    if(user.numberOfJurorVotes === 0){
        if(user.userNumber < 101) return 5000;
        if(user.userNumber < 251) return 4500;
        if(user.userNumber < 501) return 4000;
        if(user.userNumber < 1001) return 3500;
        if(user.userNumber < 2001) return 3000;
        if(user.userNumber < 5001) return 2500;
        if(user.userNumber < 10001) return 1250;
        if(user.userNumber < 50001) return 750;
        if(user.userNumber < 250001) return 250;
    }

    const _4HoursInMilliseconds = 1000 * 60 * 60 * 4;
    const _8HoursInMilliseconds = 1000 * 60 * 60 * 8;
    const _12HoursInMilliseconds = 1000 * 60 * 60 * 12;
    const _24HoursInMilliseconds = 1000 * 60 * 60 * 24;
    const _72HoursInMilliseconds = 1000 * 60 * 60 * 72;

    const timeDiff = Date.now() - bet.wentToDisputeTimestamp;

    if(timeDiff <= _4HoursInMilliseconds) return 10;
    if(timeDiff <= _8HoursInMilliseconds) return 20;
    if(timeDiff <= _12HoursInMilliseconds) return 30;
    if(timeDiff <= _24HoursInMilliseconds) return 40;
    if(timeDiff <= _72HoursInMilliseconds) return 50;
    if(timeDiff > _72HoursInMilliseconds) return 60;

}

export function getCoinsAmountFromSignup(userNumber){
    if(userNumber < 101) return 10000;
    if(userNumber < 251) return 9000;
    if(userNumber < 501) return 8000;
    if(userNumber < 1001) return 7000;
    if(userNumber < 2001) return 6000;
    if(userNumber < 5001) return 5000;
    if(userNumber < 10001) return 2500;
    if(userNumber < 50001) return 1250;
    if(userNumber < 250001) return 500;
}

export function getCoinsFromCompleteProfile(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}

export function getCoinsFromFirstBetSlipGlobal(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}

export function getCoinsFromFirstBetSlipPrivate(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}

export function getCoinsFromFirstBetWon(userNumber){
    if(userNumber < 101) return 10000;
    if(userNumber < 251) return 9000;
    if(userNumber < 501) return 8000;
    if(userNumber < 1001) return 7000;
    if(userNumber < 2001) return 6000;
    if(userNumber < 5001) return 5000;
    if(userNumber < 10001) return 2500;
    if(userNumber < 50001) return 1500;
    if(userNumber < 250001) return 500;
}

export function getCoinsFromSharingToSocial(userNumber){
    if(userNumber < 101) return 10000;
    if(userNumber < 251) return 9000;
    if(userNumber < 501) return 8000;
    if(userNumber < 1001) return 7000;
    if(userNumber < 2001) return 6000;
    if(userNumber < 5001) return 5000;
    if(userNumber < 10001) return 2500;
    if(userNumber < 50001) return 1500;
    if(userNumber < 250001) return 500;
}

export function getCoinsFromReportingBug(userNumber){
    if(userNumber < 101) return 2500;
    if(userNumber < 251) return 2250;
    if(userNumber < 501) return 2000;
    if(userNumber < 1001) return 1750;
    if(userNumber < 2001) return 1500;
    if(userNumber < 5001) return 1250;
    if(userNumber < 10001) return 625;
    if(userNumber < 50001) return 375;
    if(userNumber < 250001) return 125;
}

export function getCoinsFromRequestMoreCoins(userNumber){
    if(userNumber < 101) return 20000;
    if(userNumber < 251) return 18000;
    if(userNumber < 501) return 16000;
    if(userNumber < 1001) return 14000;
    if(userNumber < 2001) return 12000;
    if(userNumber < 5001) return 10000;
    if(userNumber < 10001) return 5000;
    if(userNumber < 50001) return 3000;
    if(userNumber < 250001) return 1000;
}

export function getCoinsFrom5StraightWins(userNumber){
    if(userNumber < 101) return 2500;
    if(userNumber < 251) return 2250;
    if(userNumber < 501) return 2000;
    if(userNumber < 1001) return 1750;
    if(userNumber < 2001) return 1500;
    if(userNumber < 5001) return 1250;
    if(userNumber < 10001) return 625;
    if(userNumber < 50001) return 375;
    if(userNumber < 250001) return 125;
}

export function getCoinsFrom10StraightWins(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}

export function getCoinsFrom5Opponents(userNumber){
    if(userNumber < 101) return 2500;
    if(userNumber < 251) return 2250;
    if(userNumber < 501) return 2000;
    if(userNumber < 1001) return 1750;
    if(userNumber < 2001) return 1500;
    if(userNumber < 5001) return 1250;
    if(userNumber < 10001) return 625;
    if(userNumber < 50001) return 375;
    if(userNumber < 250001) return 125;
}

export function getCoinsFrom10Opponents(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}

export function getCoinsFrom20FreeBets(userNumber){
    if(userNumber < 101) return 2500;
    if(userNumber < 251) return 2250;
    if(userNumber < 501) return 2000;
    if(userNumber < 1001) return 1750;
    if(userNumber < 2001) return 1500;
    if(userNumber < 5001) return 1250;
    if(userNumber < 10001) return 625;
    if(userNumber < 50001) return 375;
    if(userNumber < 250001) return 125;
}

export function getCoinsFrom100FreeBets(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}

export function getCoinsFrom5JuriesServed(userNumber){
    if(userNumber < 101) return 5000;
    if(userNumber < 251) return 4500;
    if(userNumber < 501) return 4000;
    if(userNumber < 1001) return 3500;
    if(userNumber < 2001) return 3000;
    if(userNumber < 5001) return 2500;
    if(userNumber < 10001) return 1250;
    if(userNumber < 50001) return 750;
    if(userNumber < 250001) return 250;
}
