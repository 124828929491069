import React from "react";
import "./SearchInput.css";

export const SearchInput = ({placeholder, handleSearchChange})=>{

    return (
        <div className="container">
            <input type="search" placeholder={placeholder} className="input" onChange={e=> handleSearchChange(e.target.value)}/>
            <i className="fas fa-search" />
        </div>
    )
};
