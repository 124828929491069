import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 3rem;
  flex-direction: column;

  @media ${props => props.theme.mediaQueries.biggerSmall} {
    width: 50%;
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    width: 60%;
  } */

  @media ${props => props.theme.mediaQueries.smallest} {
    margin-bottom: ${({ register }) => register && "2rem"};
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    margin-bottom: 2rem;
  }

  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-bottom: 1rem;
  } */
`;

const StyledInput = styled.input`
  width: 100%;
  background-color: var(--color-white);
  padding: ${({ profile }) => (profile ? "0.8rem 2rem" : "1.2rem 2rem")};

  color: var(--color-mainDark);
  font-weight: 500;
  font-size: ${({ register }) => (register ? "1rem" : "1.5rem")};
  border-radius: 2rem;
  border: none;

 /*  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: ${({ register }) => (register ? "1rem" : "1.5rem")};
  }
  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1rem;
  } */

  &::placeholder {
    color: var(--color-mainDark);
  }

/*   @media ${props => props.theme.mediaQueries.smallest} {
    padding: ${({ register, profile }) =>
      register || profile ? "0.5rem 2rem" : "1.2rem 2rem"};
  }

  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    padding: ${({ register, profile }) =>
      register || profile ? "0.3rem 1rem" : "0.8rem 1.2rem"};
  } */
`;

const Error = styled.div`
  color: var(--color-errorRed);
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transform: translateY(${({ show }) => (show ? "20px" : "10px")});
  transition: all 0.1s;
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 500;
  font-size: 1.2rem;

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 0.8rem;
    bottom: 1rem;
  } */
`;

const InputAuth = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <InputWrapper {...props}>
      <StyledInput {...field} {...props} onfocus="blur()" />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </InputWrapper>
  );
};

export default InputAuth;
