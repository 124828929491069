import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Formik, Field } from "formik";
import * as Yup from "yup";

import Heading from "../../../components/UI/Heading";
import Button from "../../../components/UI/Forms/Button/Button";
import Message from "../../../components/UI/Message";
import queryString from "query-string";
// import CustomLink from "../../../components/UI/CustomLink";
import InputAuth from "../../../components/UI/Forms/Input/InputAuth";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";

// import { LoginSignupFormWrapper } from "../Login/SignupForm";

import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -6rem;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const VerifyEmailWrapper = styled(FormWrapper)`
  background-color: var(--color-mainDark);
`;

const CustomRefreshLink = styled.a`
  text-decoration: none;
  color: var(--color-white);
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  transition: all 0.2s;
  border-bottom: 1px solid var(--color-white);

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }

  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

async function processVerification(
  location,
  handleVerification,
  handleResetPassword
) {
  if (queryString.parse(location.search).mode === "verifyEmail") {
    await handleVerification(queryString.parse(location.search).oobCode);
  } else if (queryString.parse(location.search).mode === "resetPassword") {
    await handleResetPassword(queryString.parse(location.search).oobCode);
  }
}

const newPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("The passoword is required.")
    .min(8, "Too short.")
});

const Action = ({
  handleVerification,
  error,
  loading,
  cleanUp,
  history,
  match,
  location,
  errorRecover,
  successRecover,
  handleResetPassword,
  processResetPassword
}) => {
  useEffect(() => {
    processVerification(location, handleVerification, handleResetPassword);
  }, []);
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  /*  const [successPassword, setSuccessPassword] = useState(false); */

  let content = "";

  if (queryString.parse(location.search).mode === "verifyEmail") {
    content = (
      <VerifyEmailWrapper>
        <Wrapper>
          {!error && (
            <>
              <Heading noMargin color="white" bold size="h3">
                Your email has been verified. Please Go Back to Wannabet!
              </Heading>

              {/*  <CustomLink link="/login" color="white" register>
                  Back to Login Page
                </CustomLink> */}

              {/*   <Button
                contain
                color={"secondary"}
                onClick={() => history.push("/")}
              >
                Login
              </Button> */}
            </>
          )}
          <MessageWrapper>
            <Message error show={error}>
              {error}
            </Message>
          </MessageWrapper>
        </Wrapper>
      </VerifyEmailWrapper>
    );
  } else if (
    queryString.parse(location.search).mode === "resetPassword" &&
    !successRecover
  ) {
    content = (
      <Formik
        initialValues={{
          newPassword: ""
        }}
        validationSchema={newPasswordSchema}
        onSubmit={async (values, { setSubmitting }) => {
          await processResetPassword(
            queryString.parse(location.search).oobCode,
            values.newPassword
          );
          /*  if (successRecover) {
            setSuccess(true);
          } */
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <VerifyEmailWrapper>
            {
              <StyledForm>
                <Heading size="h3" className="h3register" bold color="white">
                  Reset your Password
                </Heading>

                <Field
                  type="password"
                  name="newPassword"
                  placeholder="New password..."
                  component={InputAuth}
                />

                {
                  <Button
                    /* disabled={!isValid || isSubmitting} */
                    type="submit"
                    contain
                    auth
                    color="secondary"
                    classname="buttonlogin"
                    margin
                  >
                    Save
                  </Button>
                }

                {
                  <MessageWrapper>
                    <Message error show={errorRecover}>
                      {errorRecover}
                    </Message>
                  </MessageWrapper>
                }
              </StyledForm>
            }
          </VerifyEmailWrapper>
        )}
      </Formik>
    );
  } else if (
    queryString.parse(location.search).mode === "resetPassword" &&
    successRecover
  ) {
    content = (
      <VerifyEmailWrapper>
        <Wrapper>
          {!error && (
            <>
              <Heading noMargin color="white" bold size="h3">
                Your passord has been changed ! Please Login Again.
              </Heading>

              {/* <a href="/">Back to Login Page</a> */}

              {/*   <Button
                contain
                color={"secondary"}
                onClick={() => history.push("/")}
              >
                Login
              </Button> */}
            </>
          )}
        </Wrapper>
      </VerifyEmailWrapper>
    );
  }
  return content;
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.verifyEmail.loading,
  error: auth.verifyEmail.error,
  errorRecover: auth.recoverPassword.error,
  successRecover: auth.recoverPassword.success
});

const mapDispatchToProps = {
  handleVerification: actions.handleVerifyEmail,
  handleResetPassword: actions.handleResetPassword,
  processResetPassword: actions.processResetPassword,

  cleanUp: actions.clean
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Action));
