import React from "react";
import styled from "styled-components";

import Logo from "../../Logo/Logo";
import { Container } from "../../../hoc/layout/elements";
import { withRouter } from "react-router-dom";

import NavItems from "../NavItems/NavItems";

const FixedWrapper = styled.header`
  position: fixed;
  background-color: var(--color-white);

  top: 100%;
  transform: translateY(-100%);
  left: 0;
  width: 100%;
  height: 8.3rem;
  bottom: 0px;
  width: 100%;
  z-index: 10;

  @media ${props => props.theme.mediaQueries.biggerSmall} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

const Footer = ({
  loggedIn,
  emailVerified,
  usernameExists,
  location,
  height
}) => {
  return loggedIn &&
    emailVerified &&
    usernameExists &&
    location.pathname !== "/action" &&
    location.pathname !== "/login" ? (
    <FixedWrapper>
      <Container>
        <Wrapper>
          <NavItems loggedIn={loggedIn} />
        </Wrapper>
      </Container>
    </FixedWrapper>
  ) : null;
};

export default withRouter(Footer);
