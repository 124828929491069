import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import theme from "../../utils/theme";

const useStyles = makeStyles({
    lineContainer: {
        width: '90%',
        margin: 'auto'
    },
    lineWithTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px',
    },
    lineText: {
        marginLeft: '5px'
    },
    lineIconContainer: {
        height: '100%'
    }
});

export const SettingsOptionsLine = ({optionsLineText}) => {
    const classes = useStyles();

    return (
        <div className={classes.lineContainer}>
            <div className={classes.lineWithTextContainer}>
                <div className={classes.lineText}>
                    <label style={{fontSize: '16px'}}>{optionsLineText}</label>
                </div>
                <div className={classes.lineIconContainer}>
                    <i className="fa fa-angle-right"
                       style={{color: '#5830C7', fontSize: 16, fontWeight: 'bold'}}/>
                </div>
            </div>
            <hr style={{marginTop: '-7px'}}/>
        </div>
    )
}
