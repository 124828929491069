export const AUTH_START = 'AUTH_START';
export const AUTH_END = 'AUTH_END';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const SET_AUTH_REDIRECT = 'SET_AUTH_REDIRECT';

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const CLEAN_UP = 'CLEAN_UP';

export const VERIFY_START = 'VERIFY_START';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';
export const VERIFY_FAIL = 'VERIFY_FAIL';

export const RECOVERY_START = 'RECOVERY_START';
export const RECOVERY_SUCCESS = 'RECOVERY_SUCCESS';
export const RECOVERY_FAIL = 'RECOVERY_FAIL';
export const PROCESS_RECOVERY_SUCCESS = 'PROCESS_RECOVERY_SUCCESS';

export const GOOGLE_SIGNUP = 'GOOGLE_SIGNUP';
export const FACEBOOK_SIGNUP = 'FACEBOOK_SIGNUP';
export const TWITTER_SIGNUP = 'TWITTER_SIGNUP';

export const GOOGLE_SIGNIN = 'GOOGLE_SIGNIN';
export const FACEBOOK_SIGNIN = 'FACEBOOK_SIGNIN';
export const TWITTER_SIGNIN = 'TWITTER_SIGNIN';

export const PROFILE_EDIT_START = 'PROFILE_EDIT_START';
export const PROFILE_EDIT_SUCCESS = 'PROFILE_EDIT_SUCCESS';
export const PROFILE_EDIT_FAIL = 'PROFILE_EDIT_FAIL';

export const DELETE_START = 'DELETE_START';
export const DELETE_FAIL = 'DELETE_FAIL';

export const ADD_TODO_START = 'ADD_TODO_START';
export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS';
export const ADD_TODO_FAIL = 'ADD_TODO_FAIL';

export const DELETE_TODO_START = 'DELETE_TODO_START';
export const DELETE_TODO_SUCCESS = 'DELETE_TODO_SUCCESS';
export const DELETE_TODO_FAIL = 'DELETE_TODO_FAIL';

export const POST_BET_START = 'POST_BET_START';
export const POST_BET_SUCCESS = 'POST_BET_SUCCESS';
export const POST_BET_FAIL = 'POST_BET_FAIL';

export const DELETE_BET_START = 'DELETE_BET_START';
export const DELETE_BET_SUCCESS = 'DELETE_BET_SUCCESS';
export const DELETE_BET_FAIL = 'DELETE_BET_FAIL';

export const UPDATE_BET = 'UPDATE_BET';
export const UPDATE_BET_SUCCESS = 'UPDATE_BET_SUCCESS';
export const UPDATE_BET_FAIL = 'UPDATE_BET_FAIL';

export const POST_PHONE_NUMBER = 'POST_PHONE_NUMBER';
export const POST_PHONE_NUMBER_SUCCESS = 'POST_PHONE_NUMBER_SUCCESS';
export const POST_PHONE_NUMBER_ERROR = 'POST_PHONE_NUMBER_ERROR';

export const POST_EMAIL = 'POST_EMAIL';
export const POST_EMAIL_SUCCESS = 'POST_EMAIL_SUCCESS';
export const POST_EMAIL_ERROR = 'POST_EMAIL_ERROR';

export const POST_INVITATION_TEXT = 'POST_INVITATION_TEXT';
export const POST_INVITATION_TEXT_SUCCESS = 'POST_INVITATION_TEXT_SUCCESS';
export const POST_INVITATION_TEXT_ERROR = 'POST_INVITATION_TEXT_ERROR';

export const POST_INVITATION_EMAIL = 'POST_INVITATION_EMAIL';
export const POST_INVITATION_EMAIL_SUCCESS = 'POST_INVITATION_EMAIL_SUCCESS';
export const POST_INVITATION_EMAIL_ERROR = 'POST_INVITATION_EMAIL_ERROR';

export const BUG_REPORT_START = 'BUG_REPORT_START';
export const BUG_REPORT_SUCESS = 'BUG_REPORT_SUCCESS';
export const BUG_REPORT_FAIL = 'BUG_REPORT_FAIL';
