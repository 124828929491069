import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import logo from '../../../assets/logo.png';
import Img from '../../UI/Img';
import {Container, SideWrapper} from '../../../hoc/layout/elements';
import { withRouter } from 'react-router-dom';

import NavItem from '../NavItems/NavItem/NavItem';
import SettingsIcon from '../../../assets/SettingsIcon.png';
import InviteIcon from '../../../assets/InviteIcon.png';
import LogoutIcon from '../../../assets/exit.png';

import Invitation from '../../Invitation/Invitation';
import * as actions from '../../../store/actions';
import FinalConfirmation from "../../BetSlipView/FinalConfirmation";

const FixedWrapper = styled.header`
  position: fixed;
  background-color: var(--color-white);
  padding: 0rem 2rem;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 6rem;
  top: 0%;
  z-index: 10;

  @media ${(props) => props.theme.mediaQueries.biggerSmall} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Navbar = ({
  loggedIn,
  signOut,
  emailVerified,
  usernameExists,
  location,
}) => {
  const [invitation, setInvitation] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);

  return loggedIn &&
    emailVerified &&
    usernameExists &&
    location.pathname !== '/action' &&
    location.pathname !== '/login' ? (
    <FixedWrapper>
      <Container>
        <Wrapper>
          <NavItem clicked={() => setInvitation(true)} link="/">
            <Img
              dimensions={{
                width: '1.8rem',
                height: '1.8rem',
              }}
              src={InviteIcon}
            />
          </NavItem>
          <Img
            src={logo}
            dimensions={{ height: '3rem', width: '15rem' }}
            alt="Logo"
          />
          <div style={{ flexDirection: 'row', display: 'flex' }}>
            <NavItem link="/settings" style={{ margin: 'auto' }}>
              <Img
                dimensions={{
                  width: '1.8rem',
                  height: '1.8rem',
                }}
                src={SettingsIcon}
              />
            </NavItem>
            <div
              onClick={async () => await signOut()}
              style={{ marginLeft: '15px' }}
            >
              <Img
                dimensions={{
                  width: '1.8rem',
                  height: '1.8rem',
                }}
                src={LogoutIcon}
              />
            </div>
          </div>
        </Wrapper>
      </Container>

      {
        invitation  ?
            <Invitation
                isOpened={invitation}
                close={() => setInvitation(false)}
                setIsFinalConfirmation={setIsFinalConfirmation}
            />
            :
            null
      }

      {
        isFinalConfirmation ?
            <FinalConfirmation
                opened={isFinalConfirmation}
                close={() => setIsFinalConfirmation(false)}
                type={'invitation'}
            />
            :
            null
      }

    </FixedWrapper>
  ) : null;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  signOut: actions.signOut,
};

export default connect(null, mapDispatchToProps)(withRouter(Navbar));
