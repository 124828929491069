import React from "react";

import styled from "styled-components";

import { BetMiniWrapper } from "../BetSlipMini/BetMiniWrapper";
import Heading from "../UI/Heading";

import BetSlipView from "../BetSlipView/BetSlipView";

//import Explore from "../../containers/Explore/Explore"
//import MyBets from "../../containers/MyBets/MyBets"

//console.log(bet.sendBet);


export const TrendingWrapper = styled(BetMiniWrapper)`
  padding: 1.5rem 3rem 3rem;
`;

const TrendingItemsWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
`;

const TrendingItem = styled.li`
  color: var(--color-secondaryColor);
  font-size: 1.6rem;
  font-weight: ${({ clicked }) => (clicked ? "700" : "400")};
  cursor: pointer;
`;

const BlueSeparator = styled.div`
  width: 100%;
  height: 0.5rem;
  border-bottom: 1px solid var(--color-tertiaryColor);
`;

const Trending = ({
  explore,
  trendingTags,
  setTag,
  clicked,
  setClicked,
  setValueSearch
}) => {
  const first3Tags = () => {
    if (trendingTags) {
      let array = [];
      for (let i = 2; i >= 0; i--) {
        array.unshift(trendingTags[i]);
      }

      return array;
    } else {
      return null;
    }
  };
  return (
      <TrendingWrapper explore={explore} trending>
        <Heading size="h3" noMarginTop color="main">
          TRENDING
        </Heading>
        <TrendingItemsWrapper>
          {first3Tags() &&
            first3Tags().map((tag, index) => (
              <>
                <TrendingItem
                  onClick={() => {
                    //clean whatever is in the search bar
                    setValueSearch(null);
                    //set the main tag
                    setTag({
                      value: tag,
                      label: tag,
                    });
                    console.log('SETTAG:', tag);
                    /* setClicked(tag); */
                  }}
                  clicked={clicked === tag}
                >
                  {tag}
                </TrendingItem>
              </>
            ))}
        </TrendingItemsWrapper>
      </TrendingWrapper>
  );
};



export default Trending;
