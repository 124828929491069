import React, { /*useEffect,*/ useState, /*useRef*/ } from 'react';
// import DatePicker from 'react-datepicker';
import styled from 'styled-components';

/* import Dropdown from "react-dropdown"; */
import Select from 'react-select';

export const FOR_DIRECT_BET_FIELD = "forDirectBetField";

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 4;
  width: ${({ register, profile, width }) =>
    register || profile || width === '100' ? '100%' : '100%'};

  @media ${props => props.theme.mediaQueries.biggerSmall} {
    width: ${({ register, profile }) => (register || profile ? '50%' : '100%')};
  }

  /* @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    width: ${({ register, profile }) => (register || profile ? '60%' : '80%')};
  } */
`;

const Error = styled.div`
  color: var(--color-errorRed);
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '20px' : '10px')});
  transition: all 0.1s;
  position: absolute;
  bottom: 3rem;
  left: 0;
  font-weight: 500;
  font-size: 1.2rem;

  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 1rem;
    bottom: 2rem;
  }
`;

export const StyledDropdown = styled(Select)`
  margin-bottom: 3rem;
  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    margin-bottom: 2rem;
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-bottom: 1rem;
  } */

  width: ${({ direct }) => (direct ? '84%' : '100%')};
  margin-left: ${({ direct }) => (direct ? '1rem' : '0px')};

  color: black;
  border: 0;
  position: relative;
  line-height: normal;
  border-bottom: ${({ register, profile, styleType }) =>
    (register || profile) || styleType === FOR_DIRECT_BET_FIELD ? 'none' : '2px solid var(--color-tertiaryColor)'};

  &::placeholder {
   /*  color: var(--color-mainDark); */
   color: ${({ type }) =>
     type === 'privacy' ? 'hsl(0,0%,60%)' : 'var(--color-mainDark)'};
  }
  
  margin: ${({styleType})=>
    styleType === FOR_DIRECT_BET_FIELD ? 'auto' : ''
  };
  

  
  padding-top: 10px;
  
  

  [class*="control"] {
    border: 0;
    border-color: transparent;

    min-height: 0px;
    box-shadow: none;
    border-radius: ${({ register, profile }) =>
      (register || profile) && '2rem'};

    padding: ${({ profile }) => (profile ? '0.8rem 2rem' : '0.2rem 0rem;')};

   /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
      padding: ${({ profile }) => (profile ? '0.5rem 2rem' : '0rem;')};
  } */





    .css-1hwfws3 {

padding: 0px;
padding-bottom: ${({ profile }) => (profile ? '5px' : '10px')};

@media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
  padding-bottom: 5px;
  }
      [class*="singleValue"] {
        font-size: ${({ register, profile }) =>
          register ? '1rem' : profile ? '1.2rem !important' : '1.2rem'};

        @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
          font-size: ${({ register, profile }) =>
            register ? '1rem' : profile ? '1.2rem !important' : '1.2rem'};
        }

        /* @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
          font-size: ${({ register }) =>
            register ? '0.2rem' : '1rem !important'};
        } */
        
            font-size: ${({styleType}) =>
                styleType === FOR_DIRECT_BET_FIELD ? '14px' : ''
            };
            margin-left: ${({styleType}) =>
                styleType === FOR_DIRECT_BET_FIELD ? '-1px' : ''
            }; 
      }
      [class*="placeholder"] {
        font-size: ${({ register, profile, direct }) => {
          if (register) {
            return '1rem';
          } else if (profile) {
            return '1.2rem';
          } else if (direct) {
            return '13px';
          } else {
            return '1.2rem';
          }
        }};

        @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
          font-size: ${({ register, profile }) =>
            register ? '1rem' : profile ? '1.2rem' : '1.2rem'};
        }

       /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
          font-size: ${({ register }) => (register ? '0.2rem' : '1rem')};
        } */

      color: ${({ type }) =>
        type === 'privacy' ? 'hsl(0,0%,60%)' : 'var(--color-mainDark)'};

      font-size: ${({ direct }) => (direct ? '13px !important' : '1.2rem')};

        margin-left: 0px;
        margin-right: 0px;
        
      }

      [class*="Input"] {
        margin: 0px;
        padding-top: 0px;
        padding-bottom: 0px;


      }

      .css-1g6gooi{
        margin: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .css-1okebmr-indicatorSeparator{
    display: none;
  }

  .css-tlfecz-indicatorContainer {
    padding: 0px;
    color: var(--color-mainDark)
  }

  .css-1gtu0rj-indicatorContainer {
    padding: 0px;
    color: var(--color-mainDark)
  }

  [class*="menu"] {
    font-size: ${({ register }) => (register ? '1rem' : '1.2rem')};
    @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
      font-size: ${({ register, profile }) => (register ? '0.5rem' : '1rem')};
    }
  /*   @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
      font-size: ${({ register }) => (register ? '0.2rem' : '0.5rem')};
    } */
    [class*="menu-list"] {
      font-size: ${({ register }) => (register ? '1rem' : '1.2rem')};
      @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
        font-size: ${({ register }) => (register ? '0.5rem' : '1rem')};
      }

      /* @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
        font-size: ${({ register }) => (register ? '0.2rem' : '0.5rem')};
      } */
    }
    .css-1n7v3ny-option {
      background-color: var(--color-main);
    }

    .css-9gakcf-option {
      background-color: var(--color-greyColor);
    }

    [class*="isFocused"] {
      background-color: var(--color-mainDark);
    }
  }
  
`;

const DropdownField = ({
  direct,
  options,
  cryptoOption,
  field,
  placeholder,
  disabled,
  register,
  profile,
  width,
  setField = false,
  fields,
  value,
  type,
  styleType,
  sendTo,
  form: { touched, errors, setFieldValue, setFieldTouched, values },
}) => {
  const [optionsState, setOptionsState] = useState(options);

  const optionsSelected = () => {
    if (
      (type === 'settlement' && values.currency.value === 'TRX') ||
      values.currenty.value === 'BTC'
    ) {
      return cryptoOption;
    } else {
      return options;
    }
  };
  return (
    <DropdownWrapper register={register} profile={profile} width={width}>
      <StyledDropdown
        id={field.name}
        options={options}
        value={value}
        type={type}
        styleType={styleType}
        onChange={val => {
          if (setField) {
            setField({ user: val.value });
            setFieldValue({ value: val, label: val });
          }
          setFieldValue(field.name, val);
          if (type === 'currency') {
            if (val.value === 'TRX' || val.value === 'BTC') {
              setFieldValue('settlement', {
                label: 'Crypto Transaction',
                value: 'Crypto Transaction',
              });
            } else if (val.value === 'WBX') {
              setFieldValue('settlement', {
                label: 'No Settlement',
                value: 'No Settlement',
              });
            } else {
              setFieldValue('settlement', '');
            }
          }

          if(sendTo){
              setFieldValue({value: val, label: val})
          }
        }}
        onBlur={val => {
          setFieldTouched(field.name, true);
        }}
        placeholder={placeholder}
        register={register}
        profile={profile}
        menuPlacement="top"
        blurInputOnSelect={true}
        autoFocus={false}
        isDisabled={disabled}
        direct={direct}
      />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </DropdownWrapper>
  );
};

export default DropdownField;
