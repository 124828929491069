import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import * as Yup from 'yup';

import Modal from '../UI/Modal/Modal';
import Button from '../UI/Forms/Button/Button';
import Heading from '../UI/Heading';
import Message from '../UI/Message';
import * as actions from '../../store/actions';
import FinalConfirmation from './FinalConfirmation';
import {claimWon} from '../../store/actions';
import {claimLost} from '../../store/actions';
import {bothSaidWonDispute} from '../../store/actions';
import {DisplayDetail, ButtonsWrapper, BetSlipWrapper} from './BetSlipView';
import {Formik, Field, Form} from 'formik';
import {OptionForm, Label} from '../../containers/Explore/PostABet/InputBet';
import DropdownField, {
    FOR_DIRECT_BET_FIELD,
} from '../../containers/Explore/DropdownField/DropdownField';
import {StyledInput} from '../UI/Forms/Input/Input';
import {compose} from 'redux';
import {firestoreConnect} from 'react-redux-firebase';
import Select from 'react-select';
import {FormWrapper, InnerWrapper} from '../../hoc/layout/elements/index';
import Input from '../UI/Forms/Input/Input';
import {
    calculateNumberOfFreeBets,
    calculateNumberOfOpponents,
} from '../../containers/Calculations/Calculations';
import {
    getCoinsFrom100FreeBets,
    getCoinsFrom10Opponents,
    getCoinsFrom20FreeBets,
    getCoinsFrom5Opponents,
} from '../../containers/Calculations/Gamification';

import ButtonTop from '../../components/UI/Forms/Button/ButtonTop';

import Logo from "../Logo/Logo";
import SearchInput from "../SearchInput/SearchInput";
import BetSlipMini from "../BetSlipMini/BetSlipMini";



import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import BetSlipView from "../BetSlipView/BetSlipView";
import DefaultAvatar from "../../assets/default-avatar.png";
import { BetMiniWrapper } from "../BetSlipMini/BetMiniWrapper";
// import FinalConfirmation from "../Navigation/SideRight/SideRight";
import {getCoinsAmountFromJurorVote} from "../../containers/Calculations/Gamification";



const MessageWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  padding: 0 3rem;
`;

const ConfirmStyleInput = styled(StyledInput)`
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 1rem;
  width: 60%;
  font-size: 13px !important;
`;

const Divider = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const EMAIL_OR_PHONE_NUMBER_REGEX = /^\+?[1-9]\d{9,14}|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

// const BetConfirmationSchema = Yup.object().shape({
//   sendTo: Yup.object()
//       .shape({
//         value: Yup.string().required()
//       })
//       .required('You need to choose a user type'),
//   existingUser: Yup.mixed().when(
//           'newUser',{
//             is: "",
//             then: Yup.object()
//                 .shape({
//                   value: Yup.string().required()
//                 })
//                 .required('Please pick a username')
//           }
//       ),
//   newUser: Yup.mixed().when(
//       'existingUser',{
//         is: "",
//         then: Yup.string()
//             .required('Please insert a valid email or phone number')
//             .matches(EMAIL_OR_PHONE_NUMBER_REGEX,'Please insert a valid email or phone number'),
//       }
//   )
// });

// BetConfirmationSchema.validate({
//   existingUser: {value: "newUser", label: "A New User"},
//   newUser: "gilvicentevieira@gmail.com"
// }).then((result)=>{
//   console.log("result: ", result);
// }).catch((e)=>{
//   console.log("error: ", e);
// });

// const BetConfirmationSchema2 = Yup.object().shape({
//   sendTo: Yup.string()
//       .required('You need to choose a user type'),
//   existingUser: Yup.object().shape({
//       value: Yup.string().required(),
//       label: Yup.string().required()
//       })
// });

const ConfirmBet = ({
                        opened,
                        close,
                        error,
                        loading,
                        bet,
                        notification,
                        bets,
                        users,
                        type,
                        acceptBet,
                        rejectBet,
                        deleteBet,
                        postEmail,
                        postBet,
                        postPhoneNumber,
                        postInvitationText,
                        postInvitationEmail,
                        modifyBet,
                        newBet,
                        profileUser,
                        userId,
                        claimWon,
                        claimLost,
                        bothSaidWonDispute,
                        bothSaidLostDispute,
                        setWinner,
                        setLoser,
                        setBadDebt,
                        setPaid,
                        setReminderToPay,
                        setHistory,
                        isFinalConfirmation,
                        setIsFinalConfirmation,
                        addUserCoins,
                        profile,
                    }) => {
    const [state, setState] = useState({});
    const [field, setField] = useState(false);
    const [isNewUserContactInserted, setIsNewUserInserted] = useState(false);
    //const [sendTo, setSendTo] = useState({value: 'existingUser', label: "Existing User"});
    //let sendTo = {value: 'existingUser', label: "Existing User"};

    const stateRef = useRef();

    useEffect(() => {
        if (field) {
            setState({...state, ...field});
        }
    }, [field]);

    const moment = require('moment');
    const titleText = (type) => {
        if (type === 'delete') {
            return 'Are you sure you want to delete this bet ?';
        } else if (type === 'accept') {
            return 'Are you sure you want to accept this bet ?';
        } else if (type === 'add') {
            return 'Please confirm your bet';
        } else if (type === 'modify') {
            return 'Please confirm your bet';
        } else if (type === 'bothSaidWonDispute') {
            return 'The other(s) bet participant(s) also claimed that they have won the bet. Do you want to push the decision to jury arbitration ?';
        } else if (type === 'bothSaidLostDispute') {
            return 'The other(s) bet participant(s) also claimed that they have lost the bet. Do you want to push the decision to jury arbitration ?';
        } else if (type === 'claimWon' || type === 'won') {
            return 'Are you sure you won this bet ?';
        } else if (type === 'claimLost' || type === 'lost') {
            return 'Are you sure you lost this bet ?';
        } else if (type === 'badDebt') {
            return 'Are you sure you want to send notification of Bad Debt ?';
        } else if (type === 'sendReminder') {
            return 'Are you sure you want to remind to pay ?';
        } else if (type === 'paid') {
            return 'Are you sure you paid the bet ?';
        } else if (type === 'history') {
            return 'Are you sure you got paid ?';
        } else if (type === 'invitation') {
            return 'Invite a friend';
        }
    };

    const loadindgText = (type) => {
        if (type == 'accept') {
            return 'Accepting';
        } else if (type === 'reject') {
            return 'Rejecting';
        } else if (type === 'delete') {
            return 'Deleting';
        } else if (type === 'add') {
            return 'Posting';
        } else if (type === 'modify') {
            return 'Modifying';
        } else if (
            type === 'won' ||
            type === 'lost' ||
            type === 'badDebt' ||
            type === 'sendReminder' ||
            type === 'paid'
        ) {
            return 'Sending';
        } else if (type === 'history') {
            return 'Finishing';
        }
    };

    const buttonText = (type) => {
        if (type == 'accept') {
            return 'ACCEPT BET';
        } else if (type === 'reject') {
            return 'REJECT BET';
        } else if (type === 'delete') {
            return 'DELETE BET';
        } else if (type === 'add') {
            return 'CONFIRM MY BET';
        } else if (type === 'modify') {
            return 'CONFIRM MY BET';
        } else if (
            type === 'won' ||
            type === 'lost' ||
            type === 'badDebt' ||
            type === 'sendReminder' ||
            type === 'paid' ||
            type === 'claimWon' ||
            type === 'claimLost'
        ) {
            return 'SEND NOTIFICATION';
        } else if (
            type === 'bothSaidWonDispute' ||
            type === 'bothSaidLostDispute'
        ) {
            return 'YES';
        } else if (type === 'history') {
            return 'FINISH BET';
        } else if (type === 'invitation') {
            return 'SEND INVITE';
        }
    };

    const actionMethod = async (type) => {
        if (type === 'accept') {
            await acceptBet(bet);
        } else if (type === 'reject') {
            await rejectBet(bet);
        } else if (type === 'delete') {
            await deleteBet(bet.id);
        } else if (type === 'add') {
            await postBet(newBet);
            await gamificationAddingCoins();
        } else if (type === 'modify') {
            if (newBet.offeredToEmail) {
                await postEmail(newBet.offeredToEmail);
            } else if (newBet.offeredToPhoneNumber) {
                await postPhoneNumber(newBet.offeredToPhoneNumber);
            }

            if (bet && bet.creatorID === userId) {
                await modifyBet(newBet, bet.id);
                console.log('vince alas!');
            } else {
                await postBet(newBet);
                await gamificationAddingCoins();
            }
        } else if (type === 'claimWon') {
            await claimWon(bet, notification);
        } else if (type === 'claimLost') {
            await claimLost(bet, notification);
        } else if (type === 'bothSaidWonDispute') {
            await bothSaidWonDispute(bet, notification);
        } else if (type === 'bothSaidLostDispute') {
            await bothSaidLostDispute(bet, notification);
        } else if (type === 'won') {
            await setWinner(bet, notification);
        } else if (type === 'lost') {
            await setLoser(bet, notification);
        } else if (type === 'badDebt') {
            /* await setBadDebt(bet.id); */
        } else if (type === 'paid') {
            await setPaid(bet, notification);
        } else if (type === 'sendReminder') {
            await setReminderToPay(bet, notification);
        } else if (type === 'history') {
            await setHistory(bet, notification);
        } else if (type === 'invitation') {

        }
    };

    const gamificationAddingCoins = async () => {
        // Adding coins for number of opponents 5 and 10 and free bets 20 and 100
        const numberOfOpponents = calculateNumberOfOpponents(bets, userId);
        if (numberOfOpponents === 5) {
            await addUserCoins(userId, getCoinsFrom5Opponents(profile.userNumber));
        } else if (numberOfOpponents === 10) {
            await addUserCoins(userId, getCoinsFrom10Opponents(profile.userNumber));
        }

        const numberOfFreeBets = calculateNumberOfFreeBets(bets, userId);
        if (numberOfFreeBets === 20) {
            await addUserCoins(userId, getCoinsFrom20FreeBets(profile.userNumber));
        } else if (numberOfFreeBets === 100) {
            await addUserCoins(userId, getCoinsFrom100FreeBets(profile.userNumber));
        }
    };

    const contestants = bets && bets.map((bet) => bet.contestantID);
    const creators = bets && bets.map((bet) => bet.creatorID);
    // const opponents =
    //   bets &&
    //   [...new Set([...contestants, ...creators])].filter(
    //     opponent => opponent && opponent !== userId,
    //   );

    // const mostRecentUsers =
    //     users && opponents && users.filter(user => opponents.includes(user.id));

    const change = (e) => {
        if (e.target.value.length > 0) {
            setIsNewUserInserted(true);
        } else if (e.target.value.length === 0) {
            setIsNewUserInserted(false);
        }
        setField({phoneOrEmail: e.target.value});
    };

    const validE164 = (num) => {
        return /^\+?[1-9]\d{1,14}$/.test(num);
    };

    const validateEmail = (email) => {
        const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return email.match(emailRegexp);
    };

    const isConfirmButtonDisabled = (values) => {

        if (values.sendTo && values.sendTo.value === 'existingUser') {
            if (type === 'modify') {
              return values.existingUser === 'bet.creatorUsername'
            } else {
              return values.existingUser === ''
            }
        } else if (values.sendTo && values.sendTo.value === 'newUser') {
            const regex = new RegExp(EMAIL_OR_PHONE_NUMBER_REGEX);
            regex.test(values.newUser);
            return values.newUser === '' || !regex.test(values.newUser);
        }


        const regex = new RegExp(EMAIL_OR_PHONE_NUMBER_REGEX);
        regex.test(values.newUser);
        return values.newUser === '' || !regex.test(values.newUser);

    };

    let existingUserOptions = [];

    if (users) {
        for (let user of users) {
            if (user.id !== userId) {
                existingUserOptions.push({
                    value: user.username,
                    label: user.username,
                });
            }
        }
    }

    let userOptions = [];

    if (type === 'invitation') {
        userOptions.push({value: 'newUser', label: 'A new User'});
    } else {
        userOptions.push(
            {value: 'existingUser', label: 'An existing user'},
            {
                value: 'newUser',
                label: 'A new user',
            },
        );
    }

    return (
        <>
            <Modal opened={opened} close={close} isInvitation={type === 'invitation'}>
            <Logo/><br />
                {type === 'invitation' ? (
                    <>
                        <div style={{display: 'flex', alignItems: 'center', width: '100%'}}>
                            <div style={{marginTop: '0.25rem', width: '30%'}}>
                                <ButtonTop
                                    type="button"
                                    color="main"
                                    contain
                                    onClick={() => {
                                        close();
                                    }}
                                >
                                    Cancel
                                </ButtonTop>
                            </div>
                            <div>
                                <Heading size="h2" bold noMargin noMarginTop color={'secondary'}>
                                    {titleText(type)}
                                </Heading>
                            </div>
                        </div>
                    </>
                ) : (
                    <Heading noMargin bold size="h2">
                        {titleText(type)}
                    </Heading>
                )}

                {type === 'invitation' ? null : (
                    <BetSlipWrapper>
                        <p className="title">
                            {newBet ? newBet.title : bet ? bet.title : ''}
                        </p>
                        <DisplayDetail>
                            <p className="label">FROM:</p>
                            <p className="value">
                                {newBet
                                    ? moment(newBet.eventStart, 'X').format('lll')
                                    : bet
                                        ? moment(bet.eventStart, 'X').format('lll')
                                        : ''}
                            </p>
                            <p className="timezone">
                                {newBet ? newBet.timezone : bet ? bet.timezone : ''}
                            </p>
                        </DisplayDetail>
                        <DisplayDetail>
                            <p className="label">TO:</p>
                            <p className="value">
                                {newBet
                                    ? moment(newBet.betExpiry, 'X').format('lll')
                                    : bet
                                        ? moment(bet.betExpiry, 'X').format('lll')
                                        : ''}
                            </p>
                            <p className="timezone">
                                {newBet ? newBet.timezone : bet ? bet.timezone : ''}
                            </p>
                        </DisplayDetail>
                        <DisplayDetail>
                            <p className="label">AMOUNT:</p>
                            <p className="value">
                                {newBet
                                    ? `${newBet.amount} ${newBet.currency} ${newBet.settlement}`
                                    : `${bet.amount} ${bet.currency} ${bet.settlement}`}
                            </p>
                        </DisplayDetail>
                        <DisplayDetail>
                            <p className="label">PRIVACY:</p>
                            <p className="value">
                                {newBet ? newBet.privacy : bet.privacy} Event
                            </p>
                        </DisplayDetail>
                        <DisplayDetail>
                            <p className="label">SEND:</p>
                            <p className="value">
                                {newBet ? newBet.sendBet : bet ? bet.sendBet : ''}
                            </p>
                        </DisplayDetail>
                    </BetSlipWrapper>
                )}
                {(newBet && newBet.sendBet === 'Direct' && (!profileUser || !profileUser.id)) || type === 'invitation' ? (
                    <Formik
                        //validationSchema={BetConfirmationSchema}
                        initialValues={{sendTo: '', existingUser: '', newUser: ''}}
                        onSubmit={async (values, {setSubmitting, resetForm}) => {
                            // submit bet
                            if (values.newUser || values.existingUser) {
                                if (validE164(values.newUser)) {
                                    await postPhoneNumber(values.newUser);
                                } else if (validateEmail(values.newUser)) {
                                    await postEmail(values.newUser);
                                }

                                close();
                                actionMethod(type);
                                setIsFinalConfirmation(true);
                            }
                        }}
                        enableReinitialize
                        validator={() => ({})}
                    >
                        {({resetForm, values, errors, isSubmitting}) => {
                            return (
                                <FormWrapper>
                                    {type !== 'invitation' ? (
                                        <DisplayDetail>
                                            <p className="label" style={{marginTop: 15}}>
                                                SEND TO:
                                            </p>
                                            <OptionForm>
                                                <Field
                                                    value={values.sendTo}
                                                    name="sendTo"
                                                    placeholder=" Select user type"
                                                    options={userOptions}
                                                    direct={true}
                                                    styleType={FOR_DIRECT_BET_FIELD}
                                                    component={DropdownField}
                                                    width="100"
                                                />
                                            </OptionForm>
                                        </DisplayDetail>
                                    ) : null}

                                    {type === 'invitation' ||
                                    (!profileUser &&
                                        newBet.sendBet === 'Direct' &&
                                        values.sendTo.value === 'newUser') ? (
                                        <DisplayDetail>
                                            <p className="label">NEW USER:</p>
                                            <OptionForm>
                                                <Field
                                                    name="newUser"
                                                    placeholder="Friend's email or cell number"
                                                    styleType={FOR_DIRECT_BET_FIELD}
                                                    component={Input}
                                                />
                                            </OptionForm>
                                        </DisplayDetail>
                                    ) : (
                                        [
                                            !profileUser &&
                                            newBet.sendBet === 'Direct' &&
                                            values.sendTo.value === 'existingUser' ? (
                                                <DisplayDetail>
                                                    <p className="label" style={{marginTop: 15}}>
                                                        EXISTING USER:
                                                    </p>
                                                    { type === 'modify' ? (
                                                      <OptionForm>
                                                          <Field
                                                            name="existingUser"
                                                            //placeholder="Select Username"
                                                            value={bet.creatorUsername}
                                                            //options={existingUserOptions}
                                                            direct={true}
                                                            styleType={FOR_DIRECT_BET_FIELD}
                                                            component={Input}
                                                            setField={setField}
                                                            width="80"
                                                            type={'username'}
                                                            disabled={true}
                                                          />
                                                      </OptionForm> ) : (
                                                      <OptionForm>
                                                          <Field
                                                              name="existingUser"
                                                              placeholder="Select Username"
                                                              value={values.existingUser}
                                                              options={existingUserOptions}
                                                              direct={true}
                                                              styleType={FOR_DIRECT_BET_FIELD}
                                                              component={DropdownField}
                                                              setField={setField}
                                                              width="80"
                                                              type={'username'}
                                                          />
                                                      </OptionForm>
                                                    )}
                                                </DisplayDetail>
                                            ) : null,
                                        ]
                                    )}

                                    {}

                                    {/* {!profileUser &&
                newBet.sendBet === 'Direct' &&
                values.sendTo.value === 'newUser' ? (
                  <DisplayDetail>
                    <p className="label">NEW USER:</p>
                    <OptionForm>
                      <Field
                        name="newUser"
                        placeholder="Enter your email / phone number"
                        styleType={FOR_DIRECT_BET_FIELD}
                        component={Input}
                      />
                    </OptionForm>
                  </DisplayDetail>
                ) : null} */}

                                    <Button
                                        contain
                                        color="secondary"
                                        type="submit"
                                        disabled={isConfirmButtonDisabled(values)}
                                        isInvitation={type === 'invitation'}
                                        onClick={async () => {

                                            if (type === 'invitation') {
                                                close();

                                                const regex = new RegExp(EMAIL_OR_PHONE_NUMBER_REGEX);
                                                regex.test(values.newUser);

                                                if (values.newUser !== '' && regex.test(values.newUser)) {
                                                    if (validE164(values.newUser)) {
                                                        await postInvitationText(values.newUser);
                                                    } else if (validateEmail(values.newUser)) {
                                                        await postInvitationEmail(values.newUser);
                                                    }
                                                }
                                                setIsFinalConfirmation(true);

                                            } else {
                                                if (values.sendTo.value === 'existingUser') {
                                                    if (type === 'modify') {
                                                      newBet.offeredToID = users.find(
                                                      (user) => user.username === bet.creatorUsername,).id;
                                                    } else {
                                                      newBet.offeredToID = users.find(
                                                      (user) => user.username === values.existingUser.value,).id;
                                                    }
                                                    //console.log('BET ID: ', newBet.offeredToID);
                                                    //console.log('USERNAME: ', values.existingUser.value);
                                                } else if (values.sendTo.value === 'newUser') {
                                                    if (validE164(values.newUser)) {
                                                        newBet.offeredToPhoneNumber = values.newUser;
                                                    } else if (validateEmail(values.newUser)) {
                                                        newBet.offeredToEmail = values.newUser;
                                                    }
                                                }

                                                close();
                                                actionMethod(type);
                                                setIsFinalConfirmation(true);
                                            }
                                        }}
                                    >
                                        {buttonText(type)}
                                    </Button>

                                    {type === 'invitation' ? null : (
                                        <Button color="transparent" contain onClick={close}>
                                            CANCEL
                                        </Button>
                                    )}

                                    <MessageWrapper>
                                        <Message error show={error}>
                                            {error}
                                        </Message>
                                    </MessageWrapper>
                                </FormWrapper>
                            );
                        }}
                    </Formik>
                ) : (
                    <ButtonsWrapper>
                        <Button
                            contain
                            margin
                            color="secondary"
                            onClick={() => {
                                close();
                                if (setIsFinalConfirmation) {
                                    setIsFinalConfirmation(true);
                                }
                                actionMethod(type);
                            }}
                            disabled={loading}
                            loading={loading ? loadindgText : null}
                        >
                            {buttonText(type)}
                        </Button>
                        <Button color="transparent" contain onClick={close}>
                            CANCEL
                        </Button>
                    </ButtonsWrapper>
                )}

            </Modal>

        </>
    );
};

const mapStateToProps = ({bets, users, firebase, firestore}) => ({
    userId: firebase.auth.uid,
    profile: firebase.profile,
    bets: firestore.ordered.bets,
    error: bets.error,
    loading: bets.loading,
    //users: firestore.ordered.users,
});

const mapDispatchToProps = {
    acceptBet: actions.acceptBet,
    deleteBet: actions.deleteBet,
    postBet: actions.postBet,
    rejectBet: actions.rejectBet,
    modifyBet: actions.modifyBet,
    setWinner: actions.setWinner,
    setLoser: actions.setLoser,
    setHistory: actions.setHistory,
    postPhoneNumber: actions.postPhoneNumber,
    postEmail: actions.postEmail,
    postInvitationText: actions.postInvitationText,
    postInvitationEmail: actions.postInvitationEmail,
    /* setBadDebt: actions.setBadDebt, */
    setPaid: actions.setPaid,
    setReminderToPay: actions.setReminderToPay,
    claimWon: actions.claimWon,
    claimLost: actions.claimLost,
    bothSaidWonDispute: actions.bothSaidWonDispute,
    bothSaidLostDispute: actions.bothSaidLostDispute,
    addUserCoins: actions.addUserCoins,
};



export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBet);
