import moment from 'moment';

const timezones = [
  { value: 'America/Adak', text: 'Adak' },
  { value: 'America/Anchorage', text: 'Anchorage' },
  { value: 'America/Anguilla', text: 'Anguilla' },
  { value: 'America/Antigua', text: 'Antigua' },
  { value: 'America/Araguaina', text: 'Araguaina' },
  { value: 'America/Argentina/Buenos_Aires', text: 'Argentina - Buenos Aires' },
  { value: 'America/Argentina/Catamarca', text: 'Argentina - Catamarca' },
  { value: 'America/Argentina/ComodRivadavia', text: 'Argentina - ComodRivadavia' },
  { value: 'America/Argentina/Cordoba', text: 'Argentina - Cordoba' },
  { value: 'America/Argentina/Jujuy', text: 'Argentina - Jujuy' },
  { value: 'America/Argentina/La_Rioja', text: 'Argentina - La Rioja' },
  { value: 'America/Argentina/Mendoza', text: 'Argentina - Mendoza' },
  { value: 'America/Argentina/Rio_Gallegos', text: 'Argentina - Rio Gallegos' },
  { value: 'America/Argentina/Salta', text: 'Argentina - Salta' },
  { value: 'America/Argentina/San_Juan', text: 'Argentina - San Juan' },
  { value: 'America/Argentina/San_Luis', text: 'Argentina - San Luis' },
  { value: 'America/Argentina/Tucuman', text: 'Argentina - Tucuman' },
  { value: 'America/Argentina/Ushuaia', text: 'Argentina - Ushuaia' },
  { value: 'America/Aruba', text: 'Aruba' },
  { value: 'America/Asuncion', text: 'Asuncion' },
  { value: 'America/Atikokan', text: 'Atikokan' },
  { value: 'America/Atka', text: 'Atka' },
  { value: 'America/Bahia', text: 'Bahia' },
  { value: 'America/Barbados', text: 'Barbados' },
  { value: 'America/Belem', text: 'Belem' },
  { value: 'America/Belize', text: 'Belize' },
  { value: 'America/Blanc-Sablon', text: 'Blanc-Sablon' },
  { value: 'America/Boa_Vista', text: 'Boa Vista' },
  { value: 'America/Bogota', text: 'Bogota' },
  { value: 'America/Boise', text: 'Boise' },
  { value: 'America/Buenos_Aires', text: 'Buenos Aires' },
  { value: 'America/Cambridge_Bay', text: 'Cambridge Bay' },
  { value: 'America/Campo_Grande', text: 'Campo Grande' },
  { value: 'America/Cancun', text: 'Cancun' },
  { value: 'America/Caracas', text: 'Caracas' },
  { value: 'America/Catamarca', text: 'Catamarca' },
  { value: 'America/Cayenne', text: 'Cayenne' },
  { value: 'America/Cayman', text: 'Cayman' },
  { value: 'America/Chicago', text: 'Chicago' },
  { value: 'America/Chihuahua', text: 'Chihuahua' },
  { value: 'America/Coral_Harbour', text: 'Coral Harbour' },
  { value: 'America/Cordoba', text: 'Cordoba' },
  { value: 'America/Costa_Rica', text: 'Costa Rica' },
  { value: 'America/Cuiaba', text: 'Cuiaba' },
  { value: 'America/Curacao', text: 'Curacao' },
  { value: 'America/Danmarkshavn', text: 'Danmarkshavn' },
  { value: 'America/Dawson', text: 'Dawson' },
  { value: 'America/Dawson_Creek', text: 'Dawson Creek' },
  { value: 'America/Denver', text: 'Denver' },
  { value: 'America/Detroit', text: 'Detroit' },
  { value: 'America/Dominica', text: 'Dominica' },
  { value: 'America/Edmonton', text: 'Edmonton' },
  { value: 'America/Eirunepe', text: 'Eirunepe' },
  { value: 'America/El_Salvador', text: 'El Salvador' },
  { value: 'America/Ensenada', text: 'Ensenada' },
  { value: 'America/Fortaleza', text: 'Fortaleza' },
  { value: 'America/Fort_Wayne', text: 'Fort Wayne' },
  { value: 'America/Glace_Bay', text: 'Glace Bay' },
  { value: 'America/Godthab', text: 'Godthab' },
  { value: 'America/Goose_Bay', text: 'Goose Bay' },
  { value: 'America/Grand_Turk', text: 'Grand Turk' },
  { value: 'America/Grenada', text: 'Grenada' },
  { value: 'America/Guadeloupe', text: 'Guadeloupe' },
  { value: 'America/Guatemala', text: 'Guatemala' },
  { value: 'America/Guayaquil', text: 'Guayaquil' },
  { value: 'America/Guyana', text: 'Guyana' },
  { value: 'America/Halifax', text: 'Halifax' },
  { value: 'America/Havana', text: 'Havana' },
  { value: 'America/Hermosillo', text: 'Hermosillo' },
  { value: 'America/Indiana/Indianapolis', text: 'Indiana - Indianapolis' },
  { value: 'America/Indiana/Knox', text: 'Indiana - Knox' },
  { value: 'America/Indiana/Marengo', text: 'Indiana - Marengo' },
  { value: 'America/Indiana/Petersburg', text: 'Indiana - Petersburg' },
  { value: 'America/Indiana/Tell_City', text: 'Indiana - Tell City' },
  { value: 'America/Indiana/Vevay', text: 'Indiana - Vevay' },
  { value: 'America/Indiana/Vincennes', text: 'Indiana - Vincennes' },
  { value: 'America/Indiana/Winamac', text: 'Indiana - Winamac' },
  { value: 'America/Indianapolis', text: 'Indianapolis' },
  { value: 'America/Inuvik', text: 'Inuvik' },
  { value: 'America/Iqaluit', text: 'Iqaluit' },
  { value: 'America/Jamaica', text: 'Jamaica' },
  { value: 'America/Jujuy', text: 'Jujuy' },
  { value: 'America/Juneau', text: 'Juneau' },
  { value: 'America/Kentucky/Louisville', text: 'Kentucky - Louisville' },
  { value: 'America/Kentucky/Monticello', text: 'Kentucky - Monticello' },
  { value: 'America/Knox_IN', text: 'Knox IN' },
  { value: 'America/La_Paz', text: 'La Paz' },
  { value: 'America/Lima', text: 'Lima' },
  { value: 'America/Los_Angeles', text: 'Los Angeles' },
  { value: 'America/Louisville', text: 'Louisville' },
  { value: 'America/Maceio', text: 'Maceio' },
  { value: 'America/Managua', text: 'Managua' },
  { value: 'America/Manaus', text: 'Manaus' },
  { value: 'America/Marigot', text: 'Marigot' },
  { value: 'America/Martinique', text: 'Martinique' },
  { value: 'America/Matamoros', text: 'Matamoros' },
  { value: 'America/Mazatlan', text: 'Mazatlan' },
  { value: 'America/Mendoza', text: 'Mendoza' },
  { value: 'America/Menominee', text: 'Menominee' },
  { value: 'America/Merida', text: 'Merida' },
  { value: 'America/Mexico_City', text: 'Mexico City' },
  { value: 'America/Miquelon', text: 'Miquelon' },
  { value: 'America/Moncton', text: 'Moncton' },
  { value: 'America/Monterrey', text: 'Monterrey' },
  { value: 'America/Montevideo', text: 'Montevideo' },
  { value: 'America/Montreal', text: 'Montreal' },
  { value: 'America/Montserrat', text: 'Montserrat' },
  { value: 'America/Nassau', text: 'Nassau' },
  { value: 'America/New_York', text: 'New York' },
  { value: 'America/Nipigon', text: 'Nipigon' },
  { value: 'America/Nome', text: 'Nome' },
  { value: 'America/Noronha', text: 'Noronha' },
  { value: 'America/North_Dakota/Center', text: 'North Dakota - Center' },
  { value: 'America/North_Dakota/New_Salem', text: 'North Dakota - New Salem' },
  { value: 'America/Ojinaga', text: 'Ojinaga' },
  { value: 'America/Panama', text: 'Panama' },
  { value: 'America/Pangnirtung', text: 'Pangnirtung' },
  { value: 'America/Paramaribo', text: 'Paramaribo' },
  { value: 'America/Phoenix', text: 'Phoenix' },
  { value: 'America/Port-au-Prince', text: 'Port-au-Prince' },
  { value: 'America/Porto_Acre', text: 'Porto Acre' },
  { value: 'America/Port_of_Spain', text: 'Port of Spain' },
  { value: 'America/Porto_Velho', text: 'Porto Velho' },
  { value: 'America/Puerto_Rico', text: 'Puerto Rico' },
  { value: 'America/Rainy_River', text: 'Rainy River' },
  { value: 'America/Rankin_Inlet', text: 'Rankin Inlet' },
  { value: 'America/Recife', text: 'Recife' },
  { value: 'America/Regina', text: 'Regina' },
  { value: 'America/Resolute', text: 'Resolute' },
  { value: 'America/Rio_Branco', text: 'Rio Branco' },
  { value: 'America/Rosario', text: 'Rosario' },
  { value: 'America/Santa_Isabel', text: 'Santa Isabel' },
  { value: 'America/Santarem', text: 'Santarem' },
  { value: 'America/Santiago', text: 'Santiago' },
  { value: 'America/Santo_Domingo', text: 'Santo Domingo' },
  { value: 'America/Sao_Paulo', text: 'Sao Paulo' },
  { value: 'America/Scoresbysund', text: 'Scoresbysund' },
  { value: 'America/Shiprock', text: 'Shiprock' },
  { value: 'America/St_Barthelemy', text: 'St Barthelemy' },
  { value: 'America/St_Johns', text: 'St Johns' },
  { value: 'America/St_Kitts', text: 'St Kitts' },
  { value: 'America/St_Lucia', text: 'St Lucia' },
  { value: 'America/St_Thomas', text: 'St Thomas' },
  { value: 'America/St_Vincent', text: 'St Vincent' },
  { value: 'America/Swift_Current', text: 'Swift Current' },
  { value: 'America/Tegucigalpa', text: 'Tegucigalpa' },
  { value: 'America/Thule', text: 'Thule' },
  { value: 'America/Thunder_Bay', text: 'Thunder Bay' },
  { value: 'America/Tijuana', text: 'Tijuana' },
  { value: 'America/Toronto', text: 'Toronto' },
  { value: 'America/Tortola', text: 'Tortola' },
  { value: 'America/Vancouver', text: 'Vancouver' },
  { value: 'America/Virgin', text: 'Virgin' },
  { value: 'America/Whitehorse', text: 'Whitehorse' },
  { value: 'America/Winnipeg', text: 'Winnipeg' },
  { value: 'America/Yakutat', text: 'Yakutat' },
  { value: 'America/Yellowknife', text: 'Yellowknife' },
  { value: 'Europe/Amsterdam', text: 'Amsterdam' },
  { value: 'Europe/Andorra', text: 'Andorra' },
  { value: 'Europe/Athens', text: 'Athens' },
  { value: 'Europe/Belfast', text: 'Belfast' },
  { value: 'Europe/Belgrade', text: 'Belgrade' },
  { value: 'Europe/Berlin', text: 'Berlin' },
  { value: 'Europe/Bratislava', text: 'Bratislava' },
  { value: 'Europe/Brussels', text: 'Brussels' },
  { value: 'Europe/Bucharest', text: 'Bucharest' },
  { value: 'Europe/Budapest', text: 'Budapest' },
  { value: 'Europe/Chisinau', text: 'Chisinau' },
  { value: 'Europe/Copenhagen', text: 'Copenhagen' },
  { value: 'Europe/Dublin', text: 'Dublin' },
  { value: 'Europe/Gibraltar', text: 'Gibraltar' },
  { value: 'Europe/Guernsey', text: 'Guernsey' },
  { value: 'Europe/Helsinki', text: 'Helsinki' },
  { value: 'Europe/Isle_of_Man', text: 'Isle of Man' },
  { value: 'Europe/Istanbul', text: 'Istanbul' },
  { value: 'Europe/Jersey', text: 'Jersey' },
  { value: 'Europe/Kaliningrad', text: 'Kaliningrad' },
  { value: 'Europe/Kiev', text: 'Kiev' },
  { value: 'Europe/Lisbon', text: 'Lisbon' },
  { value: 'Europe/Ljubljana', text: 'Ljubljana' },
  { value: 'Europe/London', text: 'London' },
  { value: 'Europe/Luxembourg', text: 'Luxembourg' },
  { value: 'Europe/Madrid', text: 'Madrid' },
  { value: 'Europe/Malta', text: 'Malta' },
  { value: 'Europe/Mariehamn', text: 'Mariehamn' },
  { value: 'Europe/Minsk', text: 'Minsk' },
  { value: 'Europe/Monaco', text: 'Monaco' },
  { value: 'Europe/Moscow', text: 'Moscow' },
  { value: 'Europe/Nicosia', text: 'Nicosia' },
  { value: 'Europe/Oslo', text: 'Oslo' },
  { value: 'Europe/Paris', text: 'Paris' },
  { value: 'Europe/Podgorica', text: 'Podgorica' },
  { value: 'Europe/Prague', text: 'Prague' },
  { value: 'Europe/Riga', text: 'Riga' },
  { value: 'Europe/Rome', text: 'Rome' },
  { value: 'Europe/Samara', text: 'Samara' },
  { value: 'Europe/San_Marino', text: 'San Marino' },
  { value: 'Europe/Sarajevo', text: 'Sarajevo' },
  { value: 'Europe/Simferopol', text: 'Simferopol' },
  { value: 'Europe/Skopje', text: 'Skopje' },
  { value: 'Europe/Sofia', text: 'Sofia' },
  { value: 'Europe/Stockholm', text: 'Stockholm' },
  { value: 'Europe/Tallinn', text: 'Tallinn' },
  { value: 'Europe/Tirane', text: 'Tirane' },
  { value: 'Europe/Tiraspol', text: 'Tiraspol' },
  { value: 'Europe/Uzhgorod', text: 'Uzhgorod' },
  { value: 'Europe/Vaduz', text: 'Vaduz' },
  { value: 'Europe/Vatican', text: 'Vatican' },
  { value: 'Europe/Vienna', text: 'Vienna' },
  { value: 'Europe/Vilnius', text: 'Vilnius' },
  { value: 'Europe/Volgograd', text: 'Volgograd' },
  { value: 'Europe/Warsaw', text: 'Warsaw' },
  { value: 'Europe/Zagreb', text: 'Zagreb' },
  { value: 'Europe/Zaporozhye', text: 'Zaporozhye' },
  { value: 'Europe/Zurich', text: 'Zurich' },
  { value: 'Asia/Aden', text: 'Aden' },
  { value: 'Asia/Almaty', text: 'Almaty' },
  { value: 'Asia/Amman', text: 'Amman' },
  { value: 'Asia/Anadyr', text: 'Anadyr' },
  { value: 'Asia/Aqtau', text: 'Aqtau' },
  { value: 'Asia/Aqtobe', text: 'Aqtobe' },
  { value: 'Asia/Ashgabat', text: 'Ashgabat' },
  { value: 'Asia/Ashkhabad', text: 'Ashkhabad' },
  { value: 'Asia/Baghdad', text: 'Baghdad' },
  { value: 'Asia/Bahrain', text: 'Bahrain' },
  { value: 'Asia/Baku', text: 'Baku' },
  { value: 'Asia/Bangkok', text: 'Bangkok' },
  { value: 'Asia/Beirut', text: 'Beirut' },
  { value: 'Asia/Bishkek', text: 'Bishkek' },
  { value: 'Asia/Brunei', text: 'Brunei' },
  { value: 'Asia/Calcutta', text: 'Calcutta' },
  { value: 'Asia/Choibalsan', text: 'Choibalsan' },
  { value: 'Asia/Chongqing', text: 'Chongqing' },
  { value: 'Asia/Chungking', text: 'Chungking' },
  { value: 'Asia/Colombo', text: 'Colombo' },
  { value: 'Asia/Dacca', text: 'Dacca' },
  { value: 'Asia/Damascus', text: 'Damascus' },
  { value: 'Asia/Dhaka', text: 'Dhaka' },
  { value: 'Asia/Dili', text: 'Dili' },
  { value: 'Asia/Dubai', text: 'Dubai' },
  { value: 'Asia/Dushanbe', text: 'Dushanbe' },
  { value: 'Asia/Gaza', text: 'Gaza' },
  { value: 'Asia/Harbin', text: 'Harbin' },
  { value: 'Asia/Ho_Chi_Minh', text: 'Ho Chi Minh' },
  { value: 'Asia/Hong_Kong', text: 'Hong Kong' },
  { value: 'Asia/Hovd', text: 'Hovd' },
  { value: 'Asia/Irkutsk', text: 'Irkutsk' },
  { value: 'Asia/Istanbul', text: 'Istanbul' },
  { value: 'Asia/Jakarta', text: 'Jakarta' },
  { value: 'Asia/Jayapura', text: 'Jayapura' },
  { value: 'Asia/Jerusalem', text: 'Jerusalem' },
  { value: 'Asia/Kabul', text: 'Kabul' },
  { value: 'Asia/Kamchatka', text: 'Kamchatka' },
  { value: 'Asia/Karachi', text: 'Karachi' },
  { value: 'Asia/Kashgar', text: 'Kashgar' },
  { value: 'Asia/Kathmandu', text: 'Kathmandu' },
  { value: 'Asia/Katmandu', text: 'Katmandu' },
  { value: 'Asia/Kolkata', text: 'Kolkata' },
  { value: 'Asia/Krasnoyarsk', text: 'Krasnoyarsk' },
  { value: 'Asia/Kuala_Lumpur', text: 'Kuala Lumpur' },
  { value: 'Asia/Kuching', text: 'Kuching' },
  { value: 'Asia/Kuwait', text: 'Kuwait' },
  { value: 'Asia/Macao', text: 'Macao' },
  { value: 'Asia/Macau', text: 'Macau' },
  { value: 'Asia/Magadan', text: 'Magadan' },
  { value: 'Asia/Makassar', text: 'Makassar' },
  { value: 'Asia/Manila', text: 'Manila' },
  { value: 'Asia/Muscat', text: 'Muscat' },
  { value: 'Asia/Nicosia', text: 'Nicosia' },
  { value: 'Asia/Novokuznetsk', text: 'Novokuznetsk' },
  { value: 'Asia/Novosibirsk', text: 'Novosibirsk' },
  { value: 'Asia/Omsk', text: 'Omsk' },
  { value: 'Asia/Oral', text: 'Oral' },
  { value: 'Asia/Phnom_Penh', text: 'Phnom Penh' },
  { value: 'Asia/Pontianak', text: 'Pontianak' },
  { value: 'Asia/Pyongyang', text: 'Pyongyang' },
  { value: 'Asia/Qatar', text: 'Qatar' },
  { value: 'Asia/Qyzylorda', text: 'Qyzylorda' },
  { value: 'Asia/Rangoon', text: 'Rangoon' },
  { value: 'Asia/Riyadh', text: 'Riyadh' },
  { value: 'Asia/Saigon', text: 'Saigon' },
  { value: 'Asia/Sakhalin', text: 'Sakhalin' },
  { value: 'Asia/Samarkand', text: 'Samarkand' },
  { value: 'Asia/Seoul', text: 'Seoul' },
  { value: 'Asia/Shanghai', text: 'Shanghai' },
  { value: 'Asia/Singapore', text: 'Singapore' },
  { value: 'Asia/Taipei', text: 'Taipei' },
  { value: 'Asia/Tashkent', text: 'Tashkent' },
  { value: 'Asia/Tbilisi', text: 'Tbilisi' },
  { value: 'Asia/Tehran', text: 'Tehran' },
  { value: 'Asia/Tel_Aviv', text: 'Tel Aviv' },
  { value: 'Asia/Thimbu', text: 'Thimbu' },
  { value: 'Asia/Thimphu', text: 'Thimphu' },
  { value: 'Asia/Tokyo', text: 'Tokyo' },
  { value: 'Asia/Ujung_Pandang', text: 'Ujung Pandang' },
  { value: 'Asia/Ulaanbaatar', text: 'Ulaanbaatar' },
  { value: 'Asia/Ulan_Bator', text: 'Ulan Bator' },
  { value: 'Asia/Urumqi', text: 'Urumqi' },
  { value: 'Asia/Vientiane', text: 'Vientiane' },
  { value: 'Asia/Vladivostok', text: 'Vladivostok' },
  { value: 'Asia/Yakutsk', text: 'Yakutsk' },
  { value: 'Asia/Yekaterinburg', text: 'Yekaterinburg' },
  { value: 'Asia/Yerevan', text: 'Yerevan' },
  { value: 'Africa/Abidjan', text: 'Abidjan' },
  { value: 'Africa/Accra', text: 'Accra' },
  { value: 'Africa/Addis_Ababa', text: 'Addis Ababa' },
  { value: 'Africa/Algiers', text: 'Algiers' },
  { value: 'Africa/Asmara', text: 'Asmara' },
  { value: 'Africa/Asmera', text: 'Asmera' },
  { value: 'Africa/Bamako', text: 'Bamako' },
  { value: 'Africa/Bangui', text: 'Bangui' },
  { value: 'Africa/Banjul', text: 'Banjul' },
  { value: 'Africa/Bissau', text: 'Bissau' },
  { value: 'Africa/Blantyre', text: 'Blantyre' },
  { value: 'Africa/Brazzaville', text: 'Brazzaville' },
  { value: 'Africa/Bujumbura', text: 'Bujumbura' },
  { value: 'Africa/Cairo', text: 'Cairo' },
  { value: 'Africa/Casablanca', text: 'Casablanca' },
  { value: 'Africa/Ceuta', text: 'Ceuta' },
  { value: 'Africa/Conakry', text: 'Conakry' },
  { value: 'Africa/Dakar', text: 'Dakar' },
  { value: 'Africa/Dar_es_Salaam', text: 'Dar es Salaam' },
  { value: 'Africa/Djibouti', text: 'Djibouti' },
  { value: 'Africa/Douala', text: 'Douala' },
  { value: 'Africa/El_Aaiun', text: 'El Aaiun' },
  { value: 'Africa/Freetown', text: 'Freetown' },
  { value: 'Africa/Gaborone', text: 'Gaborone' },
  { value: 'Africa/Harare', text: 'Harare' },
  { value: 'Africa/Johannesburg', text: 'Johannesburg' },
  { value: 'Africa/Kampala', text: 'Kampala' },
  { value: 'Africa/Khartoum', text: 'Khartoum' },
  { value: 'Africa/Kigali', text: 'Kigali' },
  { value: 'Africa/Kinshasa', text: 'Kinshasa' },
  { value: 'Africa/Lagos', text: 'Lagos' },
  { value: 'Africa/Libreville', text: 'Libreville' },
  { value: 'Africa/Lome', text: 'Lome' },
  { value: 'Africa/Luanda', text: 'Luanda' },
  { value: 'Africa/Lubumbashi', text: 'Lubumbashi' },
  { value: 'Africa/Lusaka', text: 'Lusaka' },
  { value: 'Africa/Malabo', text: 'Malabo' },
  { value: 'Africa/Maputo', text: 'Maputo' },
  { value: 'Africa/Maseru', text: 'Maseru' },
  { value: 'Africa/Mbabane', text: 'Mbabane' },
  { value: 'Africa/Mogadishu', text: 'Mogadishu' },
  { value: 'Africa/Monrovia', text: 'Monrovia' },
  { value: 'Africa/Nairobi', text: 'Nairobi' },
  { value: 'Africa/Ndjamena', text: 'Ndjamena' },
  { value: 'Africa/Niamey', text: 'Niamey' },
  { value: 'Africa/Nouakchott', text: 'Nouakchott' },
  { value: 'Africa/Ouagadougou', text: 'Ouagadougou' },
  { value: 'Africa/Porto-Novo', text: 'Porto-Novo' },
  { value: 'Africa/Sao_Tome', text: 'Sao Tome' },
  { value: 'Africa/Timbuktu', text: 'Timbuktu' },
  { value: 'Africa/Tripoli', text: 'Tripoli' },
  { value: 'Africa/Tunis', text: 'Tunis' },
  { value: 'Africa/Windhoek', text: 'Windhoek' },
  { value: 'Australia/ACT', text: 'ACT' },
  { value: 'Australia/Adelaide', text: 'Adelaide' },
  { value: 'Australia/Brisbane', text: 'Brisbane' },
  { value: 'Australia/Broken_Hill', text: 'Broken Hill' },
  { value: 'Australia/Canberra', text: 'Canberra' },
  { value: 'Australia/Currie', text: 'Currie' },
  { value: 'Australia/Darwin', text: 'Darwin' },
  { value: 'Australia/Eucla', text: 'Eucla' },
  { value: 'Australia/Hobart', text: 'Hobart' },
  { value: 'Australia/LHI', text: 'LHI' },
  { value: 'Australia/Lindeman', text: 'Lindeman' },
  { value: 'Australia/Lord_Howe', text: 'Lord Howe' },
  { value: 'Australia/Melbourne', text: 'Melbourne' },
  { value: 'Australia/North', text: 'North' },
  { value: 'Australia/NSW', text: 'NSW' },
  { value: 'Australia/Perth', text: 'Perth' },
  { value: 'Australia/Queensland', text: 'Queensland' },
  { value: 'Australia/South', text: 'South' },
  { value: 'Australia/Sydney', text: 'Sydney' },
  { value: 'Australia/Tasmania', text: 'Tasmania' },
  { value: 'Australia/Victoria', text: 'Victoria' },
  { value: 'Australia/West', text: 'West' },
  { value: 'Australia/Yancowinna', text: 'Yancowinna' },
  { value: 'Indian/Antananarivo', text: 'Antananarivo' },
  { value: 'Indian/Chagos', text: 'Chagos' },
  { value: 'Indian/Christmas', text: 'Christmas' },
  { value: 'Indian/Cocos', text: 'Cocos' },
  { value: 'Indian/Comoro', text: 'Comoro' },
  { value: 'Indian/Kerguelen', text: 'Kerguelen' },
  { value: 'Indian/Mahe', text: 'Mahe' },
  { value: 'Indian/Maldives', text: 'Maldives' },
  { value: 'Indian/Mauritius', text: 'Mauritius' },
  { value: 'Indian/Mayotte', text: 'Mayotte' },
  { value: 'Indian/Reunion', text: 'Reunion' },
  { value: 'Atlantic/Azores', text: 'Azores' },
  { value: 'Atlantic/Bermuda', text: 'Bermuda' },
  { value: 'Atlantic/Canary', text: 'Canary' },
  { value: 'Atlantic/Cape_Verde', text: 'Cape Verde' },
  { value: 'Atlantic/Faeroe', text: 'Faeroe' },
  { value: 'Atlantic/Faroe', text: 'Faroe' },
  { value: 'Atlantic/Jan_Mayen', text: 'Jan Mayen' },
  { value: 'Atlantic/Madeira', text: 'Madeira' },
  { value: 'Atlantic/Reykjavik', text: 'Reykjavik' },
  { value: 'Atlantic/South_Georgia', text: 'South Georgia' },
  { value: 'Atlantic/Stanley', text: 'Stanley' },
  { value: 'Atlantic/St_Helena', text: 'St Helena' },
  { value: 'Pacific/Apia', text: 'Apia' },
  { value: 'Pacific/Auckland', text: 'Auckland' },
  { value: 'Pacific/Chatham', text: 'Chatham' },
  { value: 'Pacific/Easter', text: 'Easter' },
  { value: 'Pacific/Efate', text: 'Efate' },
  { value: 'Pacific/Enderbury', text: 'Enderbury' },
  { value: 'Pacific/Fakaofo', text: 'Fakaofo' },
  { value: 'Pacific/Fiji', text: 'Fiji' },
  { value: 'Pacific/Funafuti', text: 'Funafuti' },
  { value: 'Pacific/Galapagos', text: 'Galapagos' },
  { value: 'Pacific/Gambier', text: 'Gambier' },
  { value: 'Pacific/Guadalcanal', text: 'Guadalcanal' },
  { value: 'Pacific/Guam', text: 'Guam' },
  { value: 'Pacific/Honolulu', text: 'Honolulu' },
  { value: 'Pacific/Johnston', text: 'Johnston' },
  { value: 'Pacific/Kiritimati', text: 'Kiritimati' },
  { value: 'Pacific/Kosrae', text: 'Kosrae' },
  { value: 'Pacific/Kwajalein', text: 'Kwajalein' },
  { value: 'Pacific/Majuro', text: 'Majuro' },
  { value: 'Pacific/Marquesas', text: 'Marquesas' },
  { value: 'Pacific/Midway', text: 'Midway' },
  { value: 'Pacific/Nauru', text: 'Nauru' },
  { value: 'Pacific/Niue', text: 'Niue' },
  { value: 'Pacific/Norfolk', text: 'Norfolk' },
  { value: 'Pacific/Noumea', text: 'Noumea' },
  { value: 'Pacific/Pago_Pago', text: 'Pago Pago' },
  { value: 'Pacific/Palau', text: 'Palau' },
  { value: 'Pacific/Pitcairn', text: 'Pitcairn' },
  { value: 'Pacific/Ponape', text: 'Ponape' },
  { value: 'Pacific/Port_Moresby', text: 'Port Moresby' },
  { value: 'Pacific/Rarotonga', text: 'Rarotonga' },
  { value: 'Pacific/Saipan', text: 'Saipan' },
  { value: 'Pacific/Samoa', text: 'Samoa' },
  { value: 'Pacific/Tahiti', text: 'Tahiti' },
  { value: 'Pacific/Tarawa', text: 'Tarawa' },
  { value: 'Pacific/Tongatapu', text: 'Tongatapu' },
  { value: 'Pacific/Truk', text: 'Truk' },
  { value: 'Pacific/Wake', text: 'Wake' },
  { value: 'Pacific/Wallis', text: 'Wallis' },
  { value: 'Pacific/Yap', text: 'Yap' },
  { value: 'Antarctica/Casey', text: 'Casey' },
  { value: 'Antarctica/Davis', text: 'Davis' },
  { value: 'Antarctica/DumontDUrville', text: 'DumontDUrville' },
  { value: 'Antarctica/Macquarie', text: 'Macquarie' },
  { value: 'Antarctica/Mawson', text: 'Mawson' },
  { value: 'Antarctica/McMurdo', text: 'McMurdo' },
  { value: 'Antarctica/Palmer', text: 'Palmer' },
  { value: 'Antarctica/Rothera', text: 'Rothera' },
  { value: 'Antarctica/South_Pole', text: 'South Pole' },
  { value: 'Antarctica/Syowa', text: 'Syowa' },
  { value: 'Antarctica/Vostok', text: 'Vostok' },
  { value: 'Arctic/Longyearbyen', text: 'Longyearbyen' },
  { value: 'UTC', text: 'UTC' },
];

export const TIMEZONES = timezones.map((timezone) => {
  const { value } = timezone;
  return { value: value, text: value };
});

/**
 * Get UNIX Timestamp From String
 * @param {string} dateStr 
 * @returns {int}
 */
export const getUnixTimestamp = dateStr => {
  return parseInt(moment(dateStr).format('X'));
}