import styled from 'styled-components';

export const BetMiniWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  padding: 2rem 3rem;
  background-color: var(--color-white);
  margin-bottom: 4rem;
  width: 100%;
  display: ${({ trending, explore }) =>
    trending && explore ? 'none' : 'flex'};
  flex-direction: ${({ trending }) => (trending ? 'column' : 'horizontal')};
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  p.title {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
  }
  p.title-creator {
    margin-top: 1rem;
    font-weight: 700;
  }
  /* -webkit-box-shadow: 3px -1px 59px -8px rgba(150, 150, 151, 0.47);
  -moz-box-shadow: 3px -1px 59px -8px rgba(150, 150, 151, 0.47);
  box-shadow: 3px -1px 59px -8px rgba(150, 150, 151, 0.47); */
  /*   ::after {
    content: "";
    position: absolute;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 80%,
      rgba(255, 255, 255, 1) 100%
    );
    z-index: 3;
    width: 100%;
    height: 100%;

    right: 0%;
    top: 0%;
  } */
`;
