import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Wrapper, Container, InnerWrapper } from '../../hoc/layout/elements';
import { notificationsType } from '../../store/actions/todoActions';
import _ from 'lodash';
import * as actions from '../../store/actions';
import GiveARating from './GiveARating';
import Modal from '../../components/UI/Modal/Modal';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
/*
 *   Styled components below
 */

const OuterCard = styled.div`
  background-color: white;
  height: 9rem;
  width: 90%;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 5px 10px #e7e7e7;
  z-index: 1;
`;

const InnerCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50%;
`;

const CardAvatar = styled.div`
  margin-left: 4%;
  margin-top: 4%;
`;

const CardTopLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 3%;
  margin-left: 3%;
  white-space: nowrap;
`;

export const CardRightArrow = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-right: 3%;
  margin-top: 3%;
`;

const CardNotificationText = styled.div`
  margin-left: 5%;
  margin-top: 1%;
`;

const NoNotificationsText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40rem;
`;

const OlderNotificationsDivider = styled.div`
  width: 90%;
  margin-left: 1rem;
  margin-bottom: 2rem;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
`;

/*
 *   End of Styled components
 */

const getNotificationText = (
  notificationType,
  username = undefined,
  title = undefined,
) => {
  switch (notificationType) {
    case notificationsType.offered:
      return (
        username + ' has offered you a bet. View to accept, reject or modify'
      );
    case notificationsType.accepted:
      return username + ' has accepted your bet.';
    case notificationsType.rejected:
      return username + ' has rejected your bet.';
    case notificationsType.claimWon:
      return username + ' says they’ve won a bet!';
    case notificationsType.claimLost:
      return username + ' says they’ve lost a bet!';
    case notificationsType.bothSaidWonDispute:
      return (
        username +
        ' also claims that he won the bet. Bet ' +
        title +
        ' resolution will be determined by jury arbitration'
      );
    case notificationsType.bothSaidLostDispute:
      return (
        username +
        ' also claims that he lost the bet. Bet ' +
        title +
        ' resolution will be determined by jury arbitration'
      );
    case notificationsType.won:
      return username + " has won the '" + title + "' bet";
    case notificationsType.lost:
      return username + " has lost the '" + title + "' bet. You have won";
    case notificationsType.wonOnCourt:
      return (
        'Court has decided ! ' +
        "Congratulations, you have won the '" +
        title +
        "' bet"
      );
    case notificationsType.lostOnCourt:
      return (
        'Court has decided ! ' +
        "Unfortunately, you have lost the '" +
        title +
        "' bet"
      );
    case notificationsType.paid:
      return "You've been paid";
    case notificationsType.reminderToPay:
      return title + ' payment still due';
    case notificationsType.gotPaid:
      return (
        'Your bet with ' +
        username +
        ' was concluded. Leave ' +
        username +
        ' a review!'
      );
  }
};

const getHowLongAgoInMinutes = (createtAt) => {
  return (new Date().getTime() - createtAt) / 1000 / 60;
};

const getHowLongAgoText = (howLongAgoInMinutes) => {
  if (howLongAgoInMinutes < 60) {
    return Math.floor(howLongAgoInMinutes) + ' minutes ago';
  } else if (howLongAgoInMinutes < 1440) {
    return Math.floor(howLongAgoInMinutes / 60) + ' hours ago';
  } else {
    return Math.floor(howLongAgoInMinutes / 60 / 24) + ' days ago';
  }
};

async function routeChange(props, item) {
  props.history.push('/myBets', { notification: item });
  await props.markNotificationAsRead(item);
}

const renderCard = (
  notifications,
  userId,
  props,
  setIsToBeRated,
  setOpponentUserId,
) => {
  const notificationAction = (item) => {
    if (item.type === 'gotPaid') {
      setOpponentUserId(item.senderId);
      setIsToBeRated(true);
      props.markNotificationAsRead(item);
    } else if (item.type === 'rejected') {
      props.markNotificationAsRead(item);
    } else {
      routeChange(props, item);
    }
  };

  return (
    notifications &&
    notifications.map((item) => {
      if (item.read === false) {
        let otherUserPic;
        let otherUserPicPlaceHolder =
          'https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Ficons.iconarchive.com%2Ficons%2Fpapirus-team%2Fpapirus-status%2F512%2Favatar-default-icon.png&f=1&nofb=1';
        item.senderPic === '' ||
        item.senderPic === null ||
        item.senderPic === undefined
          ? (otherUserPic = otherUserPicPlaceHolder)
          : (otherUserPic = item.senderPic);

        return (
          <OuterCard>
            <InnerCard>
              <CardAvatar>
                {item.senderId !== 'Court House' ? (
                  <Link
                    to={{
                      pathname: `profile/${item.senderId}/`,
                      state: { prevPath: props.location.pathname },
                    }}
                  >
                    <img
                      src={otherUserPic}
                      style={{ height: 30, width: 30, borderRadius: 50 }}
                    />
                  </Link>
                ) : (
                  <img
                    src={otherUserPic}
                    style={{ height: 30, width: 30, borderRadius: 50 }}
                  />
                )}
              </CardAvatar>

              <CardTopLabels>
                {item.senderId !== 'Court House' ? (
                  <Link
                    to={{
                      pathname: `profile/${item.senderId}/`,
                      state: { prevPath: props.location.pathname },
                    }}
                  >
                    <label
                      style={{ fontSize: 12, marginLeft: 0, width: 'auto' }}
                    >
                      {' '}
                      {item.senderUsername}{' '}
                    </label>
                  </Link>
                ) : (
                  <label style={{ fontSize: 12, marginLeft: 0, width: 'auto' }}>
                    {' '}
                    {item.senderUsername}{' '}
                  </label>
                )}

                <label
                  style={{
                    color: '#723EFF',
                    fontSize: 8,
                    width: '100%',
                  }}
                >
                  {' '}
                  {getHowLongAgoText(
                    getHowLongAgoInMinutes(item.createdAt),
                  )}{' '}
                </label>
              </CardTopLabels>

              <CardRightArrow>
                <i
                  className={
                    item.type === 'rejected'
                      ? 'fas fa-times'
                      : 'fa fa-angle-right'
                  }
                  style={{
                    color: '#49D0FF',
                    fontSize: 16,
                    fontWeight: 'bold',
                  }}
                  onClick={() => notificationAction(item)}
                />
              </CardRightArrow>
            </InnerCard>
            <CardNotificationText onClick={() => notificationAction(item)}>
              <label style={{ fontWeight: 500 }}>
                {' '}
                {getNotificationText(
                  item.type,
                  item.senderUsername,
                  item.betTitle,
                )}{' '}
              </label>
            </CardNotificationText>
          </OuterCard>
        );
      } else {
        return <></>;
      }
    })
  );
};

const Notifications = (props) => {
  const [isToBeRated, setIsToBeRated] = useState(false);
  const [opponentUserId, setOpponentUserId] = useState('');
  let dayInMinutes = 60 * 24;
  let notificationsClone = _.cloneDeep(props.notifications); // GV the deep clone of the object is necessary so that it can later be sorted by the createdAt field
  // the sort() method is used below and changes the associated array of objects, since props.notifications is a to read only field a deep clone had to be made
  let recentNotifications = [];
  let olderNotifications = [];
  if (notificationsClone) {
    notificationsClone.sort(function (a, b) {
      return b.createdAt - a.createdAt;
    });

    recentNotifications = notificationsClone.filter(
      (item) =>
        getHowLongAgoInMinutes(item.createdAt) < dayInMinutes &&
        item.read === false &&
        item.receiverId === props.userId,
    );
    olderNotifications = notificationsClone.filter(
      (item) =>
        getHowLongAgoInMinutes(item.createdAt) >= dayInMinutes &&
        item.read === false &&
        item.receiverId === props.userId,
    );
  }

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          {recentNotifications.length < 1 && olderNotifications.length < 1 ? (
            <NoNotificationsText>
              <h1>No notifications !</h1>
            </NoNotificationsText>
          ) : (
            <></>
          )}
          {recentNotifications.length > 0 ? (
            renderCard(
              recentNotifications,
              props.userId,
              props,
              setIsToBeRated,
              setOpponentUserId,
            )
          ) : (
            <></>
          )}
          {olderNotifications.length > 0 ? (
            <OlderNotificationsDivider>
              <hr style={{ borderWidth: 1, color: '#E7E7E7' }} />
              <label
                style={{
                  color: 'lightgrey',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                OLDER
              </label>
            </OlderNotificationsDivider>
          ) : (
            <></>
          )}
          {olderNotifications.length > 0 ? (
            renderCard(
              olderNotifications,
              props.userId,
              props,
              setIsToBeRated,
              setOpponentUserId,
            )
          ) : (
            <></>
          )}
          <Modal
            opened={isToBeRated}
            close={() => console.log('closing')}
            styles={{ top: '50%' }}
          >
            <GiveARating
              setIsToBeRated={setIsToBeRated}
              oponentUserId={opponentUserId}
            />
          </Modal>
        </InnerWrapper>
      </Container>
    </Wrapper>
  );
};

const mapStateToProps = ({ firebase, firestore }) => ({
  userId: firebase.auth.uid,
  notifications: firestore.ordered.notifications,
});

const mapDispatchToProps = {
  markNotificationAsRead: actions.markNotificationAsRead,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'notifications',
        where: [['receiverId', '==', props.userId]],
      },
    ];
  }),
)(Notifications);
