// import * as actions from '../../store/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import Badge from '@material-ui/core/Badge';
// import NotificationsIcon from '../../assets/NotificationsIcon.png';
import React from 'react';
// import Img from './Img';
import { Icon } from '@iconify/react';
import bellIcon from '@iconify/icons-whh/bell';

const CustomBadge = (props) => {
  return (
    <div>
      {props.notifications ? (
        <Badge
          badgeContent={
            props.notifications.filter(
              (item) => item.receiverId === props.userId && item.read === false,
            ).length
          }
          color="secondary"
        >
          {/* <Img
            src={NotificationsIcon}
            dimensions={{
              width: '1.8rem',
              height: '1.8rem',
            }}
          /> */}
          <Icon icon={bellIcon} width="1.8rem" height="1.8rem" />
        </Badge>
      ) : (
        // <Img
        //   src={NotificationsIcon}
        //   dimensions={{
        //     width: '1.8rem',
        //     height: '1.8rem',
        //   }}
        // />
        <Icon icon={bellIcon} width="1.8rem" height="1.8rem" />
      )}
    </div>
  );
};

const mapStateToProps = ({ firebase, firestore }) => ({
  notifications: firestore.ordered.notifications,
  userId: firebase.auth.uid,
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: 'notifications',
      },
    ];
  }),
)(CustomBadge);
