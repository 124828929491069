import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import moment from 'moment';
import { getUnixTimestamp } from '../../../utils/timezones';
/* import { Form, Select, Input } from "react-formik-ui"; */
import Message from '../../../components/UI/Message';
import Input from '../../../components/UI/Forms/Input/Input';
import InputTitle from '../../../components/UI/Forms/Input/InputTitle';
import { FormWrapper, StyledForm } from '../../../hoc/layout/elements';
import DropdownField from '../DropdownField/DropdownField';
import ButtonTop from '../../../components/UI/Forms/Button/ButtonTop';
import Heading from '../../../components/UI/Heading';

import 'react-datepicker/dist/react-datepicker.css';
import Button from '../../../components/UI/Forms/Button/Button';

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 3rem;
  justify-content: space-around;
`;
export const ButtonTopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ one }) => (one ? 'flex-start' : 'space-between')};
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 1rem;

  button {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }
  /* @media ${(props) =>
    props.theme.mediaQueries
      .smallerSmallestHeight} {
    margin-bottom: 0.5rem;
  } */
  position: relative;
`;

export const Label = styled.div`
  color: var(--color-greyColor);
  font-size: 1.2rem;
  margin-right: 1rem;
  margin-top: 0.4rem;
  width: 45%;

  @media ${(props) => props.theme.mediaQueries.smallerSmallishHeight} {
    width: 50%;
  }

  display: flex;
  align-items: center;
  @media ${(props) => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 1rem;
  }
  /*  @media ${(props) =>
    props.theme.mediaQueries
      .smallerSmallestHeight} {
    font-size: 0.8rem;
  } */

  span {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: var(--color-greyColor) transparent transparent transparent;
    margin-left: 0.7rem;
  }
`;

export const OptionForm = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const MessageWrapper = styled.div`
  position: absolute;
  bottom: 0rem;
  width: 100%;
  padding: 0 3rem;
`;

const DATE_TIME_REGEX = /([12]\d{3}-([1-9]|0[1-9]|1[0-2])-([1-9]|0[1-9]|[12][0-9]|3[01]))T([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

const BetSchema = Yup.object().shape({
  title: Yup.string()
    .required('The title is required.')
    .min(4, 'Minimum 4 characters.')
    .max(160, 'Maximum 160 characters.'),
  eventStart: Yup.date()
    .test('futureTime', 'Please insert a future date and time.', (value) => {
      // eventStart must be a future date with a 5 minute leniency
      const currentTimestamp = moment(value).format('X');
      const minimumTimestamp = moment().subtract('5', 'minutes').format('X');
      return currentTimestamp >= minimumTimestamp;
    }),
  betExpiry: Yup.date()
    .test('validExpiry', 'Bet cannot expire after the event date.', function (value) {
      return moment(value).format('X') <= moment(this.parent.eventStart).format('X');
    }),
  amount: Yup.number().required('Amount wager is requried'),
  currency: Yup.string().required('A currency is required'),
});



const InputBet = ({
  loading,
  error,
  close,
  bet,
  type,
  userId,
  bets,
  users,
  direct,
  setPostedBet,
  setIsConfirmBet,
  profileUser,
}) => {

  useEffect(() => {
    if (bet) {
      setPlaceHolder('none');
    }
  }, [bet]);

  const [isConfirmBet, setConfirmView] = useState(false);
  const [placeHolder, setPlaceHolder] = useState('block');

  // const placeHolderEvStartRef = React.useRef();
  // const placeHolderEvEndRef = React.useRef();
  const placeHolderBetExpiryRef = React.useRef();

  const loadingText = () => {
    if (type === 'modify') {
      return 'Modifying...';
    } else {
      return 'Posting...';
    }
  };

  const buttonText = () => {
    if (type === 'modify') {
      return 'Modify';
    } else {
      return 'Post';
    }
  };

  const titleText = () => {
    if (type === 'modify') {
      return 'Modify Bet';
    } else {
      return 'Make a Bet';
    }
  };

  const FieldGroup = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
  `;

  const contestants = bets && bets.map((bet) => bet.contestantID);
  const creators = bets && bets.map((bet) => bet.creatorID);
  const opponents =
    bets &&
    [...new Set([...contestants, ...creators])].filter(
      (opponent) => opponent && opponent !== userId,
    );
  const mostRecentUsers =
    users && opponents && users.filter((user) => opponents.includes(user.id));

  const addMaximumScaleToMetaViewport = () => {
    const el = document.querySelector('meta[name=viewport]');

    if (el !== null) {
      let content = el.getAttribute('content');
      let re = /maximum\-scale=[0-9\.]+/g;

      if (re.test(content)) {
        content = content.replace(re, 'maximum-scale=1.0');
      } else {
        content = [content, 'maximum-scale=1.0'].join(', ');
      }

      el.setAttribute('content', content);
    }
  };

  const disableIosTextFieldZoom = addMaximumScaleToMetaViewport;

  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios/9039885#9039885
  const checkIsIOS = () =>
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  if (checkIsIOS()) {
    disableIosTextFieldZoom();
  }

  return (
    <>
      <Formik
        initialValues={{
          title: bet ? bet.title : '',
          amount: bet ? bet.amount : '',
          eventStart: bet
            ? moment(bet.eventStart, 'X').format('YYYY-MM-DDTHH:mm')
            : moment().format('YYYY-MM-DDTHH:mm'),
          betExpiry: bet
            ? moment(bet.betExpiry, 'X').format('YYYY-MM-DDTHH:mm')
            : moment().format('YYYY-MM-DDTHH:mm'),
          currency: bet ? { value: bet.currency, label: bet.currency } : '',
          settlement: bet
            ? { value: bet.settlement, label: bet.settlement }
            : { value: 'E-Transfer', label: 'E-Transfer' },
          privacy: bet ? { value: bet.privacy, label: bet.privacy } : 'Public',
          sendBet: bet
            ? { value: bet.sendBet, label: bet.sendBet }
            : direct
            ? 'Direct'
            : 'Global', // GV forcing the default value of sendBet to be Global when it's not a direct bet
          offeredToID: profileUser ? profileUser.id : null,
        }}
        enableReinitialize
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={BetSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          // submit bet
          // const contestant =
          //   users &&
          //   users.find(user => user.username === values.username.value);

          const settlement =
            values.currency.value === 'BTC' ||
            values.currency.value === 'WRX' ||
            values.currency.value === 'TRX'
              ? 'Crypto Transaction'
              : 'E-Transfer';

          const finalBet = {
            ...values,
            // contestantID: contestant ? contestant.id : '',
            // contestantUsername: contestant ? contestant.username : '',
            // contestantPic:
            //   contestant && contestant.avatarUrl ? contestant.avatarUrl : '',
            title: values.title.trim(),
            currency: values.currency.value
              ? values.currency.value
              : values.currency,
            settlement: settlement,
            privacy: 'Public',
            sendBet: values.sendBet.value
              ? values.sendBet.value
              : values.sendBet,
            eventStart: getUnixTimestamp(values.eventStart),
            betExpiry: getUnixTimestamp(values.betExpiry),
          };
          if (setPostedBet) {
            setPostedBet(finalBet);
          }
          if (setIsConfirmBet) {
            setIsConfirmBet(true);
          }

          //setIsConfirmBet(true);

          setConfirmView(true);
          setSubmitting(false);
          resetForm();
          close();
        }}
      >
        {({ resetForm, values, errors, handleChange }) => {
          const currencies = [
            { value: 'CAD', label: 'CAD' },
            { value: 'USD', label: 'USD' },
            { value: 'WBX', label: 'WBX' },
            { value: 'BTC', label: 'BTC' },
            { value: 'TRX', label: 'TRX' },
          ];

          const settlements = [
            { value: 'E-Transfer', label: 'E-Transfer' },
            { value: 'Cash', label: 'Cash' },
            { value: 'Paypal', label: 'Paypal' },
            { value: 'Venmo', label: 'Venmo' },
            { value: 'Crypto Transaction', label: 'Crypto Transaction' },
          ];

          const cryptoSettlement = [
            { value: 'Crypto Transaction', label: 'Crypto Transaction' },
          ];

          const privacies = [
            /*  { value: "Private", label: "Private" } */
            { value: 'Public', label: 'Public' },
          ];

          const sendBets = [
            { value: 'Direct', label: 'Direct' },
            { value: 'Global', label: 'Global' },
          ];

          const sendBetsMin = [{ value: 'Direct', label: 'Direct' }];
          return (
            <FormWrapper>
              <StyledForm>
                <div style={{ alignSelf: 'flex-start', marginTop: 10 }}>
                  <ButtonTop
                    type="button"
                    color="main"
                    contain
                    onClick={() => {
                      close();
                      resetForm();
                    }}
                  >
                    Cancel
                  </ButtonTop>
                </div>

                <div style={{ marginTop: -30, marginBottom: 15 }}>
                  <Heading
                    size="h2"
                    bold
                    noMargin
                    noMarginTop
                    color={'secondary'}
                  >
                    {titleText()}
                  </Heading>
                </div>

                <Field
                  type="text"
                  name="title"
                  placeholder="What's going to happen? What's your prediction? Use # to make your bets appear in search results!"
                  rows="3"
                  component={InputTitle}
                />

                {
                  <OptionForm>
                    <Label>
                      <p>EVENT DATE</p>
                      <span />
                    </Label>
                    <FieldGroup>
                      <Field
                        name="eventStart"
                        placeholder={'Select event date'}
                        value={values.eventStart}
                        component={Input}
                        type={'datetime-local'}
                        errors={errors}
                      />
                    </FieldGroup>
                  </OptionForm>
                }
                <OptionForm>
                  <Label>
                    <p>BET EXPIRY</p>
                    <span />
                  </Label>
                  <FieldGroup>
                    <Field
                      name="betExpiry"
                      placeholder="Select bet expiry"
                      component={Input}
                      errors={errors}
                      type={'datetime-local'}
                    />
                  </FieldGroup>
                </OptionForm>

                <OptionForm>
                  <div style={{ width: '50%', color: 'black', height: '50px' }}>
                    <Field
                      type="number"
                      pattern="\d*"
                      name="amount"
                      placeholder="Amount"
                      component={Input}
                      twoColumnsCase={true}
                    />
                  </div>
                  <div style={{ width: '50%', color: 'black', height: '50px' }}>
                    <Field
                      value={values.currency}
                      name="currency"
                      placeholder="Currency"
                      options={currencies}
                      component={DropdownField}
                      type={'currency'}
                    />
                  </div>
                </OptionForm>
                {type == 'modify' && bet.stage === 'Offered' ? (
                  <OptionForm>
                    <Label style={{ fontSize: '1.2rem', marginTop: 15 }}>
                      SEND BET
                      <span />
                    </Label>
                    <Field
                      value={
                        direct
                          ? { label: 'Direct', value: 'direct' }
                          : values.sendBet
                      }
                      name="sendBet"
                      placeholder={direct ? 'Direct' : 'Select Send Bet'}
                      options={
                        values.privacy.value === 'Private'
                          ? sendBetsMin
                          : sendBets
                      }
                      component={DropdownField}
                      width="100"
                      disabled={true}
                      type={direct ? 'direct' : ''}
                    />
                  </OptionForm>
                ) : (
                  <OptionForm>
                    <Label style={{ fontSize: '1.2rem', marginTop: 15 }}>
                      SEND BET
                      <span />
                    </Label>
                    <Field
                      value={
                        direct
                          ? { label: 'Direct', value: 'direct' }
                          : values.sendBet
                      }
                      name="sendBet"
                      placeholder={direct ? 'Direct' : 'Select Send Bet'}
                      options={
                        values.privacy.value === 'Private'
                          ? sendBetsMin
                          : sendBets
                      }
                      component={DropdownField}
                      width="100"
                      disabled={false}
                      type={direct ? 'direct' : ''}
                    />
                  </OptionForm>
                )}
                {type === 'modify' ? (
                  <OptionForm>
                    <Label style={{ fontSize: '1.2rem', marginTop: 15 }}>
                      PRIVACY
                      <span />
                    </Label>
                    <Field
                      value={values.privacy}
                      name="privacy"
                      placeholder="Public"
                      options={privacies}
                      component={DropdownField}
                      width="100"
                      disabled={true}
                      type={'privacy'}
                    />
                  </OptionForm>
                ) : (
                  <></>
                )}
                {type === 'modify' && bet.sendBet === 'Global' ? (
                  <>
                  <Button contain margin color={'secondary'} type="submit">
                    Pitch it !
                  </Button>
                  <MessageWrapper>
                    <Message error show={error}>
                      {error}
                    </Message>
                  </MessageWrapper>
                  </>
                ) : (
                  <>
                  <Button contain margin color={'secondary'} type="submit">
                    {type === 'modify' ? 'Counter' : 'Pitch it !'}
                  </Button>
                  <MessageWrapper>
                    <Message error show={error}>
                      {error}
                    </Message>
                  </MessageWrapper>
                  </>
                )}
              </StyledForm>
            </FormWrapper>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = ({ bets, firebase, firestore }) => ({
  userId: firebase.auth.uid,
  bets: firestore.ordered.bets,
  users: firestore.ordered.users,
  loading: bets.loading,
  error: bets.error,
});

export default connect(mapStateToProps)(InputBet);
