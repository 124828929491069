import React from 'react';
import Modal from '../../../components/UI/Modal/Modal';
import InputBet from './InputBet';

const PostABet = ({
  type,
  close,
  opened,
  bet,
  setPostedBet,
  setIsConfirmBet,
  direct,
  // openedConfirmBet,
  // closeConfirmBet,
  profileUser,
}) => {
  return (
    <>
      <Modal opened={opened} close={close} styles={{ top: '50%' }}>
        <InputBet
          direct={direct}
          type={type}
          close={close}
          bet={bet}
          setPostedBet={setPostedBet}
          setIsConfirmBet={setIsConfirmBet}
          profileUser={profileUser}
        />
      </Modal>
    </>
  );
};

export default PostABet;
