import 'semantic-ui-css/components/dropdown.css';
import 'semantic-ui-css/components/transition.css';
import 'semantic-ui-css/components/reset.css';
import 'semantic-ui-css/components/menu.css';
import 'semantic-ui-css/components/rating.css';
import 'semantic-ui-css/components/checkbox.css';
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import {Dropdown, Checkbox, Rating, Loader, Button} from 'semantic-ui-react';
import { updateBetToOffered } from '../../store/actions/todoActions';

import DefaultAvatar from '../../assets/default-avatar.png';
import PostABet from '../../containers/Explore/PostABet/PostABet';
import { RoundAvatar } from '../../components/BetSlipMini/BetSlipMini';
import ConfirmBetView from '../Explore/PostABet/ConfirmBet';
import FinalConfirmation from '../../components/BetSlipView/FinalConfirmation';
import Heading from '../../components/UI/Heading';
import Modal from '../../components/UI/Modal/Modal';
import ButtonTop from '../../components/UI/Forms/Button/ButtonTop';
import { ButtonTopWrapper } from '../Explore/PostABet/InputBet';
import { Content } from '../../hoc/layout/elements';
import theme from "../../utils/theme";
import {
    calculateOpponentDiversity,
    calculateOpponentFeedbackScore,
    calculatePercentageOfFreeBetsWon,
    calculatePercentageOfRealBetsWon, calculateWannaScore
} from "../Calculations/Calculations";
import {Avatar} from "@material-ui/core";

const options = [
  { key: 1, text: 'Use on of your Baits', value: 'Use one of your Baits' },
  { key: 2, text: 'Direct', value: 'Direct' },
];

const StyledDropdown = styled(Dropdown)`
  ${() => `
    font-size: 2rem;
    color: var(--color-secondaryColor);
    font-weight: 700;
    .menu {
      left: -6.99rem !important;
      }`}
`;

const StyledCheckbox = styled(Checkbox)`
  ${() => `
    label {
      font-weight: bold;
    }
    input {
      margin-right: 10px;
    }
    `}
`;

const BetsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 2px;

  h2 {
    color: #747576;
    text-align: start;
  }

`;

const HeaderWrapper = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
  -webkit-justify-content: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  padding: 10px;
  align-items: center;
  p {
    font-size: 2rem;
  }
`;

const Feedback = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #ace3f7;
  padding: 20px;
  align-items: center;

  b{
    font-size: 2rem;
    color: var(--color-secondaryColor);
  }
`;

const BetBreakdown = styled.div`
  padding: 10px;
  margin: 10px;
  margin-bottom: 5px;
  width: 100%;
  justify-content: center;
  text-align: left;
`;

const GlobalInTracker = styled.div`
  width: ${props => props.percentage}%;
  background-color: var(--color-secondaryColor);
  border-radius: 10px;
  height: 70%;
`;

const DirectInTracker = styled.div`
  width: ${props => props.percentage}%;
  background-color: white;
  border-radius: 10px;
  border: 0.5px solid black;
  height: 70%;
`;

const Tracker = styled.div`
  width: 100%;
  height: 9px;
  margin: 10px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 5px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px thin black;
`;

const ProgressInTracker = styled.div`
  width: ${props => props.percentage}%;
  background-color: var(--color-secondaryColor);
  border-radius: 10px;
  height: 70%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  flex-direction: column;
  width: 100%;
  align-items: center;
  p {
    font-style: bold;
  }
  align-items: center;
`;

const UserWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #80808033;
  b {
    font-size: 1.2rem;
    color: var(--color-secondaryColor);
  }

`;

const StyledRoundAvatar = styled(RoundAvatar)`
  margin-left: 20px;
  display: flex;
  justify-content: flex-start;
  width: 50%
`;

const WinrateWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;

  p {
    padding: 10px;
    font-size: 1rem;
    font-weight: bold;
  }
  b {
    font-size: 1.2rem;
    color: var(--color-secondaryColor);
  }
`;

const DiversityWrapper = styled.div`
  padding: 10px;
  width: 100%;
  justify-content: center;
  text-align: left;
`;

const LabbelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;

const Profile = props => {
  const history = useHistory();

  const [state, setState] = useState({ bets: null, users: null, user: null });
  const [showBaits, setShowBaits] = useState(false);
  const [isInputBet, setIsInputBet] = useState(false);
  const [isConfirmBet, setIsConfirmBet] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);
  const [postedBet, setPostedBet] = useState(false);
  const [betSelected, setBetSelected] = useState(false);
  const [selectedBets, setSelectedBets] = useState([]);
  const selectedBetsRef = useRef();
  const { users, bets, updateBetToOffered, loggedUser } = props;
  const userID = props.match.params.id;
  const user = state.users && state.users.find(user => user.id === userID);

  useEffect(() => {
    return () => {
      setState({ bets: null, users: null, user: null });
    };
  }, []);


  useEffect(()=>{
   // bet selected - do nothing
  },[selectedBets]);


  useEffect(() => {
    selectedBetsRef.current = selectedBets;
  }, [selectedBets]);

  useEffect(() => {
    if (bets && users) {
      setState({ bets, users });
    }
  }, [bets, users]);

  useEffect(() => {
    if (user) {
      setState({ ...state, user });
    }
  }, [user]);







  if (!state.bets || !state.users || !state.user) {
    return null;
  }

  // Get user score
  const opponentFeedBackScore =
    user &&
    (user.ratings && user.ratings.length > 0) ?
      user.ratings.reduce((prev, next) => prev + next) / (user.ratings.length)
    : 0 ;

  // Get different types of bets
  const numberOfDirectBets = bets && (bets.filter(bet => bet.sendBet === 'Direct' && (bet.creatorID === userID || bet.contestantID === userID))).length;
  const numberOfGlobalBets = bets && (bets.filter(bet => bet.sendBet === 'Global' && (bet.creatorID === userID || bet.contestantID === userID))).length;
  const numberOfBets = bets && (bets.filter(bet=> bet.creatorID === userID || bet.contestantID === userID)).length;
  const directBetsPercentage = Math.round(numberOfDirectBets / numberOfBets * 100);
  const globalBetsPercentage = Math.round(numberOfGlobalBets / numberOfBets * 100);

  // Real bets
  const realBets = bets.filter(bet => bet.creatorID === userID || bet.contestantID ===userID);
  const numberOfRealBets = realBets.length;

  // Free bets
  const freeBets = bets.filter(bet =>
      bet.freeBettorInFavor.find(elem => elem === userID) === userID ||
      bet.freeBettorAgainst.find(elem => elem === userID) === userID
  )

  const numberOfFreeBets = freeBets.length;

  // Get all free bets related data
  // const freeBets = bets && bets.filter(bet => getBets(bet, false));
  // const freeBetsWon =
  //   freeBets && freeBets.filter(bet => bet.winnerID === userID);
  // const freeWinPercentage =
  //   freeBets && Math.round((freeBetsWon.length / freeBets.length) * 100);

  // Get all paid bets realted data
  const paidBets = bets && bets.filter(bet => getBets(bet, true));
  const paidBetsWon =
    paidBets && paidBets.filter(bet => bet.winnerID === userID);
  const paidWinPercentage =
    paidBets && Math.round((paidBetsWon.length / paidBets.length) * 100);


  // Calculate the average date to pay - Subtracting the gotPaidTimespan with the establishedWinnerTimestamp to get the time between a bet being won/lost and paid
  const concludedBets = bets.filter(bet=>bet.stage === 'History');
  let amountOfTime = 0;
  concludedBets.forEach((bet)=>{
    amountOfTime += bet.gotPaidTimestamp - bet.establishedWinnerTimestamp;
  });
  let averageDaysToPay = (amountOfTime / (1000 * 24 * 60 * 60)) / concludedBets.length;

  if(isNaN(averageDaysToPay)){
    averageDaysToPay = "";
  }else if(averageDaysToPay < 1){
    averageDaysToPay = "less than 1";
  }else {
    averageDaysToPay = Math.round(averageDaysToPay);
  }

  // const days =
  //   paidBets &&
  //   paidBets
  //     .map(paidBet => new Date(paidBet.outcome) - moment(paidBet.eventStart, 'X').toDate())
  //     .map(dateMiliseconds => dateMiliseconds / (1000 * 100 * 24));
  // const daysToPay =
  //   days && days.length > 0 ? days.reduce((prev, after) => prev + after) : 0;
  // const averageDaysToPay = paidBets && Math.round(daysToPay / paidBets.length);

  // Number of bets disputed
  const betsDisputed = bets && bets.filter((bet)=> (bet.creatorID === loggedUser || bet.contestantID === loggedUser) && bet.wentToDispute === true).length;

  function getBets(bet, paid) {
    const idCheck = bet.contestantID === userID || bet.creatorID === userID;
    if (paid) {
      return idCheck && bet.paid;
    }
    return idCheck && !bet.paid;
  }

    const currentUserValidBaits = bets &&
        bets.filter(
                bet =>
                    bet.sendBet === 'Global' &&
                    bet.creatorID === loggedUser &&
                    !bet.contestantID &&
                    !bet.offeredToID &&
                    bet.stage === 'Pitched' &&
                    new Date(bet.outcome).getTime() / 1000 >= Date.now() / 1000,
            );

  const onChange = (e, data) => {
    if (data.value === 'Direct') {
      setIsInputBet(true);
    } else if (data.value === 'Use one of your Baits') {
      setShowBaits(true);
    }
  };

  return (
    <ProfileWrapper>
      {
        props.history.location?.state?.prevPath ?
            <Button onClick={()=> props.history.goBack()} style={{border: 'none', backgroundColor: theme.colors.main, color: theme.colors.secondaryColor, alignSelf: 'flex-start', width: 'auto', marginBottom: '-50px'}}>{'< BACK'}</Button>
            :
            <></>
      }
      <HeaderWrapper>
        <h1>{user.username}</h1>
      </HeaderWrapper>
      {loggedUser !== userID ? (
          <div style={{alignSelf: 'flex-end', marginTop: '-68px', marginBottom: '3vh'}}>
            <StyledDropdown
                text="..."
                options={options}
                onChange={onChange}
                icon={null}
                value={''}
            />
          </div>
      ) : (
          <div></div>
      )}
      <UserWrapper>
        <StyledRoundAvatar accepted={userID}>
          <img src={user.photoUrl || DefaultAvatar} alt="Avatar" style={{height: '50px', width: '50px', borderRadius: '50%'}}/>
        </StyledRoundAvatar>
        <div style={{display: 'flex', justifyContent: 'flex-end', width: '50%', marginRight: '20px'}}>
          <b>
            {user.firstName} {user.lastName}
          </b>
        </div>
      </UserWrapper>

      <WinrateWrapper>
        <p>
          REAL BETS MADE: <b>{numberOfRealBets}</b>
        </p>
        <p>
          REAL BETS WON:{' '}
          <b>{`${calculatePercentageOfRealBetsWon(bets, userID)}%`}</b>
        </p>
        <p>
          FREE BETS MADE: <b>{numberOfFreeBets}</b>
        </p>
        <p>
          FREE BETS WON:{' '}
          <b>{`${calculatePercentageOfFreeBetsWon(bets, userID)}%`}</b>
        </p>
      </WinrateWrapper>
      <DiversityWrapper>
        <b>DIVERSITY OF OPPONENTS:</b>
        <>
          <Tracker>
            <ProgressInTracker
              percentage={calculateOpponentDiversity(bets, userID)}
            />
          </Tracker>
          <LabbelWrapper>
            <p>Low</p>
            <p>High</p>
          </LabbelWrapper>
        </>
      </DiversityWrapper>
      <WinrateWrapper>
        <p>
          AVG DAYS TO PAY:{' '}
          <b>{averageDaysToPay}</b>
        </p>
        <p>
          BETS DISPUTED: <b>{betsDisputed}</b>
        </p>
        {/*<p>*/}
          {/*JUROR RATING: <b></b>*/}
        {/*</p>*/}
        {/*<p>*/}
          {/*JURY CONVICTIONS: <b></b>*/}
        {/*</p>*/}
      </WinrateWrapper>
      <BetBreakdown>
        <b>BET BREAKDOWN:</b>
        <>
          <Tracker>
            <GlobalInTracker percentage={globalBetsPercentage}/>
            <DirectInTracker percentage={directBetsPercentage}/>
          </Tracker>
          <LabbelWrapper>
            <p>Global</p>
            <p>Direct</p>
          </LabbelWrapper>
        </>
      </BetBreakdown>
      <Feedback>
        <p>OPPONENT FEEDBACK:</p>
        <Rating
          icon="star"
          defaultRating={calculateOpponentFeedbackScore(user)}
          maxRating={5}
          disabled
        />
      </Feedback>
      <Feedback>
        <p>WANNABET SCORE:</p>
        <b>
        {calculateWannaScore(bets, userID, calculateOpponentFeedbackScore(user), calculateOpponentDiversity(bets, userID))}/100
        </b>
      </Feedback>
      {isInputBet ? (
        <>
          <PostABet
            opened={isInputBet}
            close={() => setIsInputBet(false)}
            type={'add'}
            setPostedBet={setPostedBet}
            setIsConfirmBet={setIsConfirmBet}
            direct
            profileUser={user}
          />
        </>
      ) : null}
      {isConfirmBet ? (
        <ConfirmBetView
          opened={isConfirmBet}
          close={() => setIsConfirmBet(false)}
          type={'add'}
          newBet={postedBet}
          setIsFinalConfirmation={setIsFinalConfirmation}
          profileUser={user}
        />
      ) : null}
      {isFinalConfirmation ? (
        <FinalConfirmation
          opened={isFinalConfirmation}
          close={() => setIsFinalConfirmation(false)}
          type={'add'}
        />
      ) : null}
      {showBaits ? (
        <Modal
          displayType="block"
          opened={showBaits}
          close={() => setShowBaits(false)}
        >
          <ButtonTopWrapper>
            <ButtonTop
              type="button"
              color="main"
              contain
              onClick={() => {
                setShowBaits(false);
              }}
            >
              Cancel
            </ButtonTop>

            <Heading size="h2" bold noMargin noMarginTop color={'secondary'}>
              Your Baits
            </Heading>
            <ButtonTop
              onClick={async () => {

                selectedBetsRef.current.forEach(async currentBet =>{
                  const updatedBet = {
                    ...currentBet,
                    offeredToID: userID
                  };

                    await updateBetToOffered(updatedBet);
                });

                await setSelectedBets([]);
                await setShowBaits(false);
              }}
            >
              Assign
            </ButtonTop>
          </ButtonTopWrapper>
          <BetsWrapper>
              {currentUserValidBaits.length > 0 ?
                  <>
                  {
                      currentUserValidBaits
                      .map((bet, index) => {
                          return (
                              <StyledCheckbox
                                  control="input"
                                  key={bet.id}
                                  label={bet.title}
                                  value={bet}
                                  onChange={async (e, data) => {
                                        if (data.checked){

                                          await setSelectedBets(selectedBets => [...selectedBets, data.value]);
                                        }else {
                                          let indexOfBetToRemove = selectedBetsRef.current.findIndex(bet => bet.id === data.value.id);
                                          selectedBetsRef.current.splice(indexOfBetToRemove, 1);

                                          if(selectedBetsRef.current.length > 0){
                                            await setSelectedBets(selectedBetsRef.current);
                                          }else{
                                            await setSelectedBets([]);
                                          }
                                        }
                                      }
                                  }
                              />
                          );
                      })
                  }
                  </>
                  :
                  <h2>
                  You have no baits to offer to this user !
                  </h2>
              }
          </BetsWrapper>
        </Modal>
      ) : null}
    </ProfileWrapper>
  );
};

const mapStateToProps = ({ firebase, firestore }) => ({
  loggedUser: firebase.auth.uid,
  users: firestore.ordered.users,
  bets: firestore.ordered.bets,
  requesting: firestore.status.requesting,
});

const mapDispatchToProps = {
  updateBetToOffered,
};
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(['users', 'bets']),
)(withRouter(Profile));
