import * as actions from './actionTypes';
import { keywordRemover } from '../../utils/utilFile';
import _ from 'lodash';
import {
  getCoinsAmountFromSignup,
  getCoinsFrom10StraightWins,
  getCoinsFrom5StraightWins,
  getCoinsFromFirstBetSlipGlobal,
  getCoinsFromFirstBetSlipPrivate,
  getCoinsFromFirstBetWon,
} from '../../containers/Calculations/Gamification';

export const notificationsType = {
  accepted: 'accepted',
  rejected: 'rejected',
  offered: 'offered',
  claimWon: 'claimWon',
  claimLost: 'claimLost',
  bothSaidWonDispute: 'bothSaidWonDispute',
  bothSaidLostDispute: 'bothSaidLostDispute',
  won: 'won',
  lost: 'lost',
  wonOnCourt: 'wonOnCourt',
  lostOnCourt: 'lostOnCourt',
  paid: 'paid',
  reminderToPay: 'reminderToPay',
  gotPaid: 'gotPaid',
};
// Add a todo
export const addTodo = (data) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.ADD_TODO_START });
  try {
    const res = await firestore.collection('todos').doc(userId).get();
    const newTodo = {
      id: new Date().valueOf(),
      todo: data.todo,
    };
    if (!res.data()) {
      firestore
        .collection('todos')
        .doc(userId)
        .set({
          todos: [newTodo],
        });
    } else {
      firestore
        .collection('todos')
        .doc(userId)
        .update({
          todos: [...res.data().todos, newTodo],
        });
    }
    dispatch({ type: actions.ADD_TODO_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, payload: err.message });
  }
};

// Delete todo
export const deleteTodo = (id) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.DELETE_TODO_START });
  try {
    const res = await firestore.collection('todos').doc(userId).get();
    const previousTodos = res.data().todos;
    const newTodos = previousTodos.filter((todo) => todo.id !== id);
    await firestore.collection('todos').doc(userId).update({
      todos: newTodos,
    });
    dispatch({ type: actions.DELETE_TODO_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_TODO_FAIL, payload: err.message });
  }
};

// edit todo
export const editTodo = (id, data) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.ADD_TODO_START });
  try {
    const res = await firestore.collection('todos').doc(userId).get();
    const todos = res.data().todos;
    const index = todos.findIndex((todo) => todo.id === id);
    todos[index].todo = data.todo;

    await firestore.collection('todos').doc(userId).update({
      todos,
    });
    dispatch({ type: actions.ADD_TODO_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.ADD_TODO_FAIL, payload: err.message });
  }
};

export const addFreeBettorInFavor = (bet, id) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.POST_BET_START });

  /* const freeBettorInFavorNew = bet.freeBettorInFavor; */

  try {
    const docRef = await firestore

      .collection('bets')
      .doc(id)
      .update({
        freeBettorInFavor: [...bet.freeBettorInFavor, userId],
      });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const postBet = (data) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const creatorID = getState().firebase.auth.uid;
  const creatorUsername = getState().firebase.profile.username;
  const creatorTimezone = getState().firebase.profile.timezone
    ? getState().firebase.profile.timezone
    : '';
  const creatorPic = getState().firebase.profile.photoUrl
    ? getState().firebase.profile.photoUrl
    : getState().firebase.auth.photoUrl
    ? getState().firebase.auth.photoUrl
    : '';

  let stage;
  if (data.offeredToID || data.offferedToPhoneNumber || data.offeredToEmail) {
    stage = 'Offered';
  } else {
    stage = 'Pitched';
  }
  console.log('TITLE:' + data.title);
  const tags = data.title
    .trim()
    .split(' ')
    .filter((tag) => {
      return tag.includes('#');
    })
    .map((tag) => {
      return tag.replace('#', '');
    });

  const numberOfJuros = data.numberOfJurors || 7;
  const selectedJurorsArray = data.jurorsArray || [];

  dispatch({ type: actions.POST_BET_START });
  try {
    const betFinal = {
      ...data,
      createdAt: Date.now(),
      creatorID,
      creatorUsername,
      timezone: creatorTimezone,
      creatorPic,
      stage: stage,
      offeredToID: data.offeredToID,
      contestantID: null,
      contestantName: null,
      contestantPic: null,
      freeBettorInFavor: [],
      freeBettorAgainst: [],
      winnerID: null,
      loserID: null,
      paid: false,
      lastClaimerID: '',
      resolutionClaimArray: [],
      tags,
      numberOfJurors: numberOfJuros,
      selectedJurorsArray: selectedJurorsArray,
      jurorsInFavor: [],
      jurorsAgainst: [],
    };

    const betRef = await firestore.collection('bets').add(betFinal);

    const notificationRef = await firestore.collection('notifications').add({
      betId: betRef.id,
      betTitle: data.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: data.offeredToID,
      senderId: creatorID,
      senderUsername: creatorUsername,
      senderPic: creatorPic,
      type: notificationsType.offered,
    });

    // This logic aims to add the coins to the user profile - for gamification purposes
    const usersRef = firestore.collection('users').doc(creatorID);
    const usersCountRef = firestore.collection('usersCount').doc('usersCount');

    const userNumber = await usersCountRef
      .get()
      .then((doc) => {
        return doc.data().count;
      })
      .catch((err) => console.log(err));

    const coins = await usersRef.get().then((doc) => {
      if (
        (doc.data().numberOfGlobalBetsCreated === 0 ||
          doc.data().numberOfGlobalBetsCreated === undefined) &&
        data.privacy === 'Public'
      ) {
        return getCoinsFromFirstBetSlipGlobal(userNumber);
      } else if (
        (doc.data().numberOfPrivateBetsCreated === 0 ||
          doc.data().numberOfPrivateBetsCreated === undefined) &&
        data.privacy === 'Private'
      ) {

        return getCoinsFromFirstBetSlipPrivate(userNumber);
      } else {
        return 0;
      }
    });

    // This will increment the users count
    const increment = firestore.FieldValue.increment(1);
    const coinsIncrement = firestore.FieldValue.increment(coins);
    // Document reference
    if (data.privacy === 'Public') {
      await usersRef.update({
        numberOfGlobalBetsCreated: increment,
        coins: coinsIncrement,
      });
    } else if (data.privacy === 'Private') {
      await usersRef.update({
        numberOfPrivateBetsCreated: increment,
        coins: coinsIncrement,
      });
    }

    dispatch({ type: actions.POST_BET_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const modifyBet = (newFinal, id) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();

  dispatch({ type: actions.POST_BET_START });

  try {
    const docRef = await firestore
      .collection('bets')
      .doc(id)
      .update({
        ...newFinal,
      });

    dispatch({ type: actions.POST_BET_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const acceptBet = (bet) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const contestantID = getState().firebase.auth.uid;
  const contestantUsername = getState().firebase.profile.username;
  const contestantPic = getState().firebase.profile.photoUrl
    ? getState().firebase.profile.photoUrl
    : getState().firebase.auth.photoUrl
    ? getState().firebase.auth.photoUrl
    : '';

  dispatch({ type: actions.POST_BET_START });

  try {
    let betRef = await firestore.collection('bets').doc(bet.id);

    betRef = betRef.update({
      contestantID,
      contestantUsername,
      contestantPic,
      stage: 'Accepted',
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: bet.creatorID,
      senderId: contestantID,
      senderUsername: contestantUsername,
      senderPic: contestantPic,
      type: notificationsType.accepted,
    });

    dispatch({ type: actions.POST_BET_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

// Reject a bet
export const rejectBet = (bet) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const contestantID = getState().firebase.auth.uid;
  const contestantUsername = getState().firebase.profile.username;
  const contestantPic = getState().firebase.profile.photoUrl
    ? getState().firebase.profile.photoUrl
    : getState().firebase.auth.photoUrl
    ? getState().firebase.auth.photoUrl
    : '';

  const notificationRef = await firestore.collection('notifications').add({
    betId: bet.id,
    betTitle: bet.title,
    createdAt: new Date().getTime(),
    read: false,
    receiverId: bet.creatorID,
    senderId: contestantID,
    senderUsername: contestantUsername,
    senderPic: contestantPic,
    type: notificationsType.rejected,
  });

  dispatch({ type: actions.DELETE_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).delete();
    dispatch({ type: actions.DELETE_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_BET_FAIL, payload: err.message });
  }
};

//Delete bet
export const deleteBet = (data) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  dispatch({ type: actions.DELETE_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(data).delete();
    dispatch({ type: actions.DELETE_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_BET_FAIL, payload: err.message });
  }
};

export const claimWon = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const claimedWinnerID = getState().firebase.auth.uid;
  const claimedLoserID =
    claimedWinnerID === bet.contestantID ? bet.creatorID : bet.contestantID;
  const claimedWinnerUsername =
    claimedWinnerID === bet.creatorID
      ? bet.creatorUsername
      : bet.contestantUsername;
  const claimedWinnerPic =
    claimedWinnerID === bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const newResolutionClaimArray = _.cloneDeep(bet.resolutionClaimArray);
  newResolutionClaimArray.push('claimWon');

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      lastClaimerID: claimedWinnerID,
      resolutionClaimArray: newResolutionClaimArray,
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: claimedLoserID,
      senderId: claimedWinnerID,
      senderUsername: claimedWinnerUsername,
      senderPic: claimedWinnerPic,
      type: notificationsType.claimWon,
    });
    // if(notification){
    //     await markNotificationAsRead(notification);
    // }
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const claimLost = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const claimedLoserID = getState().firebase.auth.uid;
  const claimWinnerID =
    claimedLoserID === bet.creatorID ? bet.contestantID : bet.creatorID;
  const claimLoserUsername =
    claimedLoserID === bet.creatorID
      ? bet.creatorUsername
      : bet.contestantUsername;
  const claimLoserPic =
    claimedLoserID === bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const newResolutionClaimArray = _.cloneDeep(bet.resolutionClaimArray);
  newResolutionClaimArray.push('claimLost');

  dispatch({ type: actions.POST_BET_START });

  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      lastClaimerID: claimedLoserID,
      resolutionClaimArray: newResolutionClaimArray,
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: claimWinnerID,
      senderId: claimedLoserID,
      senderUsername: claimLoserUsername,
      senderPic: claimLoserPic,
      type: notificationsType.claimLost,
    });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const bothSaidWonDispute = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const senderID = getState().firebase.auth.uid;
  const receiverID =
    senderID === bet.contestantID ? bet.creatorID : bet.contestantID;
  const senderUsername =
    senderID === bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const senderPic =
    senderID === bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const newResolutionClaimArray = _.cloneDeep(bet.resolutionClaimArray);
  newResolutionClaimArray.push('claimWon');

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      stage: 'Pending Resolution',
      resolutionClaimArray: newResolutionClaimArray,
      wentToDispute: true,
      wentToDisputeTimestamp: Date.now(),
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: receiverID,
      senderId: senderID,
      senderUsername: senderUsername,
      senderPic: senderPic,
      type: notificationsType.bothSaidWonDispute,
    });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const bothSaidLostDispute = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const senderID = getState().firebase.auth.uid;
  const receiverID =
    senderID === bet.contestantID ? bet.creatorID : bet.contestantID;
  const senderUsername =
    senderID === bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const senderPic =
    senderID === bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const newResolutionClaimArray = _.cloneDeep(bet.resolutionClaimArray);
  newResolutionClaimArray.push('claimLost');

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      stage: 'Pending Resolution',
      resolutionClaimArray: newResolutionClaimArray,
      wentToDispute: true,
      wentToDisputeTimestamp: Date.now(),
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: receiverID,
      senderId: senderID,
      senderUsername: senderUsername,
      senderPic: senderPic,
      type: notificationsType.bothSaidLostDispute,
    });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const setWinner = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const winnerID = getState().firebase.auth.uid;
  const loserID =
    winnerID === bet.contestantID ? bet.creatorID : bet.contestantID;
  const winnerUsername =
    winnerID === bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const winnerPic =
    winnerID === bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const newResolutionClaimArray = _.cloneDeep(bet.resolutionClaimArray);
  newResolutionClaimArray.push('claimWon');

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      resolutionClaimArray: newResolutionClaimArray,
      winnerID,
      loserID,
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: loserID,
      senderId: winnerID,
      senderUsername: winnerUsername,
      senderPic: winnerPic,
      type: notificationsType.won,
    });

    const userWinnerRef = await firestore.collection('users').doc(winnerID);
    const userLoserRef = await firestore.collection('users').doc(loserID);
    const numberOfBetsWon = await userWinnerRef.get().then((doc) => {
      return doc.data().numberOfBetsWon;
    });
    const userNumber = await userWinnerRef.get().then((doc) => {
      return doc.data().userNumber;
    });

    // The Winner increments his winStreak and gets coins if 5 or 10
    await userWinnerRef.update({
      winStreak: firestore.FieldValue.increment(1),
    });
    const winStreak = await userWinnerRef.get().then((doc) => {
      return doc.data().winStreak || 0;
    });
    if (winStreak === 5) {
      await userWinnerRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFrom5StraightWins(userNumber),
        ),
      });
    } else if (winStreak === 0) {
      await userWinnerRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFrom10StraightWins(userNumber),
        ),
      });
    }
    // The loser resets his winStreak
    await userLoserRef.update({ winStreak: 0 });

    // If first won bet then user gets some coins
    if (numberOfBetsWon === 0 || numberOfBetsWon === undefined) {
      await userWinnerRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFromFirstBetWon(userNumber),
        ),
      });
    }
    userWinnerRef.update({
      numberOfBetsWon: firestore.FieldValue.increment(1),
    });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const setLoser = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const loserID = getState().firebase.auth.uid;
  const winnerID = loserID === bet.creatorID ? bet.contestantID : bet.creatorID;
  const loserUsername =
    loserID === bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const loserPic =
    loserID === bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const newResolutionClaimArray = _.cloneDeep(bet.resolutionClaimArray);
  newResolutionClaimArray.push('claimLost');

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      resolutionClaimArray: newResolutionClaimArray,
      winnerID,
      loserID,
      establishedWinnerTimestamp: new Date().getTime(),
    });

    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: winnerID,
      senderId: loserID,
      senderUsername: loserUsername,
      senderPic: loserPic,
      type: notificationsType.lost,
    });

    const userWinnerRef = await firestore.collection('users').doc(winnerID);
    const userLoserRef = await firestore.collection('users').doc(loserID);
    const numberOfBetsWon = await userWinnerRef.get().then((doc) => {
      return doc.data().numberOfBetsWon;
    });
    const userNumber = await userWinnerRef.get().then((doc) => {
      return doc.data().userNumber;
    });

    // The Winner increments his winStreak and gets coins if 5 or 10
    await userWinnerRef.update({
      winStreak: firestore.FieldValue.increment(1),
    });
    const winStreak = await userWinnerRef.get().then((doc) => {
      return doc.data().winStreak || 0;
    });

    if (winStreak === 5) {
      await userWinnerRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFrom5StraightWins(userNumber),
        ),
      });
    } else if (winStreak === 10) {
      await userWinnerRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFrom10StraightWins(userNumber),
        ),
      });
    }
    // The loser resets his winStreak
    await userLoserRef.update({ winStreak: 0 });

    // If first won bet then user gets some coins
    if (numberOfBetsWon === 0 || numberOfBetsWon === undefined) {
      await userWinnerRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFromFirstBetWon(userNumber),
        ),
      });
    }
    userWinnerRef.update({
      numberOfBetsWon: firestore.FieldValue.increment(1),
    });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const setHistory = (bet, notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const winnerID = getState().firebase.auth.uid;
  const loserID = winnerID === bet.creatorID ? bet.contestantID : bet.creatorID;
  const winnerUsername =
    bet.winnerID == bet.creatorID
      ? bet.creatorUsername
      : bet.contestantUsername;
  const winnerPic =
    bet.winnerID == bet.creatorID ? bet.creatorPic : bet.contestantPic;
  const loserUsername =
    loserID === bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const loserPic =
    loserID === bet.creatorID ? bet.creatorPic : bet.contestantPic;

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      stage: 'History',
      paid: true,
      gotPaidTimestamp: new Date().getTime(),
    });

    /*
        Whenever a bet is staged as history it is concluded
        And both users must receive a notification so that they can give a rating to each other
    */
    const winnerNotificationRef = await firestore
      .collection('notifications')
      .add({
        betId: bet.id,
        betTitle: bet.title,
        createdAt: new Date().getTime(),
        read: false,
        receiverId: bet.loserID,
        senderId: bet.winnerID,
        senderUsername: winnerUsername,
        senderPic: winnerPic,
        type: notificationsType.gotPaid,
      });

    const loserNotificationRef = await firestore
      .collection('notifications')
      .add({
        betId: bet.id,
        betTitle: bet.title,
        createdAt: new Date().getTime(),
        read: false,
        receiverId: bet.winnerID,
        senderId: bet.loserID,
        senderUsername: loserUsername,
        senderPic: loserPic,
        type: notificationsType.gotPaid,
      });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const addFreeBettorAgainst = (bet, id) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      .collection('bets')
      .doc(id)
      .update({
        freeBettorAgainst: [...bet.freeBettorAgainst, userId],
      });
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

export const setRating = (userId, rate) => async (
  // GV i will assume that this stage is reached whenever the winner of the bet clicks on 'Got Paid'
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  //dispatch({ type: actions.POST_BET_START });
  try {
    let arrayOfRatings = [];
    const userGetRef = await firestore
      .collection('users')
      .doc(userId)
      .get()
      .then((user) => {
        if (user.exists) {
          if (user.data().ratings) {
            arrayOfRatings = _.cloneDeep(user.data().ratings);
            arrayOfRatings.push(rate);
          } else {
            arrayOfRatings.push(rate);
          }
        }
      })
      .catch((err) => console.error(err));

    const userUpdateRef2 = await firestore
      .collection('users')
      .doc(userId)
      .update({
        ratings: arrayOfRatings,
      });

    //dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    console.error(err);
    //dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};

/*
    Comment on getting notifications marked as read after user action, instead of after user reading the notification

    If the user does an action on a bet immediatly after clicking on a notification then that notification can be marked as read
    because the notification object is passed around on the app

    If the user does an action on a bet without coming directly from clicking on a notification
    the app does not know what is the notification to be marked as read

 */

export const markNotificationAsRead = (notification) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();

  //dispatch({ type: actions.POST_BET_START });
  try {
    const notificationRef = await firestore
      .collection('notifications')
      .doc(notification.id)
      .update({
        read: true,
      });

    //dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    //dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};
//
//
// export const alsoMarkNotificationAsRead = async (notification, firestore) =>{
//     //dispatch({ type: actions.POST_BET_START });
//     try {
//         const notificationRef = await firestore
//             .collection("notifications")
//             .doc(notification.id)
//             .update({
//                 read: true
//             });
//
//         //dispatch({ type: actions.POST_BET_SUCCESS });
//     } catch (err) {
//         //dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
//     }
// }

export const updateBetToOffered = (bet) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  dispatch({ type: actions.UPDATE_BET });
  try {
    await firestore.collection('bets').doc(bet.id).update(bet);

    // in case the offeredID has changed from "" into a value
    const notificationRef = await firestore.collection('notifications').add({
      betId: bet.id,
      betTitle: bet.title,
      createdAt: new Date().getTime(),
      read: false,
      receiverId: bet.offeredToID,
      senderId: bet.creatorID,
      senderUsername: bet.creatorUsername,
      senderPic: bet.contestantPic,
      type: notificationsType.offered,
    });

    dispatch({ type: actions.UPDATE_BET_SUCCESS });
  } catch (err) {
    console.log(err);
    dispatch({ type: actions.UPDATE_BET_FAIL, payload: err.message });
  }
};

export const postPhoneNumber = (number) => async (
    dispatch,
    getState,
    {getFirestore},
) => {
  const firestore = getFirestore();
  dispatch({type: actions.POST_PHONE_NUMBER});
  try {
    await firestore.collection('phoneNumbers').add({phoneNumber: number});
    dispatch({type: actions.POST_PHONE_NUMBER_SUCCESS});
  } catch (err) {
    console.log(err);
    dispatch({type: actions.POST_PHONE_NUMBER_ERROR, payload: err.message});
  }
};

export const postEmail = (email) => async (
    dispatch,
    getState,
    {getFirestore},
) => {
  const firestore = getFirestore();
  dispatch({type: actions.POST_EMAIL});
  try {
    await firestore.collection('emails').add({email: email});
    dispatch({type: actions.POST_EMAIL_SUCCESS});
  } catch (err) {
    console.log(err);
    dispatch({type: actions.POST_EMAIL_ERROR, payload: err.message});
  }
};

export const postInvitationText = (number) => async (
    dispatch,
    getState,
    {getFirestore},
) => {

  const firestore = getFirestore();
  dispatch({type: actions.POST_INVITATION_TEXT});
  try {
    await firestore.collection('invitations').add({phoneNumber: number, email: false});
    dispatch({type: actions.POST_INVITATION_TEXT_SUCCESS});
  } catch (err) {
    console.log(err);
    dispatch({type: actions.POST_INVITATION_TEXT_ERROR, payload: err.message});
  }
};

export const postInvitationEmail = (email) => async (
    dispatch,
    getState,
    {getFirestore},
) => {

  const firestore = getFirestore();
  dispatch({type: actions.POST_INVITATION_EMAIL});
  try {
    await firestore.collection('invitations').add({phoneNumber: false, email: email});
    dispatch({type: actions.POST_INVITATION_EMAIL_SUCCESS});
  } catch (err) {
    console.log(err);
    dispatch({type: actions.POST_INVITATION_EMAIL_ERROR, payload: err.message});
  }
};


export const addJurorInFavor = (bet) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.UPDATE_BET });

  try {
    const docRef = await firestore
      .collection('bets')
      .doc(bet.id)
      .update({
        jurorsInFavor: [...bet.jurorsInFavor, userId],
      });

    dispatch({ type: actions.UPDATE_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_BET_FAIL, payload: err.message });
  }
};

export const addJurorAgainst = (bet) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.UPDATE_BET });

  try {
    const docRef = await firestore
      .collection('bets')
      .doc(bet.id)
      .update({
        jurorsInFavor: [...bet.jurorsAgainst, userId],
      });

    dispatch({ type: actions.UPDATE_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_BET_FAIL, payload: err.message });
  }
};

export const settleBetFromCourtDecision = (bet) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  let winnerID;
  let loserID;

  if (bet.jurorsInFavor > bet.jurorsAgainst) {
    winnerID = bet.creatorID;
    loserID = bet.contestantID;
  }
  if (bet.jurorsAgainst > bet.jurorsInFavor) {
    winnerID = bet.contestantID;
    loserID = bet.creatorID;
  }

  //const mockPic = 'Wannabet pic';

  dispatch({ type: actions.UPDATE_BET });
  try {
    const docRef = await firestore.collection('bets').doc(bet.id).update({
      winnerID,
      loserID,
    });

    const notificationToWinnerRef = await firestore
      .collection('notifications')
      .add({
        betId: bet.id,
        betTitle: bet.title,
        createdAt: new Date().getTime(),
        read: false,
        receiverId: winnerID,
        senderId: 'Court House',
        senderUsername: 'Court House',
        senderPic: '',
        type: notificationsType.wonOnCourt,
      });

    const notificationToLoserRef = await firestore
      .collection('notifications')
      .add({
        betId: bet.id,
        betTitle: bet.title,
        createdAt: new Date().getTime(),
        read: false,
        receiverId: loserID,
        senderId: 'Court House',
        senderUsername: 'Court House',
        senderPic: '',
        type: notificationsType.lostOnCourt,
      });

    dispatch({ type: actions.UPDATE_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.UPDATE_BET_FAIL, payload: err.message });
  }
};
