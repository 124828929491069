import * as actions from "./actionTypes";
import { keywordRemover } from "../../utils/utilFile";
import {notificationsType} from "./todoActions"

// Post a Bet
/* export const postBet = data => async (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  const creatorID = getState().firebase.auth.uid;
  const creatorUsername = getState().firebase.profile.username;
  const creatorPic = getState().firebase.profile.photoURL
    ? getState().firebase.profile.photoURL
    : getState().firebase.auth.photoURL
    ? getState().firebase.auth.photoURL
    : "";

  const tags = keywordRemover(data.title).split(" ");
  dispatch({ type: actions.POST_BET_START });
  try {
    const betFinal = {
      ...data,
      createdAt: Date.now(),
      creatorID,
      creatorUsername,
      creatorPic,
      stage: "pitched",
      offeredToID: data.offeredToID !== null ? data.offeredToID : null,
      contestantID: null,
      contestantName: null,
      contestantPic: null,
      freeBettorInFavor: [],
      freeBettorAgainst: [],
      winnerID: null,
      loserID: null,
      paid: false,
      tags
    };

    const docRef = await firestore.collection("bets").add(betFinal);
    dispatch({ type: actions.POST_BET_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */
/*
export const modifyBet = (newFinal, id) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  dispatch({ type: actions.POST_BET_START });

  try {
    const docRef = await firestore
      .collection("bets")
      .doc(id)
      .update({
        ...newFinal
      });

    dispatch({ type: actions.POST_BET_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */
/*
export const acceptBet = data => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  const contestantID = getState().firebase.auth.uid;
  const contestantUsername = getState().firebase.profile.username;
  const contestantPic = getState().firebase.profile.photoURL
    ? getState().firebase.profile.photoURL
    : getState().firebase.auth.photoURL
    ? getState().firebase.auth.photoURL
    : "";

  dispatch({ type: actions.POST_BET_START });

  try {
    const docRef = await firestore
      .collection("bets")
      .doc(data)
      .update({
        contestantID,
        contestantUsername,
        contestantPic,
        stage: "accepted",
        offeredToID: null
      });

    dispatch({ type: actions.POST_BET_SUCCESS });
    return true;
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */

//Delete bet
/* export const deleteBet = data => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  dispatch({ type: actions.DELETE_BET_START });
  try {
    const docRef = await firestore
      .collection("bets")
      .doc(data)
      .delete();
    dispatch({ type: actions.DELETE_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.DELETE_BET_FAIL, payload: err.message });
  }
}; */

/* export const setWinner = bet => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  const winnerID = getState().firebase.auth.uid;
  const loserID =
    winnerID === bet.contestantID ? bet.creatorID : bet.contestantID;

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      .collection("bets")
      .doc(bet.id)
      .update({
        winnerID,
        loserID
      });
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */
/* export const setLoser = bet => async (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  const loserID = getState().firebase.auth.uid;
  const winnerID =
    loserID === bet.contestantID ? bet.creatorID : bet.contestantID;
  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      .collection("bets")
      .doc(bet.id)
      .update({
        winnerID,
        loserID
      });
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */
/* export const setHistory = id => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      .collection("bets")
      .doc(id)
      .update({
        stage: "history",
        paid: true
      });
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */
export const setBadDebt = id => async (dispatch, { getFirestore }) => {
  /* const firestore = getFirestore();
  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      .collection("bets")
      .doc(id)
      .update({
        paid: false
      });
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  } */
  // send notifcation to loser saying he didn't pay
};

export const setReminderToPay = (bet, notification) => async (
    dispatch,
    getState,
    { getFirestore }
    ) => {
  const firestore = getFirestore();
  const winnerUsername = bet.winnerID == bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const winnerPic = bet.winnerID == bet.creatorID ? bet.creatorPic : bet.contestantPic;

  dispatch({ type: actions.POST_BET_START });
  try {
    const notificationRef = await firestore
        .collection('notifications')
        .add({
          betId: bet.id,
          betTitle: bet.title,
          createdAt: new Date().getTime(),
          read: false,
          receiverId: bet.loserID,
          senderId: bet.winnerID,                         // GV this is assuming that only the loser of the bet can click on the 'Paid' option
          senderUsername: winnerUsername,
          senderPic: winnerPic,
          type: notificationsType.reminderToPay
        });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}

export const setPaid = (bet, notification) => async (  dispatch,
                                       getState,
                                       { getFirestore }) => {
  const firestore = getFirestore();
  const loserUsername = bet.loserID == bet.creatorID ? bet.creatorUsername : bet.contestantUsername;
  const loserPic = bet.loserID == bet.creatorID ? bet.creatorPic : bet.contestantPic;

  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      // .collection("bets")
      // .doc(bet.id)
      // .update({
      //   paid: true
      // });

    const notificationRef = await firestore
        .collection('notifications')
        .add({
          betId: bet.id,
          betTitle: bet.title,
          createdAt: new Date().getTime(),
          read: false,
          receiverId: bet.winnerID,
          senderId: bet.loserID,                         // GV this is assuming that only the loser of the bet can click on the 'Paid' option
          senderUsername: loserUsername,
          senderPic: loserPic,
          type: notificationsType.paid
        });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
  //send notification to winner saying bet is paid
};

export const addFreeBettorInFavor = (bet, id) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.POST_BET_START });

  /* const freeBettorInFavorNew = bet.freeBettorInFavor; */

  try {
    const docRef = await firestore

      .collection("bets")
      .doc(id)
      .update({
        freeBettorInFavor: [...bet.freeBettorInFavor, userId]
      });

    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
};
/* export const addFreeBettorAgainst = (bet, id) => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.POST_BET_START });
  try {
    const docRef = await firestore

      .collection("bets")
      .doc(id)
      .update({
        freeBettorAgainst: [...bet.freeBettorAgainst, userId]
      });
    dispatch({ type: actions.POST_BET_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.POST_BET_FAIL, payload: err.message });
  }
}; */
