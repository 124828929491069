import {compose} from "redux";
import {firestoreConnect} from "react-redux-firebase";
import {connect} from "react-redux";
import React, {useEffect, useState} from 'react';
import {Wrapper, InnerWrapper, Container} from "../../hoc/layout/elements";
import {SearchInput} from "../../components/SearchInput/SearchInput";
import RankingsTable from "../../components/RankingsTable/RankingsTable";
import {
    calculateOpponentDiversity,
    calculateOpponentFeedbackScore,
    calculatePercentageOfFreeBetsWon,
    calculatePercentageOfRealBetsWon, calculateWannaScore
} from "../Calculations/Calculations";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    globalTextContainer: {
        margin: 'auto'
    },
    globalTextH1: {
        fontSize: '3rem',
        fontWeight: 300,
        color: '"#201c38"'
    },
    placeHolderForFooterSpace: {
        height: '100px'
    }
});

const Rankings = ({users, bets, location}) => {
    const classes = useStyles();

    const [searchField, setSearchField] = useState("");
    const [rankingsTableDataRows, setrankingsTableDataRows] = useState([]);

    const handleSearchChange = (searchValue) => {
        setSearchField(searchValue)
    };

    useEffect(() => {
            setrankingsTableDataRows(data(users, bets));
        },
        [users, bets]
    );

    const data = (users, bets) => {
        let rankingsTableDataRows = [];
        if (users !== undefined && users.length > 0 && bets !== undefined && bets.length > 0) {
            for (let user of users) {
                let feedbackScore = calculateOpponentFeedbackScore(user);

                let tmpRow = {
                    userID: user.id,
                    trader: user.username,
                    photoUrl: user.photoUrl,
                    real: calculatePercentageOfRealBetsWon(bets, user.id),
                    free: calculatePercentageOfFreeBetsWon(bets, user.id),
                    feedbackScore: feedbackScore,
                    wannabetScore: calculateWannaScore(bets, user.id, feedbackScore, calculateOpponentDiversity(bets, user.id))
                    //rank below i also add rank to this object
                };
                rankingsTableDataRows.push(tmpRow);
            }
        }
        // sorting the array depending on the wannabetScore attribute
        rankingsTableDataRows.sort(function (a, b) {
            return b.wannabetScore - a.wannabetScore
        });
        // Adding rank attribute to row objects
        for (let i = 1; i <= rankingsTableDataRows.length; i++) {
            if (rankingsTableDataRows[i - 1] !== undefined) {
                rankingsTableDataRows[i - 1].rank = i;
            }
        }
        return rankingsTableDataRows;
    };

    return (
        <Wrapper>
            <Container>
                <InnerWrapper>
                    <SearchInput placeholder={"Search users"} handleSearchChange={handleSearchChange}/>
                    <div className={classes.globalTextContainer}>
                        <h1 className={classes.globalTextH1}>Global</h1>
                    </div>
                    { rankingsTableDataRows ?
                        <RankingsTable rows={rankingsTableDataRows.filter(row => row.trader && row.trader.toLowerCase().includes(searchField.toLowerCase()))} location={location}/>
                        :
                        <> No users </>
                    }
                    <div className={classes.placeHolderForFooterSpace}/>
                </InnerWrapper>
            </Container>
        </Wrapper>
    )

};

const mapStateToProps = ({firestore}) => ({
    users: firestore.ordered.users,
    bets: firestore.ordered.bets,
    firestore: firestore
});

const mapDispatchToProps = {};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(['bets', 'users']),
)(Rankings);
