import React from "react";
import styled from "styled-components";

const Image = styled.img`
  height: ${({ dimensions }) => dimensions.height};
  width: ${({ dimensions }) => dimensions.width};
  margin-bottom: ${({ margin }) => (margin ? "3rem" : "0")};
  ::before {
    content: "${({ active }) => (active ? "\u2022" : "")}";
position
: absolute;
    font-size: 2rem;
   /*  margin-right: 0.6rem; */
    color: var(--color-secondaryColor);
height: 0rem;
 
    height: 0rem;
    top: 7%;
    left: -14%;
  
  }
`;

const Img = ({ src, alt, dimensions, onClick, margin, active, className }) => {
  return (
    <Image
      src={src}
      dimensions={dimensions}
      alt={alt}
      onClick={onClick}
      margin={margin}
      active={active}
      className={className}
    />
  );
};

export default Img;
