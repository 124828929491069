import * as actions from './actionTypes';
import { Router } from 'react-router-dom';
import {
  googleProvider,
  facebookProvider,
  twitterProvider,
  createUserProfileDocument,
} from '../../Firebase/Firebase';
import {
  getCoinsAmountFromSignup,
  getCoinsFrom5JuriesServed,
} from '../../containers/Calculations/Gamification';

// Sign up action creator
export const signUp = (data, sharedBetId) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  dispatch({ type: actions.AUTH_START });

  try {
    const isValidUsername = (username) =>
      username && /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/.test(username);

    if (isValidUsername(data.username)) {
      const usernameExists = await firestore
        .collection('usernames')
        .doc(data.username.toString().trim())
        .get();

      if (usernameExists.exists) {
        throw { message: 'This username already exists' };
      } else {
        const res = await firebase
          .auth()
          .createUserWithEmailAndPassword(data.email, data.password);
        // Send the verfication email
        const user = firebase.auth().currentUser;
        await user.sendEmailVerification();

        // This will increment the users count
        const increment = firebase.firestore.FieldValue.increment(1);
        // Document reference
        const usersCountRef = firestore
          .collection('usersCount')
          .doc('usersCount');
        // Update read count
        await usersCountRef.update({ count: increment });

        const userNumber = await usersCountRef.get().then((doc) => {
          return doc.data().count;
        });

        const userInitialCoins = getCoinsAmountFromSignup(userNumber);

        await firestore
          .collection('users')
          .doc(res.user.uid)
          .set({
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.username,
            country: data.country,
            email: data.email,
            userNumber: userNumber,
            coins: userInitialCoins,
            numberOfJurorVotes: 0,
            numberOfGlobalBetsCreated: 0,
            numberOfPrivateBetsCreated: 0,
            numberOfBetsWon: 0,
            photoUrl: '',
            betSlipsAvailable: 20,
            winStreak: 0,
            hasBasicSettingsProfileComplete: false,
            sharedBetId: sharedBetId ? sharedBetId : '',
          });

        await firestore.collection('usernames').doc(data.username).set({});

        if (data.betId) {
          // handling the phoneNumber invitation case - if it has a betId at this point it means that this signUp came from a invitation url
          await firestore.collection('bets').doc(data.betId).update({
            stage: 'Offered',
            offeredToID: res.user.uid,
          });
        }

        dispatch({ type: actions.AUTH_SUCCESS });
      }
    } else {
      throw { message: 'The username is invalid' };
    }
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }
  dispatch({ type: actions.AUTH_END });
};

export const googleSignUp = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  dispatch({ type: actions.AUTH_START });
  try {
    const res = await firebase.auth().signInWithRedirect(googleProvider);

    const firestore = getFirestore().auth().uid;

    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }
  dispatch({ type: actions.AUTH_END });
};
export const facebookSignUp = () => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  firebase.auth().signInWithRedirect(facebookProvider);
  dispatch({ type: actions.AUTH_START });
  try {
    const res = await firebase.auth().getRedirectResult();
    if (!firebase.auth().currentUser.emailVerified) {
      const user = firebase.auth().currentUser;
      await user.sendEmailVerification();
    }

    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }
  dispatch({ type: actions.AUTH_END });
};
export const twitterSignUp = () => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.AUTH_START });
  try {
    const res = await firebase.auth().signInWithRedirect(twitterProvider);

    if (!firebase.auth().currentUser.emailVerified) {
      const user = firebase.auth().currentUser;
      await user.sendEmailVerification();
    }

    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }
  dispatch({ type: actions.AUTH_END });
};

// Logout action creator
export const signOut = () => async (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  try {
    await firebase.auth().signOut();
  } catch (err) {
    console.log(err.message);
  }
};

// Login action creator
export const signIn = (data, sharedBetId) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.AUTH_START });
  try {
    const firestore = getFirestore();
    const userCredential = await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password);

    if (sharedBetId !== undefined && sharedBetId.length > 0) {
      firestore
        .collection('users')
        .doc(userCredential.user.uid)
        .update({ sharedBetId: sharedBetId })
        .then(() => console.log('Shared Bet Id added with success'))
        .catch((e) => console.log('Something went wrong adding Shared Bet Id'));
    }

    dispatch({ type: actions.AUTH_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.AUTH_FAIL, payload: err.message });
  }
  dispatch({ type: actions.AUTH_END });
};

// Clean up messages
export const clean = () => ({
  type: actions.CLEAN_UP,
});

// Verify email actionTypes
export const verifyEmail = () => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.VERIFY_START });
  try {
    const user = firebase.auth().currentUser;
    await user.sendEmailVerification();
    dispatch({ type: actions.VERIFY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.VERIFY_FAIL, payload: err.message });
  }
};

// Verify email actionTypes
export const handleVerifyEmail = (actionCode) => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.VERIFY_START });
  try {
    await firebase.auth().applyActionCode(actionCode);
    dispatch({ type: actions.VERIFY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.VERIFY_FAIL, payload: err.message });
  }
};

//Verify Ema

// Send recover password
export const recoverPassword = (data) => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.RECOVERY_START });
  try {
    // send email ehre
    await firebase.auth().sendPasswordResetEmail(data.email);
    dispatch({ type: actions.RECOVERY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.RECOVERY_FAIL, payload: err.message });
  }
};
// handle recover password
export const handleResetPassword = (actionCode) => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.RECOVERY_START });
  try {
    // send email ehre
    await firebase.auth().verifyPasswordResetCode(actionCode);
    dispatch({ type: actions.RECOVERY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.RECOVERY_FAIL, payload: err.message });
  }
};

export const processResetPassword = (actionCode, newPassword) => async (
  dispatch,
  getState,
  { getFirebase },
) => {
  const firebase = getFirebase();
  dispatch({ type: actions.RECOVERY_START });
  try {
    // send email ehre
    await firebase.auth().confirmPasswordReset(actionCode, newPassword);
    dispatch({ type: actions.RECOVERY_SUCCESS });
    dispatch({ type: actions.PROCESS_RECOVERY_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.RECOVERY_FAIL, payload: err.message });
  }
};

// Edit profile
export const editProfile = (data) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const { uid: userId, email: userEmail } = getState().firebase.auth;
  dispatch({ type: actions.PROFILE_EDIT_START });
  try {
    //edit the user profile
    if (data.email !== userEmail) {
      await user.updateEmail(data.email);
    }

    const isValidUsername = (username) =>
      username && /^(?!\.\.?$)(?!.*__.*__)([^/]{1,1500})$/.test(username);

    if (isValidUsername(data.username)) {
      const usernameExists = await firestore
        .collection('usernames')
        .doc(data.username.toString().trim())
        .get();

      if (usernameExists.exists) {
        throw { message: 'This username already exists' };
      } else {
        await firestore.collection('users').doc(userId).set({
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          country: data.country,
        });

        await firestore.collection('usernames').doc(data.username).set({});
        dispatch({ type: actions.AUTH_SUCCESS });

        if (data.password.length > 0) {
          await user.updatePassword(data.password);
        }
        dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
      }
    } else {
      throw { message: 'The username is invalid' };
    }
  } catch (err) {
    dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
  }
};

// Delete user
export const deleteUser = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const user = firebase.auth().currentUser;
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.DELETE_START });
  try {
    await firestore.collection('users').doc(userId).delete();

    await firestore.collection('todos').doc(userId).delete();

    await user.delete();
  } catch (err) {
    dispatch({ type: actions.DELETE_FAIL, payload: err.message });
  }
};

export const addUserCoins = (userId, coinsAmount) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  dispatch({ type: actions.UPDATE_USER });

  try {
    // This will increment the users coins amount
    const coinIncrement = firestore.FieldValue.increment(coinsAmount);

    // Document reference
    const usersCountRef = firestore.collection('users').doc(userId);

    await usersCountRef.update({ coins: coinIncrement });

    dispatch({ type: actions.UPDATE_USER });
  } catch (err) {
    dispatch({ type: actions.UPDATE_USER_FAIL, payload: err.message });
  }
};

export const incrementNumberOfJurorVotes = (userId) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const firestore = getFirestore();
  dispatch({ type: actions.UPDATE_USER });

  try {
    const numberOfJurorVotesIncrement = firestore.FieldValue.increment(1);
    // Document reference
    const usersCountRef = firestore.collection('users').doc(userId);
    const numberOfJurorVotes = await usersCountRef.get().then((doc) => {
      return doc.data().numberOfJurorVotes;
    });
    const userNumber = await usersCountRef.get().then((doc) => {
      return doc.data().userNumber;
    });

    await usersCountRef.update({
      numberOfJurorVotes: numberOfJurorVotesIncrement,
    });

    if (numberOfJurorVotes === 5) {
      await usersCountRef.update({
        coins: firestore.FieldValue.increment(
          getCoinsFrom5JuriesServed(userNumber),
        ),
      });
    }

    dispatch({ type: actions.UPDATE_USER });
  } catch (err) {
    dispatch({ type: actions.UPDATE_USER_FAIL, payload: err.message });
  }
};

// Update profile
export const updateProfile = (data, type) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.PROFILE_EDIT_START });

  let profile;
  if (type === 'basicSettings') {
    profile = {
      firstName: data.firstName,
      lastName: data.lastName,
      user: data.username,
      country: data.country,
    };
  } else if (type === 'advancedSettings') {
    profile = {
      phoneNumber: data.phoneNumber || '',
      birthDay: data.birthDay || '',
      transactionMethod: data.transactionMethod || '',
      cryptoWallet: data.cryptoWallet || '',
      currency: data.currency || '',
      address: data.address || '',
      sex: data.sex || '',
      timezone: data.timezone || '',
    };
  }

  try {
    //edit the user profile
    await firestore
      .collection('users')
      .doc(userId)
      .update(profile)
      .then(() => console.log('Updated user w/ success'))
      .catch((e) => console.log('Failed to update user: ', e));

    await firestore.collection('usernames').doc(data.username).set({});

    // delete old username
    await firestore.collection('usernames').doc(data.oldUsername).delete();

    dispatch({ type: actions.AUTH_SUCCESS });

    dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
  }
};

// Update profile
export const uploadProfilePicture = (file) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  try {
    // Get current username
    const user = firebase.auth().currentUser;
    // Create a Storage Ref w/ username
    const storageRef = firebase.storage().ref('/profilePicture/' + user.uid);
    // Upload file
    const task = storageRef
      .put(file)
      .then(async () => {
        const photoUrl = await firebase
          .storage()
          .ref('/profilePicture/' + user.uid)
          .getDownloadURL();

        await firestore.collection('users').doc(user.uid).update({
          photoUrl: photoUrl,
        });
      })
      .catch((e) => console.log('Error uploading pic: ', e));
  } catch (e) {
    console.log('Error upload profile pic ', e);
  }
};

// Update profile
export const addBetSlipWallet = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.PROFILE_EDIT_START });

  try {
    //edit the user profile - add betSlipsAvailable
    await firestore
      .collection('users')
      .doc(userId)
      .update({ betSlipsAvailable: 20 });

    dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
  }
};

// Report a bug
export const reportBug = (text) => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.BUG_REPORT_START });

  try {
    await firestore
      .collection('bugReports')
      .doc()
      .set({ reported: userId, report: text });

    dispatch({ type: actions.BUG_REPORT_SUCESS });
  } catch (err) {
    dispatch({ type: actions.BUG_REPORT_FAIL, payload: err.message });
  }
};

// Set Basic Settings profile complete
export const setBasicSettingsProfileComplete = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.PROFILE_EDIT_START });

  try {
    //edit the user profile - add betSlipsAvailable
    await firestore
      .collection('users')
      .doc(userId)
      .update({ hasBasicSettingsProfileComplete: true });

    dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
  }
};

// Set Basic Settings profile complete
export const nullifySharedBetIdOnProfile = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore },
) => {
  const firestore = getFirestore();
  const userId = getState().firebase.auth.uid;
  dispatch({ type: actions.PROFILE_EDIT_START });

  try {
    //edit the user profile - sharedBetId === ""
    await firestore.collection('users').doc(userId).update({ sharedBetId: '' });

    dispatch({ type: actions.PROFILE_EDIT_SUCCESS });
  } catch (err) {
    dispatch({ type: actions.PROFILE_EDIT_FAIL, payload: err.message });
  }
};

export const setAuthRedirect = authRedirect => async( dispatch ) => {
  dispatch({ type: actions.SET_AUTH_REDIRECT, payload: authRedirect })
}