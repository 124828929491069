import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {RoundAvatar} from "../BetSlipMini/BetSlipMini";
import DefaultAvatar from "../../assets/default-avatar.png";
import Loader from 'react-loader-spinner'
import {Link} from "react-router-dom";

const useStyles = makeStyles({
    table: {
        //minWidth: 650,
        maxWidth: '100%'
    },
    headerCell: {
        width: '10%',
        padding: '8px 2px 8px 2px',
        textAlign: 'center'
    },
    traderHeaderCell: {
        width: '30%',
        padding: '8px 2px 8px 2px',
        textAlign: 'center'
    },
    traderCell: {
        padding: '8px'
    },
    traderInsideCellContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    traderCellLabel: {
        margin: 'auto 1px'
    },
    spinner: {
        textAlign: 'center'
    },
    container: {
        width: '100%'
    }
});


const RankingsTable = (props) => {
    const classes = useStyles();

    // User pic should be added soon
    return (
        <div className={classes.container}>
            {
                (props.rows === undefined || props.rows.length === 0) ?
                    <div className={classes.spinner}>
                        <Loader type="TailSpin" color="#ffffff" height={50} width={50}/>
                    </div>
                    :
                    <TableContainer component={Paper} className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.headerCell}>RANK</TableCell>
                                    <TableCell className={classes.traderHeaderCell}>TRADER</TableCell>
                                    <TableCell className={classes.headerCell}>REAL</TableCell>
                                    <TableCell className={classes.headerCell}>FREE</TableCell>
                                    <TableCell className={classes.headerCell}>FEEDBACK SCORE</TableCell>
                                    <TableCell className={classes.headerCell}>WANNABET SCORE</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.rows.map((row) => (
                                    <TableRow key={row.rank}>
                                        <TableCell component="th" scope="row" align={'center'}>
                                            {row.rank}
                                        </TableCell>
                                        <TableCell className={classes.traderHeaderCell}>
                                            <div className={classes.traderInsideCellContainer}>
                                                <Link to={{pathname: `profile/${row.userID}/`, state: { prevPath: props.location.pathname }}} style={{display: 'flex'}}>
                                                    <RoundAvatar>
                                                        <img src={row.photoUrl || DefaultAvatar} alt="avatar" style={{height: '30px', width: '30px', borderRadius: '50%'}}/>
                                                    </RoundAvatar>
                                                    <label className={classes.traderCellLabel}>
                                                        {row.trader}
                                                    </label>
                                                </Link>
                                            </div>
                                        </TableCell>
                                        <TableCell align={'center'}>{row.real}%</TableCell>
                                        <TableCell align={'center'}>{row.free}%</TableCell>
                                        <TableCell align={'center'}><i className="far fa-star"></i>{row.feedbackScore}
                                        </TableCell>
                                        <TableCell align={'center'}>{row.wannabetScore}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </div>
    );
}

export default RankingsTable;
