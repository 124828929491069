import React, { useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import styled from "styled-components";

import Img from "../../../components/UI/Img";
import Darklogo from "../../../assets/darklogo.png";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import RecoverPasswordForm from "./RecoverPasswordForm";
import ProfileForm from "../Profile/ProfileForm";

export const LoginSignupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--color-mainDark) !important;
  width: 100%;
`;

export const LoginSignupHeader = styled.div`
  position: relative;
`;

const LogoLoginWrapper = styled(Img)`
  margin-top: ${({ page }) => (page === "login" ? "4rem" : "2rem")};
  margin-bottom: ${({ page }) => (page === "login" ? "2rem" : "0rem")};

  @media ${props => props.theme.mediaQueries.biggerSmall} {
    /*  margin-top: 17rem; */
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    height: 2rem;
    width: 12rem;
    margin-top: 1rem;
  } */
`;

export let sharedBetId = "";

const Login = ({ location, match, setAuthRedirect }) => {
  /*  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]); */
  const betId = match.params.betId;

  useEffect(() => {
    if (betId) {
      console.log('SET LOCATION', betId);
      setAuthRedirect('/myBets');
    }
  }, [betId, setAuthRedirect]);

  let page = "";

  if (location.pathname === "/login") {
    page = "login";
  } else {
    page = "signup";
  }

  const formToShow = () => {
    if (location.pathname === "/login") {
      return <LoginForm />;
    } else if (location.pathname === "/signup" || location.pathname.startsWith("/signup/")) {
      return <SignupForm betId={match.params.betId} sharedBetId={match.params.sharedBetId}/>;
    } else if (location.pathname === "/recover") {
      return <RecoverPasswordForm />;
    } else if (location.pathname === "/profileUpdate") {
      return <ProfileForm />;
    } else if(match.params.sharedBetId !== undefined){
      sharedBetId = match.params.sharedBetId;
      return <Redirect to={"/login"}/>;
    }
  };

  return (
    <LoginSignupWrapper>
      <LoginSignupHeader>
        <LogoLoginWrapper
          page={page}
          src={Darklogo}
          dimensions={{ height: "3.7rem", width: "20rem" }}
          alt="Logo"
          className="logologin"
        />
      </LoginSignupHeader>

      {formToShow()}
    </LoginSignupWrapper>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error
});

const mapDispatchToProps = {
  login: actions.signIn,
  cleanUp: actions.clean,
  googleSignUp: actions.googleSignUp,
  facebookSignUp: actions.facebookSignUp,
  twitterSignUp: actions.twitterSignUp,
  setAuthRedirect: actions.setAuthRedirect
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login));
