import React from "react";
import "./index.css";

class ListItem extends React.Component {
  render() {
    return (
      <a
        onClick={() => this.props.handleClick(this.props.value)}
        className={!this.props.active ? "" : "selected"}
        href="#"
      >
        {this.props.value}
      </a>
    )
  }
}

export default ListItem;
