import React, { useEffect } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import styled from "styled-components";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";
import Heading from "../../../components/UI/Heading";
import InputAuth from "../../../components/UI/Forms/Input/InputAuth";
import Message from "../../../components/UI/Message";
import Button from "../../../components/UI/Forms/Button/Button";
import { CustomRefreshLink } from "../VerifyEmail/VerifyEmail";
import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
`;

const RecoverFormWrapper = styled(FormWrapper)`
  background-color: var(--color-mainDark);
`;

const RecoverSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required.")
});

const RecoverPassword = ({ error, loading, sendEmail, cleanUp }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <Formik
      initialValues={{
        email: ""
      }}
      validationSchema={RecoverSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await sendEmail(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <RecoverFormWrapper>
          <Heading size="h4" bold color="white">
            Type in your e-mail to recover your password
          </Heading>
          <StyledForm>
            <Field
              type="email"
              name="email"
              placeholder="Type your email..."
              component={InputAuth}
            />
            <Button
              margin
              disabled={!isValid || isSubmitting}
              loading={loading ? "Sending recover email..." : null}
              type="submit"
              contain
              auth
              color="secondary"
            >
              Recover email
            </Button>
            <MessageWrapper>
              <Message error show={error}>
                {error}
              </Message>
            </MessageWrapper>
            <MessageWrapper>
              <Message success show={error === false}>
                Recover email sent successfully!
              </Message>
            </MessageWrapper>

            <CustomRefreshLink href="/" color="white" register>
              Start Betting
            </CustomRefreshLink>
          </StyledForm>
        </RecoverFormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.recoverPassword.loading,
  error: auth.recoverPassword.error
});

const mapDispatchToProps = {
  sendEmail: actions.recoverPassword,
  cleanUp: actions.clean
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoverPassword);
