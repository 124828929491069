import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  width: auto;

  font-size: 2rem;
  -webkit-appearance: none;
  border-radius: 0;
  border: 0;
  background: none;

  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 1.5rem;
  }
 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1rem;
  } */

  color: var(--color-mainDark);
  font-weight: 700;
  margin-top: ${({ margin }) => (margin ? "1rem" : "0")};
  margin-bottom: ${({ margin }) => (margin ? "2rem" : "0")};

  transition: all 0.2s;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--color-greyColor);
  }
`;

const ButtonTop = ({
  children,
  disabled,
  loading,
  contain,
  color,
  margin,
  ...rest
}) => {
  return (
    <StyledButton
      color={color}
      contain={contain}
      disabled={disabled}
      margin={margin}
      {...rest}
    >
      {loading ? loading : children}
    </StyledButton>
  );
};

export default ButtonTop;
