import React from "react";

import Modal from "../UI/Modal/Modal";
import RadioForm from "./RadioForm";

const TypeModal = ({ opened, close, setSendBet, type }) => {
  return (
    <Modal opened={opened} close={close} type={type}>
      <RadioForm close={close} setSendBet={setSendBet} type={type} />
    </Modal>
  );
};

export default TypeModal;
