import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import Modal from '../UI/Modal/Modal';
import Button from '../UI/Forms/Button/Button';
import Heading from '../UI/Heading';

const FinalConfirmation = ({ opened, close, type }) => {

  const textFinal = () => {
    if (type === 'accept') {
      return 'Bet has been accepted !';
    } else if (type === 'reject') {
      return 'Bet has been rejected!';
    } else if (type === 'delete') {
      return 'Your bet has been deleted!';
    } else if (type === 'add') {
      return 'Your bet has been posted!';
    } else if (type === 'modify') {
      return 'Your bet was modified !';
    } else if (
      type === 'won' ||
      type === 'lost' ||
      type === 'claimWon' ||
      type === 'claimLost' ||
      type === 'badDebt' ||
      type === 'sendReminder' ||
      type === 'paid' ||
      type === 'bothSaidWonDispute' ||
      type === 'bothSaidLostDispute'
    ) {
      return 'Notification Sent !';
    } else if (type === 'history') {
      return 'This Bet is History !';
    } else if (type === 'jurorVote') {
      return 'Thank you for your Juror Vote !';
    } else if (type === 'updatedProfile') {
      return 'Your profile was updated !';
    } else if (type === 'updatedProfilePic') {
      return 'Your profile picture is being uploaded';
    } else if (type === 'bugReport') {
      return 'Your bug report was sent ! Thank you';
    } else if (type === 'invitation'){
      return 'Invitation was sent !';
    }
  };

  return (
    <Modal opened={opened} close={close}>
      <Heading size="h3" bold color="secondary">
        {textFinal()}
      </Heading>

      <Button color="transparent" contain onClick={close}>
        Close
      </Button>
    </Modal>
  );
};

export default FinalConfirmation;
