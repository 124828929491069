import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Navbar from '../../components/Navigation/Navbar/Navbar';
import Footer from '../../components/Navigation/Footer/Footer';
import SideLeft from '../../components/Navigation/SideLeft/SideLeft';
import SideRight from '../../components/Navigation/SideRight/SideRight';
import { StickyContainer, Sticky } from 'react-sticky';

const MainWrapper = styled.main`
  min-height: 100vh;
  height: 100%;
  /*  overflow: hidden; */
`;

const ContentWrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  display: flex;
  overflow: hidden;

  /**
 * iPad with portrait orientation.
 */
  @media ${props => props.theme.mediaQueries.ipadPortrait} {
    min-height: 1024px;
  }

  /**
 * iPad with landscape orientation.
 */
  @media ${props => props.theme.mediaQueries.ipadLandscape} {
    min-height: 768px;
  }

  /**
 * iPhone 5
 * You can also target devices with aspect ratio.
 */
  @media ${props => props.theme.mediaQueries.iphoneX} {
    min-height: 500px;
  }
  @media ${props => props.theme.mediaQueries.iphoneXr} {
    min-height: 500px;
  }
  @media ${props => props.theme.mediaQueries.iphone6Plus} {
    min-height: 500px;
  }
  @media ${props => props.theme.mediaQueries.iphone6} {
    min-height: 500px;
  }
  @media ${props => props.theme.mediaQueries.iphone5} {
    min-height: 500px;
  }
  @media ${props => props.theme.mediaQueries.ipad} {
    min-height: 768px;
  }
  @media ${props => props.theme.mediaQueries.ipadPro} {
    min-height: 1024px;
  }

  @media ${props => props.theme.mediaQueries.smallerSmall} {
    margin-top: ${({ location }) =>
      location.pathname === '/login' ||
      location.pathname.indexOf('/signup') >= 0 ||
      location.pathname === '/recover' ||
      location.pathname === '/verify-email' ||
      location.pathname === '/profileUpdate' ||
      location.pathname === '/action'
        ? '0rem'
        : '6rem'};
  }
`;

const ChildrenWrapper = styled.div`
  /* width: 100%; */

  flex-basis: ${({ location }) =>
    location.pathname === '/login' ||
    location.pathname.indexOf('/signup') >= 0 ||
    location.pathname === '/recover' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/profileUpdate' ||
    location.pathname === '/action'
      ? '100%'
      : '100%'};
  @media ${props => props.theme.mediaQueries.smallerSmall} {
    flex-basis: 100%;
  }
  padding-right: ${({ location }) =>
    location.pathname === '/login' ||
    location.pathname.indexOf('/signup') >= 0 ||
    location.pathname === '/recover' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/profileUpdate' ||
    location.pathname === '/action'
      ? '2rem'
      : '2rem'};
  padding-left: ${({ location }) =>
    location.pathname === '/login' ||
    location.pathname.indexOf('/signup') >= 0 ||
    location.pathname === '/recover' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/profileUpdate' ||
    location.pathname === '/action'
      ? '2rem'
      : '2rem'};
  /* min-height: calc(100vh - 6rem); */
  /*   margin-top: 6rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #f3f4f6;
  height: ${({ location }) =>
    location.pathname === '/login' ||
    location.pathname.indexOf('/signup') >= 0 ||
    location.pathname === '/recover' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/profileUpdate' ||
    location.pathname === '/action'
      ? 'auto'
      : 'auto'};
  /*  min-height: 100vh; */
  width: 100%;
  background-color: ${({ location }) =>
    location.pathname === '/login' ||
    location.pathname.indexOf('/signup') >= 0 ||
    location.pathname === '/recover' ||
    location.pathname === '/verify-email' ||
    location.pathname === '/profileUpdate' ||
    location.pathname === '/action'
      ? 'var(--color-mainDark)'
      : '(--color-main)'};
`;

const Layout = ({
  auth,
  children,
  loggedIn,
  location,
  emailVerified,
  usernameExists,
  profileIsEmpty,
  profileIsLoaded,
}) => {
  // determine if user needs a validated email
  const trustedProviders = [ 'facebook.com', 'google.com', 'twitter.com'];
  let currentUserHasTrustedProvider = false;
  if (auth.providerData && auth.providerData.length && auth.providerData[0].providerId) {
    if (trustedProviders.indexOf(auth.providerData[0].providerId) >= 0) {
      currentUserHasTrustedProvider = true;
    }
  }

  return (
    <StickyContainer>
      <MainWrapper>
        <Navbar
          loggedIn={loggedIn}
          emailVerified={emailVerified || currentUserHasTrustedProvider}
          usernameExists={usernameExists}
        />
        <ContentWrapper location={location}>
          <SideLeft
            loggedIn={loggedIn}
            emailVerified={emailVerified || currentUserHasTrustedProvider}
            usernameExists={usernameExists}
            profileIsEmpty={profileIsEmpty}
            profileIsLoaded={profileIsLoaded}
          />
          <ChildrenWrapper loggedIn={loggedIn} location={location}>
            {children}
          </ChildrenWrapper>
          <SideRight
            loggedIn={loggedIn}
            emailVerified={emailVerified || currentUserHasTrustedProvider}
            usernameExists={usernameExists}
            profileIsEmpty={profileIsEmpty}
            profileIsLoaded={profileIsLoaded}
          />
        </ContentWrapper>

        <Sticky bottomOffset={0}>
          {props => (
            <div>
              <Footer
                loggedIn={loggedIn}
                emailVerified={emailVerified || currentUserHasTrustedProvider}
                usernameExists={usernameExists}
              />
            </div>
          )}
        </Sticky>
      </MainWrapper>
    </StickyContainer>
  );
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth,
  loggedIn: firebase.auth.uid,
  emailVerified: firebase.auth.emailVerified,
  usernameExists: firebase.profile.username,
  profileIsLoaded: firebase.profile.isLoaded,
  profileIsEmpty: firebase.profile.isEmpty,
});

export default connect(mapStateToProps)(withRouter(Layout));
