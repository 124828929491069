import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

const prodConfig = {
  apiKey: 'AIzaSyDmybiMU_g7b_UGqZuGzY0Zrmw7vqnvgoA',
  authDomain: 'wannabetdev-b3dd4.firebaseapp.com',
  databaseURL: 'https://wannabetdev-b3dd4.firebaseio.com',
  projectId: 'wannabetdev-b3dd4',
  storageBucket: 'wannabetdev-b3dd4.appspot.com',
  messagingSenderId: '316442376629',
  appId: '1:316442376629:web:b89edaca1e0a7504',
};

const devConfig = {
  apiKey: 'AIzaSyAHyGLHYuxE7cx_jU76wiveRTcPoiQ_RIw',
  authDomain: 'wannabetdevelopment.firebaseapp.com',
  databaseURL: 'https://wannabetdevelopment.firebaseio.com',
  projectId: 'wannabetdevelopment',
  storageBucket: 'wannabetdevelopment.appspot.com',
  messagingSenderId: '1095825023871',
  appId: '1:1095825023871:web:4d412c4b365935abbd304a',
  measurementId: 'G-2RDMR7MTZ8',
};

const WANNABET_PRODUCTION_URL = 'https://wannabetdev-b3dd4.firebaseapp.com/';
const WANNABET_DEVELOPMENT_URL = 'https://wannabetdevelopment.firebaseapp.com/';

export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const facebookProvider = new firebase.auth.FacebookAuthProvider();
export const twitterProvider = new firebase.auth.TwitterAuthProvider();

const config =
  process.env.REACT_APP_PRODUCTION === 'false' ? devConfig : prodConfig;
export const WANNABET_URL =
  process.env.REACT_APP_PRODUCTION === 'true'
    ? WANNABET_PRODUCTION_URL
    : WANNABET_DEVELOPMENT_URL;

console.log('URL: ', WANNABET_URL);

firebase.initializeApp(config);
firebase.firestore();

export const firestore = firebase.firestore();

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if (!userAuth) return;

  const userRef = firebase.firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { email, photoURL } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        email: email ? email : null,
        createdAt,
        photoURL: photoURL ? photoURL : null,
        ...additionalData,
      });
    } catch (error) {
      console.log('error creating user', error.message);
    }
  }

  return userRef;
};

export default firebase;
