import React, {useEffect, useState} from 'react';
import theme from '../../utils/theme'
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {makeStyles} from '@material-ui/core/styles';
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";
import * as actions from "../../store/actions";
import FinalConfirmation from "../../components/BetSlipView/FinalConfirmation";
import {
    countries,
    countriesForDropdown,
    CRYPTO_WALLETS,
    CURRENCIES,
    SEXES,
    TRANSACTION_METHODS
} from "../../utils/utilFile";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%'
    },
    backButton: {
        marginTop: '20px',
        backgroundColor: theme.colors.main,
        color: theme.colors.secondaryColor,
        fontSize: '12px',
        border: 'none'
    },
    globalTextH1: {
        color: theme.colors.secondaryColor,
        textAlign: 'center',
        fontSize: '18px',
        marginTop: '-25px'
    },
    saveButtonContainer: {
        float: 'right'
    },
    saveButton: {
        marginTop: '-40px',
        backgroundColor: theme.colors.main,
        color: theme.colors.secondaryColor,
        fontSize: '12px',
        border: 'none',
        width: 'auto',
        marginRight: '0px',
        paddingRight: '0px'
    },
    pinkBarContainer: {
        backgroundColor: theme.colors.secondaryColor,
        width: '100%',
        height: '40px',
        alignItems: 'center',
        display: 'flex'
    },
    pinkBarText: {
        color: theme.colors.main,
        fontSize: '16px',
        marginLeft: '10px'
    },
    lineContainer: {
        width: '90%',
        margin: 'auto'
    },
    lineWithTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px'
    },
    lineText: {
        marginLeft: '5px'
    },
    lineIconContainer: {
        height: '100%'
    },
    input: {
        border: '1px',
        borderStyle: 'groove',
        borderColor: theme.colors.fourthColor,
        marginRight: '5px',
        borderRadius: '3px',
        height: '30px',
        width: '135px',
        display: 'flex',
        alignItems: 'center'
    },
    dropdownContainer: {
        width: '135px',
        marginRight: '5px',
        border: '1px',
        borderStyle: 'groove',
        borderRadius: '3px',
        borderColor: theme.colors.fourthColor,
    },
    dropdown: {
        border: 'none !important',
        backgroundColor: '#f3f4f6 !important'
    }
});

const TokenWallet = ({profile, addBetSlipWallet}) => {
    const classes = useStyles();

    useEffect(()=>{
        if(profile.betSlipsAvailable === undefined){
            addBetSlipWallet();
        }
    }, [profile]);

    return (
        <div className={classes.container}>
            <div className={classes.backButton}>
                <Link to={"/settings"}> {'< Back'} </Link>
            </div>
            <h1 className={classes.globalTextH1}>Token Wallet</h1>


            <div className={classes.pinkBarContainer}>
                <h1 className={classes.pinkBarText}> @{profile.username} </h1>
            </div>


            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Wannabux Amount</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input type="text" disabled placeholder={profile.coins} style={{marginLeft: '10px', borderStyle: 'none', backgroundColor: theme.colors.main} }/>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
};

const mapStateToProps = ({firebase, firestore}) => ({
    profile: firebase.profile
});

const mapDispatchToProps = {
    addBetSlipWallet: actions.addBetSlipWallet
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(TokenWallet);
