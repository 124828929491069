import * as actions from '../actions/actionTypes';
import { updateBetToOffered } from '../actions';

const initialState = {
  error: null,
  loading: false,
  deleteBet: {
    error: null,
    loading: false,
  },
  updateBetToOffered: {
    error: null,
    loading: false,
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.POST_BET_START:
      return { ...state, loading: true };

    case actions.POST_BET_SUCCESS:
      return { ...state, loading: false, error: false };

    case actions.POST_BET_FAIL:
      return { ...state, loading: false, error: payload };

    case actions.DELETE_BET_START:
      return { ...state, deleteBet: { ...state.deleteBet, loading: true } };

    case actions.DELETE_BET_SUCCESS:
      return {
        ...state,
        deleteBet: { ...state.deleteBet, loading: false, error: false },
      };

    case actions.DELETE_BET_FAIL:
      return {
        ...state,
        deleteBet: { ...state.deleteBet, loading: false, error: payload },
      };

    case actions.UPDATE_BET:
      return {
        ...state,
        updateBetToOffered: { ...state.updateBetToOffered, loading: true },
      };

    case actions.UPDATE_BET_SUCCESS:
      return {
        ...state,
      };

    case actions.UPDATE_BET_FAIL:
      return {
        ...state,
        updateBetToOffered: { ...state.updateBetToOffered, loading: false, error: payload },
      };

    case actions.POST_PHONE_NUMBER:
      return {
        ...state,
      };

    case actions.POST_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
      };

    case actions.POST_PHONE_NUMBER_ERROR:
      return {
        ...state,
      };

    case actions.POST_EMAIL:
      return {
        ...state,
      };

    case actions.POST_EMAIL_SUCCESS:
      return {
        ...state,
      };

    case actions.POST_EMAIL_ERROR:
      return {
        ...state,
      };

    default:
      return state;
  }
};
