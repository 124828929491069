import React, { useEffect } from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { FormWrapper } from "../../../hoc/layout/elements";
import Heading from "../../../components/UI/Heading";
import Button from "../../../components/UI/Forms/Button/Button";
import Message from "../../../components/UI/Message";
import CustomLink from "../../../components/UI/CustomLink";

import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -2rem;
`;

export const CustomRefreshLink = styled.a`
  text-decoration: none;
  color: var(--color-white);
  font-weight: 700;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  transition: all 0.2s;
  border-bottom: 1px solid var(--color-white);

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }

  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
`;

const VerifyEmailWrapper = styled(FormWrapper)`
  background-color: var(--color-mainDark);
`;

const VerifyEmail = ({ sendVerification, error, loading, cleanUp, authRedirect }) => {
  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <VerifyEmailWrapper>
      <Wrapper>
        <Heading noMargin color="white" bold size="h3">
          Verify your email
        </Heading>
        <Heading color="white" bold size="h4">
          Go to your email inbox, and please verify your email.
        </Heading>
        <Button
          margin
          loading={loading ? "Sending email..." : null}
          disabled={loading}
          contain
          color={"secondary"}
          onClick={() => sendVerification()}
        >
          Re-send verification email
        </Button>
        {/* <Button
            contain
            color={"secondary"}
            onClick={() => history.push("/login")}
          >
            Login
          </Button> */}
        }
        <MessageWrapper>
          <Message error show={error}>
            {error}
          </Message>
        </MessageWrapper>
        <MessageWrapper>
          <Message success show={error === false}>
            Message sent successfully!
          </Message>
        </MessageWrapper>
        <CustomRefreshLink href={authRedirect || ''} color="white" register>
          Start Betting !
        </CustomRefreshLink>
      </Wrapper>
    </VerifyEmailWrapper>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.verifyEmail.loading,
  error: auth.verifyEmail.error,
  authRedirect: auth.authRedirect
});

const mapDispatchToProps = {
  sendVerification: actions.verifyEmail,
  cleanUp: actions.clean
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyEmail);
