import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import windowSize from 'react-window-size';

import Layout from './hoc/layout/Layout';
import LoginSignup from './containers/Auth/Login/LoginSignup';
import VerifyEmail from './containers/Auth/VerifyEmail/VerifyEmail';
import Logout from './containers/Auth/Logout/Logout';
import MyBets from './containers/MyBets/MyBets';
import Explore from './containers/Explore/Explore';
import Action from './containers/Auth/Action/Action';
import Loader from './components/UI/Loader';
import Notifications from './containers/Notifications/Notifications';
import { WrapperLoader } from './index';
import ProfileView from './containers/Profile/Profile';
import Rankings from './containers/Rankings/Rankings';
import Settings from './containers/Settings/Settings';
import BasicSettings from './containers/Settings/BasicSettings';
import AdvancedSettings from './containers/Settings/AdvancedSettings';
import BetSlipWallet from './containers/Settings/BetSlipWallet';
import ReportBug from './containers/Settings/ReportBug';
import TokenWallet from './containers/Settings/TokenWallet';
import Invitation from "./components/Invitation/Invitation";

const App = props => {

  console.log(`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`);

  const { 
    currentUserHasTrustedProvider, 
    loggedIn, 
    emailVerified, 
    username,
    profileIsLoaded, 
    profileIsEmpty 
  } = props;

  const isLoading = (loggedIn && profileIsEmpty);
  const userVerified = (emailVerified || currentUserHasTrustedProvider);
  const userHasProfile = (profileIsLoaded && !profileIsEmpty && username);

  // block view until done loading
  if (isLoading) {
    return (
      <WrapperLoader>
          <Loader isWhite />
      </WrapperLoader>
    );
  };

  // logged in but unverified - must verify email
  if (loggedIn && !userVerified) {
    return (
      <Layout>
        <Switch>
          <Route exact path="/verify-email" component={VerifyEmail} />
          <Route exact path="/action" component={Action} />
          <Redirect to="/verify-email" />
        </Switch>
      </Layout>
    );
  }

  // logged in but no username - must continue account setup
  if (loggedIn && profileIsLoaded && !username) {
    return (
      <Layout>
        <Switch>
          <Route exact path="/profileUpdate" component={LoginSignup} />
          <Route exact path="/signup/:betId" component={LoginSignup} />
          <Redirect to="/profileUpdate" />
        </Switch>
      </Layout>
    );
  }
  
  // logged in and good to go!
  if (loggedIn && userVerified && userHasProfile) {
    return (
      <Layout>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/profile/:id" component={ProfileView} />
            <Route exact path="/action" component={Action} />
            <Route exact path="/" component={Explore} />
            <Route exact path="/explore/:sharedBetId" component={Explore} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/myBets" component={MyBets} />
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/rankings" component={Rankings} />
            <Route exact path="/settings" component={Settings} />
            <Route exact path="/basicSettings" component={BasicSettings} />
            <Route exact path="/advancedSettings" component={AdvancedSettings} />
            <Route exact path="/betSlipWallet" component={BetSlipWallet} />
            <Route exact path="/reportBug" component={ReportBug} />
            <Route exact path="/tokenWallet" component={TokenWallet} />
            <Route exact path="/signup/:betId">
              <Redirect to="/myBets" />
            </Route>
            <Route exact path="/invite" component={Invitation} />
            <Redirect to="/" />
          </Switch>
        </Suspense>
      </Layout>
    );
  }

  // not signed in - allow public routes only
  return (
    <Layout>
      <Switch>
        <Route exact path="/login" component={LoginSignup} />
        <Route exact path="/signup" component={LoginSignup} />
        <Route exact path="/recover" component={LoginSignup} />
        <Route exact path="/action" component={Action} />
        <Route exact path="/signup/:betId" component={LoginSignup} />
        <Route exact path="/explore/:sharedBetId" component={LoginSignup}>
        {/*
        <Redirect to={{pathname: "/login/:sharedBetId" }} />
        */}
        </Route>
        <Redirect to="/login" />
      </Switch>
    </Layout>
  );

};

const mapStateToProps = ({ auth, firebase }) => ({
  loggedIn: firebase.auth.uid,
  emailVerified: firebase.auth.emailVerified,
  username: firebase.profile.username,
  profileIsLoaded: firebase.profile.isLoaded,
  profileIsEmpty: firebase.profile.isEmpty,
  currentUserHasTrustedProvider: (['facebook.com', 'google.com', 'twitter.com'].indexOf(firebase?.auth?.providerData?.[0]?.providerId) >= 0)
});

export default connect(mapStateToProps)(windowSize(App));
