import React from 'react';
import theme from '../../utils/theme'
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {makeStyles} from '@material-ui/core/styles';
import {SettingsOptionsLine} from "./SettingsOptionsLine";
import { useHistory } from "react-router-dom";
import BasicSettings from "./BasicSettings";
import {Link} from 'react-router-dom';
import * as actions from "../../store/actions";

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%'
    },
    globalTextH1: {
        color: theme.colors.secondaryColor,
        textAlign: 'center',
        fontSize: '18px'
    },
    pinkBarContainer: {
        backgroundColor: theme.colors.secondaryColor,
        width: '100%',
        height: '40px',
        alignItems: 'center',
        display: 'flex'
    },
    pinkBarText: {
        color: theme.colors.main,
        fontSize: '16px',
        marginLeft: '10px'
    }
});

const Settings = ({profile, updateProfile}) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.container}>
            <h1 className={classes.globalTextH1}>Settings</h1>
            <div className={classes.pinkBarContainer}>
                <h1 className={classes.pinkBarText}> @{profile.username} </h1>
            </div>
            <Link to={'/basicSettings'}>
                <SettingsOptionsLine optionsLineText="Basic Settings"/>
            </Link>
            <Link to={'/advancedSettings'}>
                <SettingsOptionsLine optionsLineText="Advanced Settings"/>
            </Link>
            <Link to={'/betSlipWallet'}>
                <SettingsOptionsLine optionsLineText="Bet Slip Wallet"/>
            </Link>

            <Link to={'/tokenWallet'}>
                <SettingsOptionsLine optionsLineText="Token Wallet"/>
            </Link>

            <Link to={'/reportBug'}>
                <SettingsOptionsLine optionsLineText="Report a Bug"/>
            </Link>
        </div>
        )
}

const mapStateToProps = ({firebase}) => ({
    profile: firebase.profile
});

const mapDispatchToProps = {};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Settings);
