import React from 'react';
import styled from 'styled-components';
import {FOR_DIRECT_BET_FIELD} from "../../../../containers/Explore/DropdownField/DropdownField";

const InputWrapper = styled.div`
  width: ${({ title }) => (title ? '100%' : '75%')};
  display: flex;
  
  height: ${({ twoColumnsCase }) => twoColumnsCase ? '50px' : ''};
  
  justify-content: center;
  position: relative;
  margin-bottom: 3rem;
  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    margin-bottom: 2rem;
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-bottom: 1rem;
  } */
  flex-direction: column;
  width: 100%;
 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    width: 80%;
  } */
`;

export const StyledInput = styled.input`
  padding-bottom: 3px;
  width: 90%;
  background-color: white;

  color: var(--color-mainDark);
  
  font-weight: ${({styleType})=>
    styleType === FOR_DIRECT_BET_FIELD ? '' : '400' };
    
  font-size: ${({styleType})=>
    styleType === FOR_DIRECT_BET_FIELD ? '14px' : '1.2rem' };

  [type="datetime-local"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
  }

  margin-left: ${({styleType})=>
    styleType === FOR_DIRECT_BET_FIELD ? '22px' : '' };
  margin-right: 0.5em;

  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 1.2rem;
  }
 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1rem;
  } */
  border: 0px;
  
  border-bottom: ${({styleType }) =>
    styleType === FOR_DIRECT_BET_FIELD ? 'none' : '2px solid var(--color-tertiaryColor)'};
  
  margin-top: ${({styleType})=>
    styleType === FOR_DIRECT_BET_FIELD ? '30px' : '5px' };

  &::placeholder {
    color: var(--color-mainDark);
    @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 1.2rem;
    height: 22px;
  }
  }
`;

const Error = styled.div`
  color: var(--color-errorRed);
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transform: translateY(${({ show }) => (show ? '20px' : '10px')});
  transition: all 0.1s;
  position: absolute;
  bottom: 0rem;
  left: 0;
  font-weight: 500;
  font-size: 1.2rem;

  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 0.8rem;
    bottom: 0.5rem;
  }
`;

const Input = ({ field, styleType, form: { touched, errors }, ...props }) => {
  return (
    <InputWrapper {...props}>
      <StyledInput
        {...field}
        {...props}
        styleType={styleType}
        ref={props.innerRef}
        onClick={props.onClick}
      />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </InputWrapper>
  );
};

export default Input;
