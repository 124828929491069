import React, { /*useEffect,*/ useState } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import styled from "styled-components";
import * as Yup from "yup";

import { FormWrapper, StyledForm } from "../../../hoc/layout/elements";
import Message from "../../../components/UI/Message";
import Heading from "../../../components/UI/Heading";
// import Input from "../../../components/UI/Forms/Input/Input";
import Button from "../../../components/UI/Forms/Button/Button";
import InputAuth from "../../../components/UI/Forms/Input/InputAuth";
import { countries } from "../../../utils/utilFile";
import DropdownField from "../../Explore/DropdownField/DropdownField";

/* import Modal from "../../../components/UI/Modal"; */

import * as actions from "../../../store/actions";

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -1rem;
  width: 100%;
  padding: 0 3rem;
`;

const DeleteWrapper = styled.div`
  cursor: pointer;
  color: var(--color-errorRed);
  font-size: 1.3rem;
  font-weight: 700;
  margin-top: 2rem;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  justify-content: space-around;
`;

const ProfileSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Your first name is required.")
    .min(3, "Minimum 3 characters")
    .max(25, "Maximum 25 character"),
  lastName: Yup.string()
    .required("Your last name is required.")
    .min(3, "Minimum 3 characters.")
    .max(25, "Maximum 25 characters."),
  username: Yup.string()
    .required("Your username is required")
    .min(3, "Minimum 3 characters")
    .max(12, "Maximum 12 characters"),
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  // password: Yup.string().min(8, "Minimum 8 characters"),
  // confirmPassword: Yup.string().when("password", {
  //   is: password => password && password.length > 0,
  //   then: Yup.string()
  //     .required("You need to confirm your password.")
  //     .oneOf([Yup.ref("password"), null], `Password doesn't match`)
  // })
});

const Profile = ({
  firebase,
  editProfile,
  loading,
  error,
  loadingDelete,
  errorDelete,
  deleteUser,
  cleanUp
}) => {
  /* useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]); */

  const [modalOpened, setModalOpened] = useState(false);

  /*  if (!isLoaded) return null; */
  return (
    <>
      <Formik
        initialValues={{
          firstName: firebase.profile.firstName,
          lastName: firebase.profile.lastName,
          username: firebase.profile.username,
          country: firebase.profile.country,
          email: firebase.auth.email,
          password: "",
          confirmPassword: ""
        }}
        validationSchema={ProfileSchema}
        onSubmit={async (values, { setSubmitting }) => {
          // edit the profile here
          await editProfile({
            ...values,
            country: values.country.value
          });
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, values }) => {
          return (
            <FormWrapper>
              <Heading size="h3" bold color="white">
                Complete your Profile, before logging in
              </Heading>

              <StyledForm>
                <Field
                  type="text"
                  name="firstName"
                  placeholder="Your first name..."
                  component={InputAuth}
                  profile
                />
                <Field
                  type="text"
                  name="lastName"
                  placeholder="Your last name..."
                  component={InputAuth}
                  profile
                />
                <Field
                  type="text"
                  name="username"
                  placeholder="Your username..."
                  component={InputAuth}
                  profile
                />
                <Field
                  type="text"
                  value={values.country}
                  name="country"
                  placeholder="Your country..."
                  options={countries}
                  component={DropdownField}
                  profile
                />
                <Field
                  type="email"
                  name="email"
                  placeholder="Your email..."
                  component={InputAuth}
                  profile
                />
                {/* <Field
                  type="password"
                  name="password"
                  placeholder="Your password..."
                  component={InputAuth}
                  profile
                />
                <Field
                  type="password"
                  name="confirmPassword"
                  placeholder="Re-type your password..."
                  component={InputAuth}
                  profile
                /> */}
                <Button
                  /*  disabled={!isValid || isSubmitting} */
                  loading={loading ? "Submitting..." : null}
                  auth
                  type="submit"
                  color="secondary"
                  contain
                  margin
                >
                  Submit
                </Button>
                <MessageWrapper>
                  <Message error show={error}>
                    {error}
                  </Message>
                </MessageWrapper>
                <MessageWrapper>
                  <Message success show={error === false}>
                    Profile was updated!
                  </Message>
                </MessageWrapper>
                {/*    <DeleteWrapper onClick={() => setModalOpened(true)}>
                  Delete my account
                </DeleteWrapper> */}
              </StyledForm>
            </FormWrapper>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = ({ firebase, auth }) => ({
  /* isLoaded: firebase.probile.isLoaded, */
  firebase,
  loading: auth.profileEdit.loading,
  error: auth.profileEdit.error,
  loadingDelete: auth.deleteUser.loading,
  errorDelete: auth.deleteUser.error
});

const mapDispatchToProps = {
  editProfile: actions.editProfile,
  cleanUp: actions.clean,
  deleteUser: actions.deleteUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
