import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 3rem;

  // @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
  //   margin-bottom: 2rem;
  // }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-bottom: 1rem;
  } */
  flex-direction: column;
`;

const StyledInput = styled.textarea`
  width: 100%;
  background-color: transparent;

  color: var(--color-mainDark);
  font-weight: 500;
  font-size: 1.6rem;
  padding-bottom: 0.5rem;
  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 1.5rem;
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1rem;
  } */
  border: 0px;
  border-bottom: 2px solid var(--color-tertiaryColor);

  &::placeholder {
    color: var(--color-mainDark);
  }
  
  &:placeholder-shown {
    height: 60px;
  }
`;

const Error = styled.div`
  color: var(--color-errorRed);
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transform: translateY(${({ show }) => (show ? "20px" : "10px")});
  transition: all 0.1s;
  position: absolute;
  bottom: 0rem;
  left: 0;
  font-weight: 500;
  font-size: 1.2rem;

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 0.8rem;
    bottom: 1rem;
  } */
`;

const Input = ({ field, form: { touched, errors }, ...props }) => {
  return (
    <InputWrapper {...props}>
      <StyledInput {...field} {...props} onfocus="blur()" />
      <Error show={errors[field.name] && touched[field.name]}>
        {errors[field.name]}
      </Error>
    </InputWrapper>
      );
};

export default Input;
