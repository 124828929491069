import React from "react";
import styled from "styled-components";
import Img from "../UI/Img";
import logo from "../../assets/logo.png";

const Logo = ({}) => {
  return (
    <Img
      src={logo}
      dimensions={{
        height: "2.5rem",
        width: "12rem"
      }}
      alt={"logo"}
    />
  );
};

export default Logo;
