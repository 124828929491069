import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--color-white);
  font-weight: 700;
  font-size: ${({ privacy }) => (privacy ? "1rem" : "1.2rem")};
  margin-bottom: ${({ noMarginBottom }) => (noMarginBottom ? "0rem" : "2rem")};
  transition: all 0.2s;
  border-bottom: 1px solid var(--color-white);

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: ${({ register }) => (register ? "0.5rem" : "1rem")};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  } */
`;

const CustomLink = ({ link, children, noMarginBottom, privacy, register }) => {
  return (
    <StyledLink
      to={link}
      noMarginBottom={noMarginBottom}
      privacy={privacy}
      register={register}
    >
      {children}
    </StyledLink>
  );
};

export default CustomLink;
