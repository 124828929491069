import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  width: ${({ contain }) => (contain ? 'auto' : '100%')};
  outline: none;
  padding: 1rem 3rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  color: ${({ color }) =>
    color === 'transparent' ? 'var(--color-greyColor)' : 'var(--color-white)'};
  font-weight: ${({ color }) => (color === 'transparent' ? '400' : '600')};
  box-shadow: ${({ color }) =>
    color === 'transparent'
      ? 'none'
      : '0rem 0.6rem 0.8rem var(--shadowSecondary)'};
  background-color: ${({ color }) => {
    if (color === 'red') return 'var(--color-errorRed)';
    else if (color === 'main') return 'var(--color-mainDark)';
    else if (color === 'secondary') return 'var(--color-secondaryColor)';
    else if (color === 'transparent') return 'transparent';
  }};
  margin-top: ${({ isInvitation }) => (isInvitation ? '0.5rem' : '2rem')};
  transition: all 0.2s;
  margin-bottom: ${({ margin }) => (margin ? '1.5rem' : '0')};

  &:hover {
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(2px);
  }

  &:disabled {
    cursor: not-allowed;
    /*
    background-color: ${({ auth }) =>
      auth ? 'var(--color-secondaryColor)' : 'var(--color-greyColor)'}; */
    background-color: var(--color-greyColor);
  }
`;

const Button = ({
  children,
  margin,
  disabled,
  loading,
  contain,
  color,
  classname,
  isInvitation,
  ...rest
}) => {
  return (
    <StyledButton
      color={color}
      contain={contain}
      disabled={disabled}
      classname={classname}
      margin={margin}
      isInvitation={isInvitation}
      {...rest}
    >
      {loading ? loading : children}
    </StyledButton>
  );
};

export default Button;
