import React, {useState} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {firestoreConnect} from 'react-redux-firebase';
import {PullToRefresh} from 'react-js-pull-to-refresh';
import {PullDownContent, RefreshContent} from 'react-js-pull-to-refresh';
import moment from 'moment';

import Heading from '../../components/UI/Heading';
import Loader from '../../components/UI/Loader';
import SearchForm from '../../components/SearchForm/SearchForm';
import Trending, {TrendingWrapper} from '../../components/Trending/Trending';
import BetSlipMini from '../../components/BetSlipMini/BetSlipMini';
import FinalConfirmation from '../../components/BetSlipView/FinalConfirmation';
import {
    Wrapper,
    InnerWrapper,
    Container,
    Content,
    ContentBetSlipMini,
} from '../../hoc/layout/elements';
import {nullifySharedBetIdOnProfile} from "../../store/actions";
import {populateTagsArray} from '../../utils/utilFile';
import {Link} from "react-router-dom";

const FilterBaitBet = styled.div`
  display: flex;

  align-self: center;
  ul {
    list-style: none;
    display: flex;

    li {
      width: 10rem;
      text-align: center;
      padding: 0.5rem 1rem;

      font-weight: 700;
      font-size: 1.2rem;

        &:nth-of-type(1) {
        border-radius: 5px;
        margin: 3px;
        background-color: ${({baitBet}) =>
            baitBet === 'Court' ? 'var(--color-mainDark)' : 'var(--color-white)'};
        color: ${({baitBet}) =>
            baitBet === 'Court' ? 'var(--color-white)' : 'var(--color-mainDark)'};
      }

      &:nth-of-type(2) {
        border-radius: 5px;
        margin: 3px;
        background-color: ${({baitBet}) =>
            baitBet === 'Bait' ? 'var(--color-mainDark)' : 'var(--color-white)'};
        color: ${({baitBet}) =>
            baitBet === 'Bait' ? 'var(--color-white)' : 'var(--color-mainDark)'};
      }
      &:nth-of-type(3) {
        border-radius: 5px;
        margin: 3px;
        background-color: ${({baitBet}) =>
            baitBet === 'Bet' ? 'var(--color-mainDark)' : 'var(--color-white)'};
        color: ${({baitBet}) =>
            baitBet === 'Bet' ? 'var(--color-white)' : 'var(--color-mainDark)'};
      }

    }
  }
`;

export const InnerPullToRefresh = styled.div`
  width: 100%;
  & > div {
    width: 100%;
  }
`;




export const Explore = ({bets, requesting, userId, match, profile, nullifySharedBetIdOnProfile, history}) => {
    const [courtBaitBet, setCourtBaitBet] = useState('Bait');
    const [tag, setTag] = useState('');
    const [valueSearch, setValueSearch] = useState(null);
    const [type, setType] = useState('accept');
    const [isFinalConfirmation, setFinalConfirmation] = useState(false);
    const [firsTimeWithSharedId, setFirsTimeWithSharedId] = useState(true);
    const [showJurorVoteThankYouModal, setShowJurorVoteThankYouModal] = useState(false);
    /*   const [clicked, setClicked] = useState(""); */

    if(profile.sharedBetId?.length > 0 && !firsTimeWithSharedId){
        nullifySharedBetIdOnProfile();
    }

    let content;
    let tagsArray = [];
    let tagsArray2 = [];
    let tagsArrayFiltered = [];
    let tagsArrayFiltered2 = [];
    let resultjeje = [];

    console.log("betsnew:", bets);
    const filterBaitBet = () => {
        if (courtBaitBet === 'Bait') {
            return bets.filter(bet => {
                return (
                    bet.stage === 'Pitched' &&
                    bet.creatorID !== userId &&
                    bet.offeredToID !== userId &&
                    determineIfEventStartIsAfterCurrentDate(bet) &&
                    bet.sendBet === 'Global'
                );
            });
        } else if (courtBaitBet === 'Bet') {
            return bets.filter(bet => {
                return (
                    bet.stage === 'Accepted' &&
                    bet.offeredToID !== userId &&
                    bet.creatorID !== userId &&
                    bet.contestantID !== userId &&
                    !bet.freeBettorInFavor.find(element => element === userId) &&
                    !bet.freeBettorAgainst.find(element => element === userId) &&
                    !(moment().format('X') > bet.eventStart) // !(Date.now() / 1000 > new Date(bet.eventStart).getTime() / 1000)
                );
            });
        } else if (courtBaitBet === 'Court') {
            return bets.filter(bet => {
                return bet.wentToDispute &&
                       bet.stage === 'Pending Resolution' &&
                       bet.creatorID !== userId &&
                       bet.contestantID !== userId &&
                       (!bet.jurorsInFavor?.includes(userId) && !bet.jurorsAgainst?.includes(userId)) &&
                       ((bet.privacy === 'Private' && bet.selectedJurorsArray?.includes(userId)) || (bet.privacy === 'Public')) &&
                       !bet.winnerID
                       //bet.jurorsInFavor?.length + bet.jurorsAgainst?.length < bet.numberOfJurors
            })
        }
    };



    const filterTagsArray = () => {
        if (courtBaitBet === 'Bait') {
            return bets.filter(bet => {
                return (
                    bet.stage != 'Pending Resolution' &&
                    bet.stage === 'Pitched' &&
                    bet.creatorID !== userId &&
                    bet.offeredToID !== userId &&
                    determineIfEventStartIsAfterCurrentDate(bet) &&
                    bet.sendBet === 'Global'
                );
            });
        } else if (courtBaitBet === 'Bet') {
            return bets.filter(bet => {
                return (
                  bet.stage != 'Pending Resolution' &&
                  bet.sendBet === 'Direct' &&
                  bet.stage === 'Accepted' &&
                  bet.offeredToID !== userId &&
                  bet.creatorID !== userId &&
                  bet.contestantID !== userId &&
                  !(moment().format('X') > bet.eventStart)
                );
            });
        } else if (courtBaitBet === 'Court') {
            return bets.filter(bet => {
                return (
                  bet.stage === 'Pending Resolution'
                );
            })
        }
    };

    //console.log('FILTER:', filterBaitBet);

    const filterTag = betsBaitBet => {
        if (tag && tag.value !== '') {
            return betsBaitBet.filter(bet => {
                return bet.tags.includes(tag.value) === true;
            });
        } else return betsBaitBet;
    };
    console.log('IS TAG:', filterTag);
    if (!bets && requesting) {
        content = (
            <Content>
                <Loader isWhite/>
            </Content>
        );
    } else if (Object.keys(bets).length === 0) {
        content = (
            <Content>
                <Heading size="h2">You have no bets!</Heading>
            </Content>
        );
    } else {
        console.log('Array of Bets', bets);
        console.log('1', tagsArrayFiltered);
        tagsArray = (populateTagsArray(bets));
        tagsArrayFiltered = (filterTagsArray());
        tagsArray2 = (populateTagsArray(tagsArrayFiltered));
        //tagsArrayFiltered2 = (populateTagsArray(tagsArrayFiltered));
        console.log('tagsArray', tagsArray);
        console.log('tagsArray2', tagsArray2);
        //console.log('tagsArrayFiltered', tagsArrayFiltered2);

        //var nameArray = tagsArrayFiltered.map(function (bets) { return bets.tags; });
        //nameArray= JSON.stringify(nameArray, null, 4);

        resultjeje = tagsArrayFiltered.map(a => a.tags);
        //var showtimesAsString = resultjeje.join(' ');
        console.log('tagsArrayFiltered', tagsArrayFiltered);
        console.log('testvince', resultjeje);











        const betsToShow = filterTag(filterBaitBet());

        console.log('betsToShow: ', betsToShow);

        betsToShow.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
                return -1;
            }
            if (a.createdAt < b.createdAt) {
                return 1;
            }

            return 0;
        });

        if (betsToShow.length === 0) {
            content = (
                <Content>
                    <Heading size="h2">{`There are no ${courtBaitBet}s !`}</Heading>
                </Content>
            );
        } else
            content = (
                <ContentBetSlipMini>
                    {betsToShow.map(bet => (
                        <BetSlipMini
                            key={bet.id}
                            bet={bet}
                            type={type}
                            setType={setType}
                            isFinalConfirmation={isFinalConfirmation}
                            setFinalConfirmation={setFinalConfirmation}
                            sharedBetId={match.params.sharedBetId || profile.sharedBetId}
                            firsTimeWithSharedId={firsTimeWithSharedId}
                            setFirsTimeWithSharedId={setFirsTimeWithSharedId}
                            courtBaitBet = {courtBaitBet}
                            setShowJurorVoteThankYouModal = {setShowJurorVoteThankYouModal}
                        />
                    ))}
                    {showJurorVoteThankYouModal ? (
                        <FinalConfirmation
                            opened={showJurorVoteThankYouModal}
                            close={() => setShowJurorVoteThankYouModal(false)}
                            type={'jurorVote'}
                        />
                    ) : null}
                </ContentBetSlipMini>
            );


    }

    const handleRefresh = () => {
        // do some async code here
        return new Promise(resolve => {
            setTimeout(resolve, 1000);
        });
    };

    function greeting() {
      console.log('hello!');
    }



    return (
        <Wrapper>
            <Container>
                <InnerWrapper>
                    <SearchForm
                        bets={bets}
                        setTag={setTag}
                        setValueSearch={setValueSearch}
                        valueSearch={valueSearch}
                        /*   setClicked={setClicked} */
                    />
                      { tagsArray2.length === 0 ? (
                        <TrendingWrapper>
                          <Heading size="h3" noMarginTop color="main">
                            Nothing trending in {courtBaitBet}s...
                          </Heading>
                        </TrendingWrapper>
                      ) : (
                        <Trending
                            trendingTags={tagsArray2}
                            setTag={setTag}
                            /*  clicked={clicked}
                            setClicked={setClicked} */
                            setValueSearch={setValueSearch}
                        />
                      )}

                    <FilterBaitBet baitBet={courtBaitBet}>
                        <ul className="stage">
                            <li onClick={() => {setCourtBaitBet('Court'); greeting();}}>Court House</li>
                            <li onClick={() => setCourtBaitBet('Bait')}>Baits</li>
                            <li onClick={() => setCourtBaitBet('Bet')}>Bets</li>
                        </ul>
                    </FilterBaitBet>
                    <FinalConfirmation
                        opened={isFinalConfirmation}
                        close={() => setFinalConfirmation(false)}
                        type={type}
                    />
                    <InnerPullToRefresh>
                        <PullToRefresh
                            pullDownContent={<PullDownContent/>}
                            refreshContent={<RefreshContent/>}
                            pullDownThreshold={200}
                            onRefresh={handleRefresh}
                            triggerHeight={50}
                            backgroundColor="transparent"
                            startInvisible={true}
                        >
                            {content}
                        </PullToRefresh>
                    </InnerPullToRefresh>
                </InnerWrapper>

            </Container>
        </Wrapper>
    );
};

const mapStateToProps = ({firebase, firestore}) => ({
    userId: firebase.auth.uid,
    profile: firebase.profile,
    bets: firestore.ordered.bets,
    requesting: firestore.status.requesting,
    requested: firestore.status.requested,
});

const mapDispatchToProps = {
    nullifySharedBetIdOnProfile: nullifySharedBetIdOnProfile
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(['bets']),
)(Explore);

export const determineIfEventStartIsAfterCurrentDate = (bet) => {
    // GV this methods determines if the eventDay is atLeast one day after the current date

    // let eventDate = new Date(bet.eventStart);
    // let eventDateYear = eventDate.getFullYear();
    // let eventDateMonth = eventDate.getMonth() + 1;
    // let eventDateDay = eventDate.getDate();
    //
    // let currentDate = new Date(Date.now());
    // let currentDateYear = currentDate.getFullYear();
    // let currentDateMonth = currentDate.getMonth() + 1;
    // let currentDateDay = currentDate.getDate();
    //
    // if (eventDateYear >= currentDateYear && ((eventDateMonth === currentDateMonth && eventDateDay > currentDateDay) || eventDateMonth > currentDateMonth)) {
    //     return true;
    // }
    // return false;

    // GV requirements where changed, now there is not the need to be in the next day

    return bet.eventStart > moment().format('X'); // eventDate.getTime() > currentDate.getTime();
}
