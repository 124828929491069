import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import Heading from "../../components/UI/Heading";

const GreetingWrapper = styled.div`
  margin-bottom: 3rem;
  display: blocK;

  @media ${props => props.theme.mediaQueries.smallerSmall} {
    display: none;
  }
`;

const Greeting = ({ username }) => {
  return (
    <GreetingWrapper>
      <Heading noMargin noMarginTop size="h1">
        {`Hi ${username} !`}
      </Heading>
      <Heading noMarginTop noMargin size="h3" color="secondary">
        {"Let's see what bets you've won"}
      </Heading>
    </GreetingWrapper>
  );
};

const mapStateToProps = ({ firebase }) => ({
  username: firebase.profile.username
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Greeting);
