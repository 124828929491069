import React, {useEffect, useState} from 'react';
import theme from '../../utils/theme'
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import * as actions from "../../store/actions";
import {makeStyles} from '@material-ui/core/styles';
import {Button, TextArea} from "semantic-ui-react";
import FinalConfirmation from "../../components/BetSlipView/FinalConfirmation";
import {getCoinsFromReportingBug, /*getCoinsFromSharingToSocial*/} from "../Calculations/Gamification";

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%'
    },
    backButton: {
        marginTop: '20px',
        backgroundColor: theme.colors.main,
        color: theme.colors.secondaryColor,
        fontSize: '12px',
        border: 'none'
    },
    globalTextH1: {
        color: theme.colors.secondaryColor,
        textAlign: 'center',
        fontSize: '18px',
        marginTop: '-25px'
    },
    pinkBarContainer: {
        backgroundColor: theme.colors.secondaryColor,
        width: '100%',
        height: '40px',
        alignItems: 'center',
        display: 'flex'
    },
    pinkBarText: {
        color: theme.colors.main,
        fontSize: '16px',
        marginLeft: '10px'
    },
    textAreaContainer: {
        width: '90%',
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    textArea: {
        width: '100%',
        margin: 'auto',
        height: '120px',
        padding: '7px',
        fontSize: 'small'
    },
    reportButton: {
        border: '1px',
        borderStyle: 'groove',
        borderColor: theme.colors.main,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10px'
    }
});

const ReportBug = ({userId, profile, reportBug, addUserCoins}) => {
    const classes = useStyles();

    const [reportText, setReportText] = useState("");
    const [enabledReportButton, setEnabledReportButton] = useState(false);
    const [sentBugReport, setSentBugReport] = useState(false);

    useEffect(()=>{
        if(reportText.length > 0){
            setEnabledReportButton(true);
        }else{
            setEnabledReportButton(false);
        }
    }, [reportText]);

    return (
        <div className={classes.container}>
            <div className={classes.backButton}>
                <Link to={"/settings"}> {'< Back'} </Link>
            </div>
            <h1 className={classes.globalTextH1}>Report a Bug</h1>

            <div className={classes.pinkBarContainer}>
                <h1 className={classes.pinkBarText}> @{profile.username} </h1>
            </div>


            <div className={classes.textAreaContainer}>
                <TextArea placeholder='Tell us more' onChange={(e)=>setReportText(e.target.value)} className={classes.textArea}/>
            </div>

            <Button onClick={async ()=>{await reportBug(reportText); setSentBugReport(true); await addUserCoins(userId, getCoinsFromReportingBug(profile.userNumber))}} className={classes.reportButton} disabled={!enabledReportButton}>
                Report
            </Button>

            {sentBugReport ? (
                <FinalConfirmation
                    opened={sentBugReport}
                    close={() => setSentBugReport(false)}
                    type={'bugReport'}
                />
            ) : null}


        </div>
    )
};

const mapStateToProps = ({firebase}) => ({
    userId: firebase.auth.uid,
    profile: firebase.profile,
});

const mapDispatchToProps = {
    reportBug: actions.reportBug,
    addUserCoins: actions.addUserCoins
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(ReportBug);
