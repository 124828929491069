import React from 'react';
import ListItem from './listItem';
import windowSize from 'react-window-size';
import './index.css';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      value: [],
    };

    this.handleItemClick = this.handleItemClick.bind(this);

    this.toggleList = this.toggleList.bind(this);
    this.resetListandToggle = this.resetListandToggle.bind(this);
    this.updateListandToggle = this.updateListandToggle.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', e => {
      if (this.node && !this.node.contains(e.target)) {
        this.setState({ showList: false });
      }
    });
    document.addEventListener('touchstart', e => {
      if (this.node && !this.node.contains(e.target)) {
        this.setState({ showList: false });
      }
    });

    /*  if (this.props.windowWidth <= 700) {
      this.setState({ showList: true })
    } */
  }

  componentDidUpdate(prevProps) {
    if (this.props.arraySent !== prevProps.arraySent) {
      this.setState({
        valueSent: this.props.arraySent,
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', e => {
      if (this.node && !this.node.contains(e.target)) {
        this.setState({ showList: false });
      }
    });

    document.addEventListener('touchstart', e => {
      if (this.node && !this.node.contains(e.target)) {
        this.setState({ showList: false });
      }
    });
  }

  resetListandToggle() {
    this.setState(prevState => ({
      showList: !prevState.showList,
    }));
    this.props.handleCancel();
  }

  updateListandToggle() {
    this.setState(prevState => ({
      showList: !prevState.showList,
    }));
    this.props.handleUpdate();
  }

  toggleList() {
    this.setState(prevState => ({ showList: !prevState.showList }));
  }

  handleItemClick(value) {
    this.props.onChange(value);
  }

  render() {
    return (
      <div
        ref={node => (this.node = node)}
        className={this.props.context ? null : 'select'}
      >
        <button
          onClick={this.toggleList.bind(this)}
          className={!this.state.showList ? '' : 'opened'}
        >
          <span className={this.props.context ? null : 'select_value'}>
            {this.props.name}
          </span>
        </button>

        <div className={'select_list ' + (!this.state.showList && 'hide')}>
          {this.props.windowWidth > 700 && (
            <p className={this.props.context ? null : 'filter'}>
              Filter your results to include:{' '}
            </p>
          )}

          {this.props.options &&
            this.props.options.map((option, index) => (
              <ListItem
                key={index}
                active={
                  this.props.optionsSelected &&
                  this.props.optionsSelected.includes(this.props.options[index])
                }
                show={this.state.showList}
                handleClick={this.handleItemClick}
                arraySent={this.props.arraySent}
                value={this.props.options[index]}
              />
            ))}
        </div>
      </div>
    );
  }
}
export default typeof window !== 'undefined' ? windowSize(Select) : Select;
