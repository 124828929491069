import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import Backdrop from './Backdrop';

const WrappedModal = styled.div`
  width: 90%;
  /*  height: 95%; */

  @media ${(props) => props.theme.mediaQueries.biggerMediumHeight} {
    width: 50%;
    /*  height: 68%; */
  }
  @media ${(props) => props.theme.mediaQueries.largeHeight} {
    width: 50%;
    /* height: 50%; */
  }

  position: ${({ opened }) => (opened ? 'absolute' : 'fixed')};

  top: ${(props) =>
    props.styles && props.styles.top ? props.styles.top : '60%'};

  @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
    top: 80%;
  }
  left: 50%;
  transform: ${({ opened, type }) => {
    if (opened && type === 'type') return 'translate(-50%,-50%)';
    else if (opened) return 'translate(-50%, -50%)';
    else return 'translate(-50%, 150%)';
  }};
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 150;
  justify-content: center;
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  visibility: ${({ opened }) => (opened ? 'visible' : 'hidden')};

  max-width: 40rem;
  box-shadow: 0 0.5rem 3.5em var(--shadow);
  border-radius: 1rem;
  background-color: var(--color-white);
  transition: all 0.5s;

  padding: ${({ isInvitation }) => (isInvitation ? '1rem' : '0')};
`;

export const InsideWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: ${({ type }) => (type == 'type' ? 'flex-start' : 'center')};
  padding: 1rem 1.5rem;
`;

const Modal = React.memo(
  ({ opened, close, children, type, displayType, styles, isInvitation }) => {
    return ReactDOM.createPortal(
      <>
        <Backdrop close={close} opened={opened} />
        <WrappedModal
          opened={opened}
          type={type}
          styles={styles}
          isInvitation={isInvitation}
        >
          <InsideWrapper displayType={displayType || 'flex'} type={type}>
            {children}
          </InsideWrapper>
        </WrappedModal>
      </>,
      document.getElementById('root-modal'),
    );
  },
  (prevProps, nextProps) => {
    return prevProps.opened === nextProps.opened;
  },
);

export default Modal;
