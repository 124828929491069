import React, { useState } from 'react';
import Modal from '../UI/Modal/Modal';
import { connect } from 'react-redux';
import styled from 'styled-components';
import theme from '../../utils/theme';
import PostABet from '../../containers/Explore/PostABet/PostABet';
import ConfirmBet from '../BetSlipView/ConfirmBet';
import Button from '../UI/Forms/Button/Button';
import Heading from '../UI/Heading';
import ButtonTop from '../UI/Forms/Button/ButtonTop';
import DefaultAvatar from '../../assets/default-avatar.png';
import {
  ParticipantsWrapper,
  ParticipantWrapper,
  RoundAvatar,
  CreatorWrapper,
  BaitDetails,
  OddsBar,
} from '../BetSlipMini/BetSlipMini';
import { ButtonTopWrapper } from '../../containers/Explore/PostABet/InputBet';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import './BetSlipView.css';
import { determineIfEventStartIsAfterCurrentDate } from '../../containers/Explore/Explore';
import { WANNABET_URL } from '../../Firebase/Firebase';
import { getCoinsFromSharingToSocial } from '../../containers/Calculations/Gamification';
import * as actions from '../../store/actions';
import betsReducer from '../../store/reducers/betsReducer';
import {Link} from "react-router-dom";
//import InputTitle from '../UI/Forms/Input/InputTitle';
//import { Field, Formik } from 'formik';
//import InputBet from '../../containers/Explore/PostABet/InputBet';

export const BetSlipWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 2rem;
    margin-bottom: 2rem;

    font-size: 2rem;
    color: var(--color-mainDark);

    word-break: normal;

    @media ${(props) => props.theme.mediaQueries.smallerSmallishHeight} {
      font-size: 1.5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
      font-size: 1rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 50%;

  @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
    width: 25%;
  }

  margin-bottom: 2rem;
  margin-top: 2rem;

  @media ${(props) => props.theme.mediaQueries.smallerSmallishHeight} {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }

  @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-bottom: 0rem;
    margin-top: 0rem;
  }

  button {
    @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
      padding: 0.5rem 0.5rem;
      font-size: 0.7rem;
      margin-bottom: 0.5rem;
    }
  }
`;

export const DisplayDetail = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  width: 100%;

  @media ${(props) => props.theme.mediaQueries.smallerSmallishHeight} {
    margin-bottom: 0.3rem;
  }

  @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-bottom: 0.3rem;
  }

  .label {
    margin-right: 1.3rem;
    font-weight: 700;
    color: var(--color-greyColor);
    flex-basis: 24%;

    @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
      font-size: 0.8rem;
    }
  }

  .value,
  .timezone {
    color: var(--color-mainDark);

    font-size: 1.3rem;

    @media ${(props) => props.theme.mediaQueries.smallerSmallishHeight} {
      font-size: 1.3rem;
    }

    @media ${(props) => props.theme.mediaQueries.smallerSmallestHeight} {
      font-size: 1rem;
    }
  }

  .value {
    margin-right: 1rem;
  }
`;

const HeadingSlip = styled(Heading)`
  position: absolute;
  transform: translate(-50%);
  left: 50%;
`;

const BetSlipView = ({
  opened,
  close,
  bet,
  userId,
  isFinalConfirmation,
  setFinalConfirmation,
  type,
  setType,
  notification,
  users,
  addUserCoins,
  profile,
}) => {
  const [isInputBet, setInputView] = useState(false);
  const [isConfirmBet, setConfirmView] = useState(false);
  const [postedBet, setPostedBet] = useState(false);

  const moment = require('moment');

  return (
    <>
      {bet && (
        <>
          <Modal opened={opened} close={close}>
            <BetSlipWrapper>
              <ButtonTopWrapper>
                <ButtonTop
                  type="button"
                  margin
                  color="main"
                  noMargin
                  contain
                  onClick={() => {}}
                />
                <HeadingSlip
                  size="h2"
                  className="betslip"
                  bold
                  noMargin
                  noMarginTop
                  color={'secondary'}
                >
                  Bet Slip
                </HeadingSlip>
                <ButtonTop
                  type="button"
                  margin
                  color="main"
                  noMargin
                  contain
                  onClick={() => {
                    close();
                  }}
                >
                  close
                </ButtonTop>
              </ButtonTopWrapper>

              {bet.contestantID ? (
                <ParticipantsWrapper>
                  <ParticipantWrapper
                    winner={bet.winnerID && bet.winnerID === bet.creatorID}
                    voted={
                      bet.freeBettorInFavor.length > 0 &&
                      bet.freeBettorInFavor.find(
                        (element) => element === userId,
                      )
                    }
                  >
                    <Link to={{pathname: `profile/${bet.creatorID}/`, state: { prevPath: window.location.pathname }}}>
                      <RoundAvatar accepted={bet && bet.contestantID}>
                        <img
                          src={bet.creatorPic || DefaultAvatar}
                          alt="avatar"
                          style={{
                            height: '30px',
                            width: '30px',
                            borderRadius: '50%',
                          }}
                        />
                      </RoundAvatar>
                    </Link>


                    <p>{bet.creatorUsername}</p>
                  </ParticipantWrapper>
                  <OddsBar
                    score={
                      bet.freeBettorInFavor.length > 0 ||
                      bet.freeBettorAgainst.length > 0
                        ? Math.floor(
                            (bet.freeBettorInFavor.length /
                              (bet.freeBettorInFavor.length +
                                bet.freeBettorAgainst.length)) *
                              100,
                          )
                        : 50
                    }
                  />
                  <ParticipantWrapper
                    winner={bet.winnerID && bet.winnerID === bet.contestantID}
                    voted={
                      bet.freeBettorAgainst.length > 0 &&
                      bet.freeBettorAgainst.find(
                        (element) => element === userId,
                      )
                    }
                  >
                    <Link to={{pathname: `profile/${bet.creatorID}/`, state: { prevPath: window.location.pathname }}}>
                      <RoundAvatar accepted={bet && bet.contestantID}>
                        <img
                          src={bet.contestantPic || DefaultAvatar}
                          alt="avatar"
                          style={{
                            height: '30px',
                            width: '30px',
                            borderRadius: '50%',
                          }}
                        />
                      </RoundAvatar>
                    </Link>

                    <p>{bet.contestantUsername}</p>
                  </ParticipantWrapper>
                </ParticipantsWrapper>
              ) : (
                <CreatorWrapper>
                  <Link to={{pathname: `profile/${bet.creatorID}/`, state: { prevPath: window.location.pathname }}}>
                    <RoundAvatar>
                      <img
                        src={bet.creatorPic || DefaultAvatar}
                        alt="avatar"
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '50%',
                        }}
                      />
                    </RoundAvatar>
                  </Link>
                  <BaitDetails>
                    <p>{bet.creatorUsername}</p>
                    <p>{bet.privacy} </p>
                  </BaitDetails>
                </CreatorWrapper>
              )}

              <p className="title">{bet.title}</p>



              <DisplayDetail>
                <p className="label">EVENT START</p>
                <p className="value">
                  {' '}
                  {moment(bet.eventStart, 'X').format('lll')}
                </p>
                <p className="timezone">{bet.timezone}</p>
              </DisplayDetail>
              {/*<DisplayDetail>*/}
              {/*<p className="label"> EVENT END</p>*/}
              {/*<p className="value">*/}
              {/*{moment(new Date(bet.eventEnd).getTime()).format("lll")}*/}
              {/*</p>*/}
              {/*<p className="timezone">{bet.timezone}</p>*/}
              {/*</DisplayDetail>*/}
              <DisplayDetail>
                <p className="label">BET EXPIRY</p>
                <p className="value">
                  {moment(bet.betExpiry, 'X').format('lll')}
                </p>
                <p className="timezone">{bet.timezone}</p>
              </DisplayDetail>
              {/*<DisplayDetail>*/}
              {/*<p className="label">OUTCOME</p>*/}
              {/*<p className="value">*/}
              {/*{moment(new Date(bet.outcome).getTime()).format("lll")}*/}
              {/*</p>*/}
              {/*<p className="timezone">{bet.timezone}</p>*/}
              {/*</DisplayDetail>*/}
              <DisplayDetail>
                <p className="label">WAGER AMT</p>
                <p className="value">{bet.amount}</p>
              </DisplayDetail>
              <DisplayDetail>
                <p className="label">CURRENCY</p>
                <p className="value">{bet.currency}</p>
              </DisplayDetail>
              {/*<DisplayDetail>*/}
              {/*<p className="label">SETTLEMENT</p>*/}
              {/*<p className="value">{bet.settlement}</p>*/}
              {/*</DisplayDetail>*/}
              <DisplayDetail>
                <p className="label">PRIVACY</p>
                <p className="value">{bet.privacy}</p>
              </DisplayDetail>
              <DisplayDetail>
                <p className="label">SEND BET</p>
                <p className="value">{bet.sendBet}</p>
              </DisplayDetail>

              {(bet.stage === 'Pitched' || bet.stage === 'Offered') &&
                bet.betExpiry >= moment().format('X') && (
                  <ButtonsWrapper>
                    <Button
                      type="button"
                      color="secondary"
                      contain
                      margin
                      onClick={() => {
                        setType('modify');

                        setInputView(true);

                        close();
                      }}
                    >
                      Modify
                    </Button>



                    {bet.creatorID !== userId && (
                      <Button
                        type="button"
                        color="secondary"
                        margin
                        contain
                        onClick={() => {
                          close();
                          setType('accept');

                          setConfirmView(true);
                        }}
                      >
                        Accept
                      </Button>
                    )}



                    {bet.stage === 'Offered' &&
                      betsReducer.creatorID !== userId && bet.creatorID !== userId && (
                        <Button
                          type="button"
                          color="secondary"
                          contain
                          onClick={() => {
                            close();
                            setType('reject');
                            setConfirmView(true);
                          }}
                        >
                          Reject
                        </Button>
                      )}

                    {bet.creatorID === userId && (
                      <Button
                        type="button"
                        color="secondary"
                        contain
                        onClick={() => {
                          close();
                          setType('delete');
                          setConfirmView(true);
                        }}
                      >
                        Delete
                      </Button>
                    )}
                  </ButtonsWrapper>
                )}

              {(bet.stage === 'Accepted' ||
                bet.stage === 'Pending Resolution') &&
                !(bet.betExpiry >= moment().format('X')) &&
                bet.contestantID &&
                (userId === bet.creatorID || userId === bet.contestantID) && (
                  <>
                    {!bet.winnerID &&
                    bet.lastClaimerID !== userId &&
                    bet.wentToDispute === undefined ? (
                      <ButtonsWrapper>
                        <Button
                          type="button"
                          color="secondary"
                          contain
                          margin
                          onClick={() => {
                            close();
                            switch (bet.resolutionClaimArray.length) {
                              case 0:
                                setType('claimWon');
                                setConfirmView(true);
                                break;
                              case 1:
                                if (
                                  bet.resolutionClaimArray[0] == 'claimLost'
                                ) {
                                  setType('won');
                                  setConfirmView(true);
                                } else if (
                                  bet.resolutionClaimArray[0] == 'claimWon'
                                ) {
                                  setType('claimWon');
                                  setConfirmView(true);
                                }
                                break;
                              case 2:
                                if (bet.resolutionClaimArray[0] == 'claimWon') {
                                  setType('bothSaidWonDispute');
                                  setConfirmView(true);
                                } else if (
                                  bet.resolutionClaimArray[0] == 'claimLost'
                                ) {
                                  setType('won');
                                  setConfirmView(true);
                                }
                                break;
                            }
                          }}
                        >
                          I won
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          contain
                          onClick={() => {
                            close();
                            switch (bet.resolutionClaimArray.length) {
                              case 0:
                                setType('claimLost');
                                setConfirmView(true);
                                break;
                              case 1:
                                if (
                                  bet.resolutionClaimArray[0] == 'claimLost'
                                ) {
                                  setType('claimLost');
                                  setConfirmView(true);
                                } else if (
                                  bet.resolutionClaimArray[0] == 'claimWon'
                                ) {
                                  setType('lost');
                                  setConfirmView(true);
                                }
                                break;
                              case 2:
                                if (
                                  bet.resolutionClaimArray[0] == 'claimLost'
                                ) {
                                  setType('bothSaidLostDispute');
                                  setConfirmView(true);
                                } else if (
                                  bet.resolutionClaimArray[0] == 'claimWon'
                                ) {
                                  setType('lost');
                                  setConfirmView(true);
                                }
                                break;
                            }
                          }}
                        >
                          I Lost
                        </Button>
                      </ButtonsWrapper>
                    ) : bet.winnerID === userId ? (
                      <ButtonsWrapper>
                        <Button
                          type="button"
                          color="secondary"
                          margin
                          contain
                          onClick={() => {
                            close();
                            setType('history');
                            setConfirmView(true);
                          }}
                        >
                          Got Paid
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          margin
                          contain
                          onClick={() => {
                            close();
                            setType('sendReminder');
                            setConfirmView(true);
                          }}
                        >
                          Send Reminder
                        </Button>
                        <Button
                          type="button"
                          color="secondary"
                          margin
                          contain
                          onClick={() => {
                            close();
                            setType('badDebt');
                            setConfirmView(true);
                          }}
                        >
                          Bad Debt
                        </Button>
                      </ButtonsWrapper>
                    ) : bet.winnerID ? (
                      <ButtonsWrapper>
                        <Button
                          type="button"
                          color="secondary"
                          margin
                          contain
                          onClick={() => {
                            close();
                            setType('paid');
                            setConfirmView(true);
                          }}
                        >
                          Paid
                        </Button>
                      </ButtonsWrapper>
                    ) : (
                      <></>
                    )}
                  </>
                )}

              {bet.wentToDispute && !bet.winnerID ? (
                <label
                  style={{
                    color: theme.colors.secondaryColor,
                    fontSize: 'medium',
                    margin: 'auto',
                  }}
                >
                  Your bet is pending resolution
                </label>
              ) : (
                <></>
              )}

              {bet.stage === 'Accepted' &&
              determineIfEventStartIsAfterCurrentDate(bet) ? (
                <div className="button-container">
                  <FacebookShareButton
                    url={WANNABET_URL + 'explore/' + bet.id}
                    quote={
                      "Check out this bet - '" + bet.title + "' ! At Wannabet"
                    }
                  >
                    <div
                      className="button button-facebook"
                      onClick={() =>
                        addUserCoins(
                          userId,
                          getCoinsFromSharingToSocial(profile.userNumber),
                        )
                      }
                    >
                      <i className="fab fa-facebook-f icon" />
                      <span className="shareText">Share</span>
                    </div>
                  </FacebookShareButton>
                  <TwitterShareButton
                    url={WANNABET_URL + 'explore/' + bet.id}
                    title={
                      "Check out this bet - '" + bet.title + "' ! At Wannabet"
                    }
                  >
                    <div
                      className="button button-twitter"
                      onClick={() =>
                        addUserCoins(
                          userId,
                          getCoinsFromSharingToSocial(profile.userNumber),
                        )
                      }
                    >
                      <i className="fab fa-twitter icon" />
                      <span className="shareText">Share</span>
                    </div>
                  </TwitterShareButton>
                </div>
              ) : (
                <></>
              )}



            </BetSlipWrapper>
          </Modal>

          {isInputBet ? (
            <PostABet
              setPostedBet={setPostedBet}
              opened={isInputBet}
              setIsConfirmBet={setConfirmView}
              closeConfirmBet={() => setConfirmView(false)}
              bet={bet}
              type={type}
              close={() => setInputView(false)}
            />
          ) : null}
          {isConfirmBet ? (
            <ConfirmBet
              opened={isConfirmBet}
              closeBetSlip={close}
              close={() => setConfirmView(false)}
              bet={bet}
              newBet={postedBet}
              type={type}
              isFinalConfirmation={isFinalConfirmation}
              setIsFinalConfirmation={setFinalConfirmation}
              notification={notification}
              users={users}
            />
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ firebase, firestore }) => ({
  userId: firebase.auth.uid,
  profile: firebase.profile,
  users: firestore.ordered.users,
});

const mapDispatchToProps = {
  addUserCoins: actions.addUserCoins,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(['users']),
)(BetSlipView);
