import React, { useState, ElementConfig } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";

import Select, { components } from "react-select";

export const StyledSearch = styled(Select)`
  margin-bottom: 2.5rem;
  width: 100%;
  color: var(--color-mainDark);
  font-size: 16px;
  position: relative;

  &::placeholder {
    color: var(--color-mainDark);
  }

  [class*="control"] {
    padding: 0rem 2rem;
    border-radius: 3rem;
    border-color: transparent;
    border-bottom: 2px solid var(--color-main);

    box-shadow: none;

    :hover {
    }

    .css-1hwfws3 {
      padding: 0;

      [class*="singleValue"] {
        font-size: 1.2rem;
      }
      [class*="placeholder"] {
        font-size: 1.2rem;
        color: var(--color-mainDark);
        margin-bottom: 2rem;
      }
    }
  }

  .css-1pahdxg-control {
    border-color: var(--color-greyColor) !important;
  }

  [class*="IndicatorsContainer"] {
    /*  display: none; */
    span {
      display: none;
    }
    [class*="indicatorContainer"]:nth-of-type(2) {
      display: none;
    }
  }

  [class*="menu"] {
    font-size: 1.5rem;
    z-index: 5;
    [class*="menu-list"] {
      font-size: 1.5rem;
    }
    .css-1n7v3ny-option {
      background-color: var(--color-main);
    }

    .css-9gakcf-option {
      background-color: var(--color-greyColor);
    }

    [class*="isFocused"] {
      background-color: var(--color-mainDark);
    }
  }
`;

const SearchForm = ({ bets, setTag, setValueSearch, valueSearch }) => {
  const handleChange = value => {
    // this is going to call setFieldValue and manually update values.topics
    setValueSearch(value);
    setTag(value);
  };

  const DropdownIndicator = () => {
    return <i className="fas fa-search" />;
  };

  const tagsArray = [];
  let options = [];

  if (bets) {
    bets
      .map(bet => {
        return bet.tags;
      })
      .forEach(tags => {
        tags.forEach(element => {
          if (!tagsArray.includes(element)) {
            tagsArray.push(element);
          }
        });
      });

    options = tagsArray.map(tag => ({
      value: tag,
      label: tag
    }));

    /*     options.unshift({ value: "", label: "" }); */
  }

  return (
    <StyledSearch
      id="color"
      options={bets && options}
      components={{ DropdownIndicator }}
      multi={true}
      onChange={handleChange}
      value={valueSearch}
      placeholder={"Search bets"}
      isClearable={true}
      blurInputOnSelect={true}
      autoFocus={false}
    />
  );
};

export default SearchForm;
