import React, { useState, useEffect } from 'react';
import { useSwipeable, Swipeable } from 'react-swipeable';
import moment from 'moment';

import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import styled, { css } from 'styled-components';

import {
  Wrapper,
  InnerWrapper,
  Container,
  Content,
  ContentBetSlipMini,
} from '../../hoc/layout/elements';
import Heading from '../../components/UI/Heading';
import SearchForm from '../../components/SearchForm/SearchForm';
import TypeModal from '../../components/TypeModal/TypeModal';
import Greeting from '../../components/Greeting/Greeting';
import Loader from '../../components/UI/Loader';
import BetSlipMini from '../../components/BetSlipMini/BetSlipMini';
import FinalConfirmation from '../../components/BetSlipView/FinalConfirmation';
import MultiSelectDropdown from '../../components/UI/MultiSelectDropdown';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import {
  PullDownContent,
  ReleaseContent,
  RefreshContent,
} from 'react-js-pull-to-refresh';
import { InnerPullToRefresh } from '../Explore/Explore';
import { notificationsType } from '../../store/actions/todoActions';

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const MyBets = ({ bets, requesting, userId, history, users }) => {
  const [stage, setStage] = useState('All');
  const [isTypeModal, setTypeModal] = useState(false);
  const [sendBet, setSendBet] = useState('All');
  const [tag, setTag] = useState('');
  const [valueSearch, setValueSearch] = useState(null);
  const [counter, setCounter] = useState(0);
  const [isFinalConfirmation, setFinalConfirmation] = useState(false);
  const [type, setType] = useState('add');
  const [privacySelected, setPrivacySelected] = useState('All');
  const [sentSelected, setSentSelected] = useState('Global');

  let content;

  const filterStage = () => {
    if (stage === 'Pitched') {
      //let orderedBets = bets.sort((a,b)=> b.createdAt - a.createdAt);

      return bets.filter((bet) => {
        return (
          bet.creatorID === userId &&
          (bet.stage === stage || bet.stage === 'Offered') &&
          bet.betExpiry >= moment().format('X')
        );
      });
    } else if (stage === 'Offered') {
      //let orderedBets = bets.sort((a,b)=> b.createdAt - a.createdAt);

      const currentUser = users.find((user) => user.id === userId);
      return bets.filter((bet) => {
        return (
          bet.stage === stage &&
          bet.betExpiry >= moment().format('X') &&
          (bet.offeredToID === userId ||
            (currentUser && currentUser.email === bet.offeredToEmail)) &&
          bet.creatorID !== userId
        );
      });
    } else if (stage === 'Accepted') {
      return bets.filter((bet) => {
        return (
          bet.stage === stage &&
          bet.betExpiry >= moment().format('X') && 
          (bet.creatorID === userId || bet.contestantID === userId)
        );
      });
    } else if (stage === 'Completed') {
      return bets.filter((bet) => {
        return (
          !(bet.betExpiry >= moment().format('x')) && 
          !bet.winnerID &&
          bet.contestantID &&
          (bet.creatorID === userId || bet.contestantID === userId) &&
          bet.stage !== 'Pending Resolution'
        );
      });
    } else if (stage === 'Pending Resolution') {
      return bets.filter((bet) => {
        return (
          bet.stage === stage &&
          !bet.winnerID &&
          (bet.creatorID === userId || bet.contestantID === userId)
        );
      });
    } else if (stage === 'History') {
      return bets.filter((bet) => {
        return (
          bet.stage === stage &&
          (bet.creatorID === userId || bet.contestantID === userId)
        );
      });
    } else if (stage === 'Receivable') {
      return bets.filter((bet) => {
        return (
          bet.winnerID &&
          bet.winnerID === userId &&
          !(bet.betExpiry >= moment().format('X')) &&
          !bet.paid
        );
      });
    } else if (stage === 'Payable') {
      return bets.filter((bet) => {
        return (
          bet.loserID &&
          bet.loserID === userId &&
          !(bet.betExpiry >= moment().format('X')) && 
          !bet.paid
        );
      });
    } else if (stage === 'Free') {
      return bets.filter((bet) => {
        return (
          bet.betExpiry >= moment().format('X') && 
          (bet.freeBettorInFavor.find((element) => element === userId) ||
            bet.freeBettorAgainst.find((element) => element === userId))
        );
      });
    } else if (stage === 'All') {
      const currentUser = users.find((user) => user.id === userId);
      return bets.filter((bet) => {
        return bet.creatorID === userId || bet.contestantID === userId || bet.offeredToID === userId || bet.offeredToEmail === currentUser.email;
      });
    }
  };

  const filterSendBet = (betsSendBet) => {
    if (sendBet !== 'All') {
      return betsSendBet.filter((bet) => {
        return bet.sendBet === sendBet;
      });
    } else {
      return betsSendBet;
    }
  };

  const filterPrivacy = (betsPrivacy) => {
    if (privacySelected !== 'All') {
      return betsPrivacy.filter((bet) => {
        return bet.privacy === privacySelected;
      });
    } else {
      return betsPrivacy;
    }
  };
  const sendBetText = () => {
    if (sendBet === 'all') {
      return 'All';
    } else if (sendBet === 'Direct') {
      return 'Direct';
    } else if (sendBet === 'Global') {
      return 'Global';
    }
  };

  const filterTag = (betsBaitBet) => {
    if (tag && tag.value !== '') {
      return betsBaitBet.filter((bet) => {
        return bet.tags.includes(tag.value) === true;
      });
    } else return betsBaitBet;
  };

  if (!bets && requesting) {
    content = (
      <Content>
        <Loader isWhite />
      </Content>
    );
  } else if (Object.keys(bets).length === 0) {
    content = (
      <Content>
        <Heading size="h2">You have no bets!</Heading>
      </Content>
    );
  } else {
    let betsToShow = filterTag(filterPrivacy(filterSendBet(filterStage())));
    let isFromNotification = false;
    let notification;
    if (
      history &&
      history.location &&
      history.location.state &&
      history.location.state.notification
    ) {
      const betFromNotificationToShow = bets.find(
        (bet) => bet.id === history.location.state.notification.betId,
      );
      notification = history.location.state.notification;
      //betsToShow = [];
      //betsToShow.push(betToShow);
      if (notification.type === notificationsType.offered) {
        if (stage !== 'Offered') {
          setStage('Offered');
        }
      } else if (notification.type === notificationsType.accepted) {
        if (stage !== 'Accepted') {
          setStage('Accepted');
        }
      } else if (
        notification.type === notificationsType.claimWon ||
        notification.type === notificationsType.claimLost ||
        notification.type === notificationsType.bothSaidWonDispute ||
        notification.type === notificationsType.bothSaidLostDispute
      ) {
        if (stage !== 'Completed') {
          setStage('Completed');
        }
      } else if (
        (notification.type === notificationsType.won ||
          notification.type === notificationsType.reminderToPay) &&
        betFromNotificationToShow.stage !== 'History'
      ) {
        if (stage !== 'Payable') {
          setStage('Payable');
        }
      } else if (
        (notification.type === notificationsType.lost ||
          notification.type === notificationsType.paid) &&
        betFromNotificationToShow.stage !== 'History'
      ) {
        if (stage !== 'Receivable') {
          setStage('Receivable');
        }
      } else if (
        notification.type === notificationsType.gotPaid ||
        betFromNotificationToShow.stage === 'History'
      ) {
        if (stage !== 'History') {
          setStage('History');
        }
      }
      const betToShow = { ...betFromNotificationToShow, isFromNotification };
      betToShow.isFromNotification = true;
      if (betsToShow.find((bet) => bet.id === betToShow.id) === undefined) {
        betsToShow.splice(
          betsToShow.findIndex((bet) => bet.id === betToShow.id),
        );
        betsToShow.push(betToShow);
      }
    }

    betsToShow.sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return -1;
      }
      if (a.createdAt < b.createdAt) {
        return 1;
      }

      return 0;
    });

    if (betsToShow && betsToShow.length === 0) {
      content = (
        <Content>
          <Heading size="h2">{`You have no ${
            stage === 'all' ? '' : stage
          } bets !`}</Heading>
        </Content>
      );
    } else
      content = (
        <ContentBetSlipMini>
          {betsToShow.map((bet) => (
            <BetSlipMini
              key={bet.id}
              bet={bet}
              isFinalConfirmation={isFinalConfirmation}
              setFinalConfirmation={setFinalConfirmation}
              type={type}
              setType={setType}
              isFromNotification={bet.isFromNotification}
              notification={notification}
            />
          ))}
        </ContentBetSlipMini>
      );
  }

  const FilterTypeWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 3rem;
    border-radius: 3px;
    padding: 0.5rem 1rem;
    background-color: var(--color-mainDark);
    color: var(--color-white);

    p {
      margin-right: 1rem;
    }

    i {
      color: var(--color-tertiaryColor);
      font-weight: bold;
    }
  `;

  const SwipeableWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 4rem;
    overflow: hidden;

    ::before {
      content: ' ';
      width: 15%;
      height: 100%;
      position: absolute;

      left: 0%;

      top: 0%;

      background-image: linear-gradient(
        to left,
        rgba(243, 244, 246, 0) -50%,
        rgba(243, 244, 246, 1) 100%
      );
      z-index: 3;
    }
    ::after {
      content: ' ';
      width: 15%;
      height: 100%;
      position: absolute;
      right: 0%;
      top: 0%;
      background-image: linear-gradient(
        to right,
        rgba(243, 244, 246, 0) -50%,
        rgba(243, 244, 246, 1) 100%
      );

      z-index: 3;
    }
  `;

  const FilterStage = styled.ul`
    list-style: none;
    display: flex;
    position: absolute;
    top: 0%;
    left: 50%;

    transform: ${({ stage }) => {
      if (stage == 'offered') {
        return 'translatex(-2.4em)';
      } else if (stage === 'accepted') {
        return 'translatex(-17.5%)';
      } else if (stage === 'pitched') {
        return 'translatex(-30%)';
      } else if (stage === 'completed') {
        return 'translatex(-43%)';
      } else if (stage === 'history') {
        return 'translatex(-56.2%)';
      } else if (stage === 'payable') {
        return 'translatex(-67.5%)';
      } else if (stage === 'receivable') {
        return 'translatex(-80.5%)';
      } else if (stage === 'free') {
        return 'translatex(-95%)';
      }
    }};

    /*  ::before {
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      left: -40%;

      top: 0%;
      background-image: linear-gradient(
        to left,
        rgba(243, 244, 246, 0) 75%,
        rgba(243, 244, 246, 1) 88%
      );

      z-index: 3;
      transform: ${({
      stage,
    }) => {
      if (stage == 'offered') {
        return 'translatex(2rem)';
      } else if (stage === 'accepted') {
        return 'translatex(10%)';
      } else if (stage === 'pitched') {
        return 'translatex(30%)';
      } else if (stage === 'completed') {
        return 'translatex(42%)';
      } else if (stage === 'history') {
        return 'translatex(55%)';
      } else if (stage === 'payable') {
        return 'translatex(68%)';
      } else if (stage === 'receivable') {
        return 'translatex(80%)';
      } else if (stage === 'free') {
        return 'translatex(92%)';
      }
    }};
    } */
    /*  ::after {
      content: "";
      width: 100%;
      height: 100%;
      position: fixed;
      right: 60%;

      top: 0%;
      background-image: linear-gradient(
        to right,
        rgba(243, 244, 246, 0) 75%,
        rgba(243, 244, 246, 1) 88%
      );

      z-index: 3;
      transform: ${({
      stage,
    }) => {
      if (stage == 'offered') {
        return 'translatex(2.4rem)';
      } else if (stage === 'accepted') {
        return 'translatex(17%)';
      } else if (stage === 'pitched') {
        return 'translatex(29%)';
      } else if (stage === 'completed') {
        return 'translatex(42%)';
      } else if (stage === 'history') {
        return 'translatex(55%)';
      } else if (stage === 'payable') {
        return 'translatex(67%)';
      } else if (stage === 'receivable') {
        return 'translatex(79%)';
      } else if (stage === 'free') {
        return 'translatex(48.2rem)';
      }
    }};
    } */
  `;

  const Stage = styled.li`
    flex-grow: 1;
    display: inline-block;
    text-align: center;
    /*   margin-right: 1rem; */
    position: relative;
    transition: all 0.2s;
    width: fit-content;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;

    font-size: ${({ stageName, stageOnState }) =>
      stageName === stageOnState ? '1rem' : '1.3rem'};
    transform: ${({ stageName, stageOnState }) =>
      stageName === stageOnState ? 'translateY(1rem)' : 'translateY(0rem)'};
    color: ${({ stageName, stageOnState }) =>
      stageName === stageOnState
        ? 'var(--color-secondaryColor)'
        : 'var(--color-mainDark)'};

    font-weight: ${({ stageName, stageOnState }) =>
      stageName === stageOnState ? '700' : '400'};
  `;

  const Line = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 90%;
    transform: translateX(-50%);
    width: 10.5rem;

    div:nth-child(1) {
      width: 2rem;
      border-radius: 400px;
      margin-right: 0.4rem;
      border-bottom: 3px solid rgba(114, 62, 255, 0.3);
      visibility: ${({ stageOnState }) =>
        stageOnState === 'accepted' || stageOnState === 'offered'
          ? 'hidden'
          : 'visible'};
    }
    div:nth-child(2) {
      width: 2rem;
      border-radius: 400px;
      margin-right: 0.4rem;
      border-bottom: 3px solid rgba(114, 62, 255, 0.3);
      visibility: ${({ stageOnState }) =>
        stageOnState === 'offered' ? 'hidden' : 'visible'};
    }
    div:nth-child(3) {
      width: 3rem;
      border-radius: 400px;
      margin-right: 0.4rem;
      border-bottom: 3px solid var(--color-secondaryColor);
      align-self: center;
      justify-self: center;
    }
    div:nth-child(4) {
      width: 2rem;
      border-radius: 400px;
      margin-right: 0.4rem;
      border-bottom: 3px solid rgba(114, 62, 255, 0.3);
      visibility: ${({ stageOnState }) =>
        stageOnState === 'free' ? 'hidden' : 'visible'};
    }
    div:nth-child(5) {
      width: 2rem;
      border-radius: 400px;
      margin-right: 0.4rem;
      border-bottom: 3px solid rgba(114, 62, 255, 0.3);
      visibility: ${({ stageOnState }) =>
        stageOnState === 'receivable' || stageOnState === 'free'
          ? 'hidden'
          : 'visible'};
    }

    ::before {
      content: '';
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0%;

      top: 0%;
      background-image: linear-gradient(
        to left,
        rgba(243, 244, 246, 0) 85%,
        rgba(243, 244, 246, 1)
      );
      z-index: 3;
    }
    ::after {
      content: '';
      width: 100%;
      height: 100%;
      position: fixed;
      right: 0%;
      top: 0%;
      background-image: linear-gradient(
        to right,
        rgba(243, 244, 246, 0) 85%,
        rgba(243, 244, 246, 1)
      );
      z-index: 3;
    }
  `;

  const stages = [
    'offered',
    'accepted',
    'pitched',
    'completed',
    'history',
    'payable',
    'receivable',
    'free',
  ];
  const betStage = [
    'All',
    'Offered',
    'Accepted',
    'Pitched',
    'Completed',
    'Pending Resolution',
    'History',
    'Payable',
    'Receivable',
    'Free',
  ];

  const privacy = ['All', 'Public', 'Private'];

  const sent = ['All', 'Global', 'Direct'];

  const handleSwipeLeft = () => {
    if (counter === 7) {
      setCounter(7);
      setStage(stages[7]);
    } else {
      setCounter(counter + 1);
      setStage(stages[counter + 1]);
    }
  };
  const handleSwipeRight = () => {
    if (counter > 0) {
      setCounter(counter - 1);
      setStage(stages[counter - 1]);
    } else {
      setCounter(0);
      setStage(stages[0]);
    }
  };

  const handleRefresh = () => {
    // do some async code here
    return new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  };

  return (
    <>
      <Wrapper>
        <Container>
          <InnerWrapper>
            <SearchForm
              bets={bets}
              setTag={setTag}
              setValueSearch={setValueSearch}
              valueSearch={valueSearch}
            />
            <Greeting />

            <FilterWrapper>
              <MultiSelectDropdown
                onChange={(value) => setStage(value)}
                options={betStage}
                optionsSelected={stage}
                name={'Bet Stage'}
                className="mortgageTypeFilter"
              />
              <MultiSelectDropdown
                onChange={(value) => setPrivacySelected(value)}
                options={privacy}
                optionsSelected={privacySelected}
                name={'Privacy'}
                className="mortgageTypeFilter"
              />
              <MultiSelectDropdown
                onChange={(value) => setSendBet(value)}
                /* ref={this.checkboxMortgage} */
                options={sent}
                optionsSelected={sendBet}
                /* optionsSent={this.state.arrayMortgageClassSent} */
                /* handleUpdate={this.updateMortgageClass} */
                /* handleCancel={this.cancelMortƒgadsageClass} */
                name={'Sent'}
                className="mortgageTypeFilter"
                /*  show={this.state.showFilter} */
              />
            </FilterWrapper>

            <FinalConfirmation
              opened={isFinalConfirmation}
              close={() => setFinalConfirmation(false)}
              type={type}
            />

            <TypeModal
              opened={isTypeModal}
              close={() => setTypeModal(false)}
              setSendBet={setSendBet}
              type={'type'}
            />
            <InnerPullToRefresh>
              <PullToRefresh
                pullDownContent={<PullDownContent />}
                refreshContent={<RefreshContent />}
                pullDownThreshold={200}
                onRefresh={handleRefresh}
                triggerHeight={50}
                backgroundColor="transparent"
                startInvisible={true}
              >
                {content}
              </PullToRefresh>
            </InnerPullToRefresh>
          </InnerWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

const mapStateToProps = ({ firebase, firestore, users }) => ({
  userId: firebase.auth.uid,
  users: firestore.ordered.users,
  bets: firestore.ordered.bets,
  requesting: firestore.status.requesting,
  requested: firestore.status.requested,
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect(() => ['bets', 'users']),
)(MyBets);
