// % real bets won
export function calculatePercentageOfRealBetsWon(bets, userID){

    const realBets = bets.filter(bet => bet.creatorID === userID || bet.contestantID ===userID);
    const numberOfRealBets = realBets.length;
    const percentageOfRealBetsWon = numberOfRealBets !== 0 ? (realBets.filter(bet=> bet.winnerID === userID).length / numberOfRealBets) * 100 : 0;

    return Math.round(percentageOfRealBetsWon);
}

// % free bets won
export function calculatePercentageOfFreeBetsWon(bets, userID){

    // Free bets
    const freeBets = bets.filter(bet =>
        bet.freeBettorInFavor.find(elem => elem === userID) === userID ||
        bet.freeBettorAgainst.find(elem => elem === userID) === userID
    );

    const numberOfFreeBets = freeBets.length;
    const numberOfFreeBetsWon = freeBets.filter(bet=> (bet.freeBettorInFavor.find(elem => elem === userID) && bet.creatorID === bet.winnerID) || (bet.freeBettorAgainst.find(elem => elem === userID)) && bet.contestantID === bet.winnerID).length;
    const percentageOfFreeBetsWon = numberOfFreeBets !== 0 ? ( numberOfFreeBetsWon / numberOfFreeBets ) * 100 : 0;

    return Math.round(percentageOfFreeBetsWon);
}

export function calculateNumberOfFreeBets(bets, userID){
    // Free bets
    const freeBets = bets.filter(bet =>
        bet.freeBettorInFavor.find(elem => elem === userID) === userID ||
        bet.freeBettorAgainst.find(elem => elem === userID) === userID
    );

    return freeBets.length;
}

// feedback score
export function calculateOpponentFeedbackScore(user){
    const opponentFeedBackScore =
        user &&
        (user.ratings && user.ratings.length > 0) ?
            user.ratings.reduce((prev, next) => prev + next) / (user.ratings.length)
            : 0 ;

    return Math.round(opponentFeedBackScore)|| 0;
}

// opponent diversity
export function calculateOpponentDiversity(bets, userID){
    // Get all the different opponents
    const contestants = bets && bets.map(bet => bet.contestantID === userID);
    const creators = bets && bets.map(bet => bet.creatorID === userID);

    const opponents =
        bets &&
        [...new Set([...contestants, ...creators])].filter(
            opponent => opponent && opponent !== userID,
        );

    // Calculate diversity of opponents
    const opponentDiversity =
        opponents && (opponents.length / bets.length);

    const adjustedOpponentDiversity = opponentDiversity * 100;
    const weightedOpponentDiversity = adjustedOpponentDiversity * 0.10;
    const maxOfOpponentDiversity = Math.max(0, weightedOpponentDiversity);

    return maxOfOpponentDiversity;
}

export function calculateNumberOfOpponents(bets, userID){
    // Get all the different opponents
    const contestants = bets && bets.map(bet => bet.contestantID === userID);
    const creators = bets && bets.map(bet => bet.creatorID === userID);

    const opponents = bets && [...new Set([...contestants, ...creators])].filter(opponent => opponent && opponent !== userID);

    return opponents.length;
}

// WANNASCORE
export function calculateWannaScore(bets, userID, opponentFeedbackScore, opponentDiversity){


    // WANNABET SCORE SECTION
    const currentUserBets = bets.filter((bet)=> bet.creatorID === userID || bet.contestantID === userID);


    /* ----------------------------- Percentage of Bets lost not paid ----------------------------------------------- */

    const betsLost = currentUserBets.filter(bet =>  bet.loserID === userID);
    const numberOfBetsLost = betsLost.length;
    const numberOfBetsLostAndNotPaid = betsLost.filter(bet=> bet.paid === false).length;

    const PercentageOfBetsLostAndNotPaid = numberOfBetsLost !== 0 ? numberOfBetsLost - numberOfBetsLostAndNotPaid / numberOfBetsLost : 0;

    // adjusted formula = 100-((B3*100)^1,5)
    const adjustedScoreForNumberOfBetsLostAndNotPaid = PercentageOfBetsLostAndNotPaid === 0 ? 0 : 100-(Math.pow((PercentageOfBetsLostAndNotPaid * 100), 1.5));
    const weightedScoreForNumberOfBetsLostAndNotPaid = adjustedScoreForNumberOfBetsLostAndNotPaid * 0.20;

    const maxOfweightedScoreForNumberOfBetsLostAndNotPaid = Math.max(0, weightedScoreForNumberOfBetsLostAndNotPaid);


    /* ----------------------------- Number of bets made ----------------------------------------------- */
    //const NumberOfBetsMade = currentUserBets.length;

    // adjusted formula # bets made in the last 90 days / 100
    const ninetyDaysInMilliseconds = 90 * 24 * 60 * 60 * 1000;
    const adjustedNumberOfBetsMade = currentUserBets.filter(bet=>bet.createdAt > Date.now() - ninetyDaysInMilliseconds).length / 100;
    const weightedScoreForNumberOfBetsMade = (adjustedNumberOfBetsMade * 0.05) * 100;

    const maxOfNumberOfBetsMade = Math.max(0, weightedScoreForNumberOfBetsMade);


    /* ---------------- Opponent feedback score  - is on const opponentFeedBackScore -------------------------------- */

    const adjustedOpponentFeedbackScore = opponentFeedbackScore / 5;
    const weightedScoreForOpponentFeedbackScore = (adjustedOpponentFeedbackScore * 0.15) * 100;

    const maxOfOpponentFeedbackScore = Math.max(0, weightedScoreForOpponentFeedbackScore);


    /* ----------------------------- Win Percentage ----------------------------------------------- */

    const winPercentage = currentUserBets.length !== 0 ? currentUserBets.filter(bet => bet.winnerID === userID).length / currentUserBets.length : 0;

    const adjustedWinPercentage = winPercentage * 100;
    const weightedWinPercentage = adjustedWinPercentage * 0.05;

    const maxOfWinPercentage = Math.max(0,weightedWinPercentage);


    /* ----------------------- Diversity of opponents - is on const opponentDiversity ------------------------------- */
    const adjustedDiversityOfOpponents = opponentDiversity * 100;
    const weightedDiversityOfOpponents = adjustedDiversityOfOpponents * 0.10;

    const maxOfDiversityOfOpponents = Math.max(0, weightedDiversityOfOpponents);

    // Percentage of Free bets win (for now i can't distinguish free bets from real bets)
    const percentageOfFreeBetsWin = currentUserBets.length !== 0 ? currentUserBets.filter(bet => bet.winnerID === userID).length / currentUserBets.length : 0;

    const adjustedPercentageOfFreeBetsWin = percentageOfFreeBetsWin * 100;
    const weightedPercentageOfFreeBetsWin = adjustedPercentageOfFreeBetsWin * 0.05;

    const maxOfPercentageOfFreeBetsWin = Math.max(0, weightedPercentageOfFreeBetsWin);


    // Wannabet score
    const wannabetScore = maxOfweightedScoreForNumberOfBetsLostAndNotPaid + maxOfNumberOfBetsMade + maxOfOpponentFeedbackScore
        + maxOfWinPercentage + maxOfDiversityOfOpponents + maxOfPercentageOfFreeBetsWin;


    return Math.round(wannabetScore);
}
