import ReactDOM from "react-dom";
import React from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import styled from "styled-components";

import theme from "./utils/theme";
import GlobalStyles from "./utils/global";
import store from "./store";

import App from "./App";
// import ProgressBar from "./components/UI/ProgressBar";
// import Logo from "./components/Logo/Logo";
import * as serviceWorker from "./serviceWorker";
import Loader from "./components/UI/Loader";
// import logo from "./assets/logo.png";
// import Img from "../src/components/UI/Img";

export const WrapperLoader = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 1);
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(243, 244, 246, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(47%, rgba(246, 246, 246, 1)),
    color-stop(100%, rgba(243, 244, 246, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(243, 244, 246, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(243, 244, 246, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(243, 244, 246, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(246, 246, 246, 1) 47%,
    rgba(243, 244, 246, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f3f4f6', GradientType=0 );

  /* p {
    color: var(--color-mainDark);
    margin-top: 30px;
    font-size: 1.2rem;
  } */
`;

const root = document.getElementById("root");

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <>
      <WrapperLoader>
        {/*  <Img
          src={logo}
          dimensions={{
            height: "5.5rem",
            width: "28rem"
          }}
          alt={"logo"}
        />

        <ProgressBar />
        <p>Loading...</p> */}
        <Loader isWhite />
      </WrapperLoader>
      <GlobalStyles />
    </>
  </ThemeProvider>,
  root
);

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <>
            <App />
            <GlobalStyles />
          </>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>,
    root
  );
});

serviceWorker.register();
