import React, { useState } from 'react';
import ConfirmBet from '../../containers/Explore/PostABet/ConfirmBet';
import Modal from '../UI/Modal/Modal';
import FinalConfirmation from "../BetSlipView/ConfirmBet";

const Invitation = ({ isOpened, close, setIsFinalConfirmation }) => {

  //const [isFinalConfirmation, setIsFinalConfirmation] = useState(true);

  return (
  <>
    <ConfirmBet type="invitation" opened={isOpened} close={close} setIsFinalConfirmation={setIsFinalConfirmation}/>
  </>
  );
};

export default Invitation;
