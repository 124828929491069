import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';
import _ from 'lodash';
import BetSlipView from "../BetSlipView/BetSlipView";
import DefaultAvatar from "../../assets/default-avatar.png";

import * as actions from "../../store/actions";
import { BetMiniWrapper } from "./BetMiniWrapper";
// import FinalConfirmation from "../Navigation/SideRight/SideRight";
import {getCoinsAmountFromJurorVote} from "../../containers/Calculations/Gamification";



const BetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ParticipantsWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: flex-start;
  justify-content: space-between;
`;

export const ParticipantWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;



  ::after {
     content: '${({ voted, winner }) =>
       voted ? "Your\xa0vote!" : winner ? "Winner" : ""}';
    font-size: 1rem;
    color: var(--color-secondaryColor);
    font-weight: 700;
    transform: translateX(-50%);
    left: ${({ voted }) => (voted ? "35%" : "50%")};
    height: 1.2rem;
    width: 3rem;
    position: absolute;
    top: -35%;

  }

  p {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1rem;

  }
`;

export const RoundAvatar = styled.div`
  margin-right: ${({ accepted }) => (accepted ? "0rem" : "1rem")};
`;

export const OddsBar = styled.div`
  width: 75%;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  height: 4px;
  background-color: var(--color-secondaryColor);
  border-radius: 1.5px;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: -100%;
    border: 2px solid var(--color-secondaryColor);
    border-radius: 50%;
    z-index: 4;
    background: white;
    right: ${({ score }) => (score !== false ? `${score}%` : "50%")};
    transform: translateX(50%);
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0.5rem;
    font-size: 1.3rem;
    color: var(--color-mainDark);
  }

  p.value {
    color: var(--color-secondaryColor);
  }
`;

//Bait Styles

export const CreatorWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p:nth-child(1) {
    color: var(--color-mainDark);
    font-weight: 700;
  }
  p:nth-child(2) {
    color: var(--color-secondaryColor);
  }
`;

export const BaitDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Options = styled.div`
  cursor: pointer;
  font-size: 2rem;
  color: var(--color-secondaryColor);
  font-weight: 700;
  margin-left: auto;
  margin-top: -1rem;
`;

const TitleAndInfo = styled.div`
  display: flex;
  justify-content: center;
`;

const CourtHouseSubtitle = styled.div`
  display: flex;
  justify-content: center;

    p.value {
    color: var(--color-secondaryColor);
    font-size: 16px;
    margin-top: -20px;
    margin-bottom: 20px;
  }
`;

const BaitWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const BaitInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PercentageButton = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonFree = styled.div`
  padding: ${({ opponent }) =>
    opponent === "no" ? "0.2rem 1.3rem" : "0.2rem 1.2rem"};
  color: var(--color-main);
  font-size: 1.2rem;
  border-radius: 10%;

  background-color: ${({ color }) =>
    color === "secondary"
      ? "var(--color-secondaryColor)"
      : "var(--color-mainDark)"};
`;

const ButtonPercentage = styled.div`
  color: var(--color-mainDark);
  text-align: center;
  font-size: 1.3rem;
  font-weight: bold;
`;

const BetSlipMini = ({
  bet,
  userId,
  user,
  addFreeBettorAgainst,
  addFreeBettorInFavor,
  addJurorInFavor,
  addJurorAgainst,
  isFinalConfirmation,
  setFinalConfirmation,
  type,
  setType,
  isFromNotification,
  notification,
  history,
  location,
  sharedBetId,
  firsTimeWithSharedId,
  setFirsTimeWithSharedId,
  courtBaitBet,
  settleBetFromCourtDecision,
  setShowJurorVoteThankYouModal,
  addUserCoins,
  incrementNumberOfJurorVotes
}) => {
  const [isBetSlipView, setBetSlipView] = useState(()=>{
    if(isFromNotification){
      setTimeout(function(){ setBetSlipView(true) }, 500);
      return false;
    }
    return false;
  });

  const moment = require("moment");

  const handleAddFreeBettorInFavor = async () => {
    if(courtBaitBet === 'Court'){
      await addJurorInFavor(bet);
        if(bet.jurorsInFavor?.length + bet.jurorsAgainst?.length + 1 === bet.numberOfJurors){

          const betCopy = _.cloneDeep(bet);
          betCopy.jurorsInFavor = [...bet.jurorsInFavor, userId]; // GV this needs to be added in memory, because bet is in memory and not instantaneously updated
          await settleBetFromCourtDecision(betCopy);
        }
      let coinsAmount = getCoinsAmountFromJurorVote(user, bet);
      await addUserCoins(userId, coinsAmount);
      await incrementNumberOfJurorVotes(userId);
      await setShowJurorVoteThankYouModal(true);
    }
    else {
      if (
          (bet.freeBettorAgainst.length > 0 &&
              bet.freeBettorAgainst.find(element => element === userId)) ||
          ((bet.freeBettorInFavor.length > 0 &&
              bet.freeBettorInFavor.find(element => element === userId)) ||
              userId === bet.creatorID) ||
          userId === bet.contestantID
      ) {
        return null;
      } else {
        await addFreeBettorInFavor(bet, bet.id);
      }
    }


  };

  const handleAddFreeBettorAgainst = async () => {
    setShowJurorVoteThankYouModal(true);
    if(courtBaitBet === 'Court'){
      await addJurorAgainst(bet);
      if((bet.jurorsInFavor?.length + bet.jurorsAgainst?.length + 1)  === bet.numberOfJurors){
        const betCopy = _.cloneDeep(bet);
        betCopy.jurorsAgainst = [...bet.jurorsAgainst, userId]; // GV this needs to be added in memory, because bet is in memory and not instantaneously updated
        await settleBetFromCourtDecision(betCopy);
      }
      let coinsAmount = getCoinsAmountFromJurorVote(user, bet);
      await addUserCoins(userId, coinsAmount);
      await incrementNumberOfJurorVotes(userId);
      await setShowJurorVoteThankYouModal(true);
    }else{
      if (
          (bet.freeBettorAgainst.length > 0 &&
              bet.freeBettorAgainst.find(element => element === userId)) ||
          (bet.freeBettorInFavor.length > 0 &&
              bet.freeBettorInFavor.find(element => element === userId)) ||
          (userId === bet.creatorID || userId === bet.contestantID)
      ) {
        return null;
      } else {
        return await addFreeBettorAgainst(bet, bet.id);
      }
    }
  };
  /*
  if (bet) {
    console.log(
      Math.floor(
        (bet.freeBettorInFavor.length /
          (bet.freeBettorInFavor.length + bet.freeBettorAgainst.length)) *
          100
      )
    );
  } */

  useEffect(()=>{
    if(sharedBetId !== undefined && sharedBetId.substr(0,20) === bet.id && firsTimeWithSharedId){
      setBetSlipView(true);
      setFirsTimeWithSharedId(false);
    }
  }, [sharedBetId]);

  return (
    <BetMiniWrapper accepted={bet && bet.contestantID}>
      {bet && bet.contestantID ? (
        <BetWrapper>
          <Options
            onClick={() => {
              setBetSlipView(true);
            }}
          >
            ...
          </Options>
          <TitleAndInfo>
            <p className="title">{bet.title}</p>
          </TitleAndInfo>

            {
                courtBaitBet === 'Court' ?
                    <CourtHouseSubtitle>
                        <p className="value"> (Bet in court - please vote) </p>
                    </CourtHouseSubtitle>
                    :
                    <></>
            }

          <ParticipantsWrapper>
            {bet && bet.contestantID && (
              <PercentageButton>
                <ButtonFree
                  onClick={handleAddFreeBettorInFavor}
                  color="secondary"
                >
                  Yes
                </ButtonFree>
                <ButtonPercentage>
                  {bet.freeBettorInFavor.length > 0 ||
                  bet.freeBettorAgainst.length > 0
                    ? Math.floor(
                        (bet.freeBettorInFavor.length /
                          (bet.freeBettorInFavor.length +
                            bet.freeBettorAgainst.length)) *
                          100
                      )
                    : 50}
                  %
                </ButtonPercentage>
              </PercentageButton>
            )}

            <ParticipantWrapper
              voted={
                bet.freeBettorInFavor.length > 0 &&
                bet.freeBettorInFavor.find(element => element === userId)
              }
              winner={bet.winnerID && bet.winnerID === bet.creatorID}
            >

              <Link to={{pathname: `profile/${bet.creatorID}/`, state: { prevPath: location.pathname }}}>
                <RoundAvatar accepted={bet && bet.contestantID}>
                  <img src={bet.creatorPic || DefaultAvatar} alt="avatar" style={{height: '30px', width: '30px', borderRadius: '50%'}}/>
                </RoundAvatar>
              </Link>

              <p>{bet.creatorUsername}</p>
            </ParticipantWrapper>

            <DetailsWrapper>
              <LabelsWrapper>
                <p>Wager Amount: </p>
                <p className="value">
                  {bet.amount}&nbsp;{bet.currency}{" "}
                </p>
              </LabelsWrapper>
              <LabelsWrapper>
                <p>Outcome Known: </p>
                <p className="value">
                  {moment(bet.betExpiry, 'X').format(
                    "MMM Do, YYYY"
                  )}{" "}
                  <span>{bet.timezone}</span>
                </p>
              </LabelsWrapper>
              <LabelsWrapper>
                <p>Time to Expiry: </p>
                <p className="value">
                  {moment(bet.eventStart, 'X')
                    .endOf()
                    .fromNow()}
                </p>
              </LabelsWrapper>
              <LabelsWrapper>
               <p># of Juror Votes: </p>
               <p className="value">
                   {(bet.jurorsInFavor?.length || 0) + (bet.jurorsAgainst?.length || 0)} out of {bet.numberOfJurors}
               </p>
              </LabelsWrapper>

            </DetailsWrapper>

            <ParticipantWrapper
              voted={
                bet.freeBettorAgainst.length > 0 &&
                bet.freeBettorAgainst.find(element => element === userId)
              }
              winner={bet.winnerID && bet.winnerID === bet.contestantID}
            >
              <Link to={{pathname: `profile/${bet.contestantID}/`, state: { prevPath: location.pathname }}}>
                <RoundAvatar accepted={bet && bet.contestantID}>
                  <img src={bet.contestantPic || DefaultAvatar} alt="avatar" style={{height: '30px', width: '30px', borderRadius: '50%'}}/>
                </RoundAvatar>
              </Link>

              <p>{bet.contestantUsername}</p>
            </ParticipantWrapper>
            <PercentageButton>
              <ButtonFree opponent={"no"} onClick={handleAddFreeBettorAgainst}>
                {" "}
                No
              </ButtonFree>
              <ButtonPercentage>
                {bet.freeBettorInFavor.length > 0 ||
                bet.freeBettorAgainst.length > 0
                  ? Math.floor(
                      (bet.freeBettorAgainst.length /
                        (bet.freeBettorInFavor.length +
                          bet.freeBettorAgainst.length)) *
                        100
                    )
                  : 50}
                %
              </ButtonPercentage>
            </PercentageButton>
          </ParticipantsWrapper>
        </BetWrapper>
      ) : (
        <BaitWrapper>
          <BaitInfoWrapper>
            <Options
              onClick={() => {
                setBetSlipView(true);
              }}
            >
              ...
            </Options>
            <TitleAndInfo>
              <p className="title">{bet.title}</p>
            </TitleAndInfo>

            <ParticipantWrapper>
              <Link to={{pathname: `profile/${bet.creatorID}/`, state: { prevPath: location.pathname }}}>
                <RoundAvatar>
                  <img src={bet.creatorPic || DefaultAvatar} alt="avatar" style={{height: '30px', width: '30px', borderRadius: '50%'}}/>
                </RoundAvatar>
              </Link>

              <p>{bet.creatorUsername}</p>
            </ParticipantWrapper>

            <DetailsWrapper>
              <LabelsWrapper>
                <p>Wager Amount: </p>
                <p className="value">
                  {bet.amount}&nbsp;{bet.currency}{" "}
                </p>
              </LabelsWrapper>
              <LabelsWrapper>
                <p>Outcome Known: </p>
                <p className="value">
                  {moment(bet.betExpiry, 'X').format(
                    "MMM Do, YYYY"
                  )}{" "}
                  <span>{bet.timezone}</span>
                </p>
              </LabelsWrapper>
              <LabelsWrapper>
                <p>Time to Expiry: </p>
                <p className="value">
                  {moment(bet.eventStart, 'X')
                    .endOf()
                    .fromNow()}
                </p>
              </LabelsWrapper>
            </DetailsWrapper>
          </BaitInfoWrapper>
        </BaitWrapper>
      )}
      <BetSlipView
        bet={bet && bet}
        opened={isBetSlipView}
        close={() => setBetSlipView(false)}
        isFinalConfirmation={isFinalConfirmation}
        setFinalConfirmation={setFinalConfirmation}
        type={type}
        setType={setType}
        notification={notification}
      />

    </BetMiniWrapper>
  );
};

const mapStateToProps = ({ firebase }) => ({
  userId: firebase.auth.uid,
  user: firebase.profile
});

const mapDispatchToProps = {
  addFreeBettorInFavor: actions.addFreeBettorInFavor,
  addFreeBettorAgainst: actions.addFreeBettorAgainst,
  addJurorInFavor: actions.addJurorInFavor,
  addJurorAgainst: actions.addJurorAgainst,
  settleBetFromCourtDecision: actions.settleBetFromCourtDecision,
  addUserCoins: actions.addUserCoins,
  incrementNumberOfJurorVotes: actions.incrementNumberOfJurorVotes
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(BetSlipMini));
