import React, { useState } from 'react';
import styled from 'styled-components';

import NavItem from './NavItem/NavItem';
import ItemLink from '../../UI/ItemLink';
import { withRouter } from 'react-router-dom';

import Img from '../../UI/Img';
// import SearchIcon from '../../../assets/SearchIcon.png';
// import MyBetsIcon from '../../../assets/MyBetsIcon.png';
// import MyBetsIconActive from '../../../assets/MyBetsIconActive.png';

import MakeaBetIcon from '../../../assets/plus_shadow.svg';
// import RankingsIcon from '../../../assets/RankingsIcon.png';
// import NotificationsIcon from '../../../assets/NotificationsIcon.png';
import PostABet from '../../../containers/Explore/PostABet/PostABet';

// New Icons
import { Icon } from '@iconify/react';
import searchIcon from '@iconify/icons-fa-solid/search';
import starIcon from '@iconify/icons-fa-solid/star';
import bellIcon from '@iconify/icons-whh/bell';
import diceIcon from '@iconify/icons-whh/dice';

import FinalConfirmation from '../../BetSlipView/FinalConfirmation';
import ConfirmBet from '../../BetSlipView/ConfirmBet';
import * as actions from '../../../store/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import CustomBadge from '../../UI/CustomBadge';

const Nav = styled.nav`
  display: flex;
  margin-top: ${(props) => (props.mobile ? '-6rem' : null)};
  width: 100%;
  padding: 0rem 1rem;
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
  align-items: center;
  height: 100%;
  position: relative;
  width: 100%;
  justify-content: center;
`;

const ItemLinkFooter = styled(ItemLink)`
  display: flex;
  margin-bottom: ${(props) => (props.center ? '3rem' : '0')};
  flex-direction: column;
  align-items: center;
  width: 7.5rem;
  z-index: 4;
`;

const RoundedMakeABet = styled.div`
  position: absolute;
  top: -12%;
  left: 50%;
  transform: translate(-50%);
  width: 4rem;
  height: 1rem;
  border-radius: 50% / 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--color-white);
`;

const NavItems = ({ mobile, clicked, loggedIn, location, users }) => {
  const [isPosting, setIsPosting] = useState(false);
  const [isConfirmBet, setIsConfirmBet] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);
  const [postedBet, setPostedBet] = useState(false);
  let links;
  if (loggedIn) {
    links = (
      <Ul mobile={mobile}>
        <ItemLinkFooter>
          <NavItem mobile={mobile} clicked={clicked} color={'grey'} link="/">
            <Icon icon={searchIcon} width="1.8rem" height="1.8rem" />
            <p>Explore</p>
          </NavItem>
        </ItemLinkFooter>
        <ItemLinkFooter>
          <NavItem
            mobile={mobile}
            clicked={clicked}
            color={'grey'}
            link="/myBets"
          >
            <Icon icon={diceIcon} width="1.8rem" height="1.8rem" />
            <p>My&nbsp;Bets</p>
          </NavItem>
        </ItemLinkFooter>
        <ItemLinkFooter center onClick={() => setIsPosting(true)}>
          <Img
            src={MakeaBetIcon}
            dimensions={{
              width: '4rem',
              height: '4rem',
            }}
          />
          <NavItem
            mobile={mobile}
            clicked={clicked}
            color={'grey'}
            link="/makeabet"
          >
            Make&nbsp;a&nbsp;Bet
          </NavItem>
        </ItemLinkFooter>
        <ItemLinkFooter>
          <NavItem
            mobile={mobile}
            clicked={clicked}
            color={'grey'}
            link="/rankings"
          >
            <Icon icon={starIcon} width="1.8rem" height="1.8rem" />
            Rankings
          </NavItem>
        </ItemLinkFooter>
        <ItemLinkFooter>
          <NavItem
            mobile={mobile}
            clicked={clicked}
            color={'grey'}
            link="/notifications"
          >
            <CustomBadge />
            Notifications
          </NavItem>
        </ItemLinkFooter>
        <RoundedMakeABet />
        {isPosting ? (
          <>
            <PostABet
              opened={isPosting}
              close={() => setIsPosting(false)}
              type={'add'}
              setPostedBet={setPostedBet}
              setIsConfirmBet={setIsConfirmBet}
            />
          </>
        ) : null}
        {isConfirmBet ? (
          <ConfirmBet
            opened={isConfirmBet}
            close={() => setIsConfirmBet(false)}
            type={'add'}
            newBet={postedBet}
            setIsFinalConfirmation={setIsFinalConfirmation}
            users={users}
          />
        ) : null}
        {isFinalConfirmation ? (
          <FinalConfirmation
            opened={isFinalConfirmation}
            close={() => setIsFinalConfirmation(false)}
            type={'add'}
          />
        ) : null}
      </Ul>
    );
  } else {
    links = (
      <Ul mobile={mobile}>
        <NavItem mobile={mobile} clicked={clicked} link="/login">
          Login
        </NavItem>
        <NavItem mobile={mobile} clicked={clicked} link="/signup">
          Signup
        </NavItem>
      </Ul>
    );
  }
  return <Nav mobile={mobile}>{links}</Nav>;
};

const mapStateToProps = ({ bets, users, firebase, firestore }) => ({
  users: firestore.ordered.users,
});

export default withRouter(
  compose(
    connect(mapStateToProps),
    firestoreConnect(() => {
      return [
        {
          collection: 'users',
        },
      ];
    }),
  )(NavItems),
);
