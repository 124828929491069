import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import {
  FormWrapper,
  StyledForm,
  // SocialContainer
} from "../../../hoc/layout/elements";
// import Input from "../../../components/UI/Forms/Input/Input";
import InputAuth from "../../../components/UI/Forms/Input/InputAuth";
import Button from "../../../components/UI/Forms/Button/Button";
import Heading from "../../../components/UI/Heading";
import Message from "../../../components/UI/Message";
import CustomLink from "../../../components/UI/CustomLink";
import { SignupOption } from "./LoginForm";
import { countries } from "../../../utils/utilFile";
import DropdownField from "../../Explore/DropdownField/DropdownField";

import * as actions from "../../../store/actions";
import {sharedBetId} from "./LoginSignup";

export const LoginSignUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginSignupFormWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 150;
  justify-content: center;
  width: 100%;
  max-width: 40rem;
  box-shadow: 0 0.5rem 3.5em var(--shadow);
  border-radius: 1rem;
  /*  background-color: var(--color-white); */
`;

export const LoginSignUpHeader = styled.div`
  position: fixed;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const MessageWrapper = styled.div`
  //position: absolute;
  //bottom: 9rem;
  padding-bottom: 10px;
`;

export const ButtonLogin = styled(Button)`
  /* @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-top: 0rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.5rem;
  } */
`;

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Your first name is required.")
    .min(3, "Minimum 3 characters.")
    .max(12, "Maximum 12 characters."),
  lastName: Yup.string()
    .required("Your last name is required.")
    .min(3, "Minimum 3 characters.")
    .max(12, "Maximum 12 characters."),
  username: Yup.string()
    .required("Your username is required")
    .min(3, "Minimum 3 characters")
    .max(8, "Maximum 8 characters"),
  country: Yup.string().required("Your country is required"),
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  password: Yup.string()
    .required("The passoword is required.")
    .min(8, "Minimum 8 characters.")
    .max(12, "Maximum 12 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], `Password doesn't match`)
    .required("You need to confirm your password.")
});

const SignUpForm = ({
  signUp,
  loading,
  error,
  cleanUp,
  location,
  googleSignUp,
  facebookSignUp,
  twitterSignup,
  betId
}) => {

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        password: "",
        confirmPassword: ""
      }}
      validationSchema={SignUpSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await signUp({
          ...values,
          country: values.country.value,
          betId: betId
        }, sharedBetId);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, values }) => (
        <FormWrapper>
          <StyledForm>
            <Heading size="h3" noMarginTop bold color="white">
              Sign up
            </Heading>
            <Field
              type="text"
              name="firstName"
              placeholder="Your first name..."
              component={InputAuth}
              profile
            />
            <Field
              type="text"
              name="lastName"
              placeholder="Your last name..."
              component={InputAuth}
              profile
            />
            <Field
              type="text"
              name="username"
              placeholder="Your username..."
              component={InputAuth}
              profile
            />

            <Field
              type="text"
              profile
              value={values.country}
              name="country"
              placeholder="Your country..."
              options={countries}
              component={DropdownField}
            />
            <Field
              type="email"
              name="email"
              placeholder="Your email..."
              profile
              component={InputAuth}
            />
            <Field
              type="password"
              name="password"
              placeholder="Your password..."
              component={InputAuth}
              profile
            />
            <Field
              type="password"
              name="confirmPassword"
              placeholder="Re-type your password..."
              component={InputAuth}
              profile
            />
            <ButtonLogin
              /* disabled={!isValid || isSubmitting} */
              loading={loading ? "Signing up..." : null}
              type="submit"
              contain
              auth
              color="secondary"
            >
              Sign up
            </ButtonLogin>
            <Heading noMargin privacy bold register color="white" size="h6">
              By signing up, you agree to our&nbsp;
              <CustomLink privacy>Terms of service</CustomLink>&nbsp;and&nbsp;
              <CustomLink privacy>Privacy&nbsp;policy</CustomLink>
            </Heading>
          </StyledForm>
          <SignupOption>
            <Heading bold size="h4" color="white" noMargin noMarginTop register>
              Already have an account?
            </Heading>
            <CustomLink link="/login" color="white" register>
              Login
            </CustomLink>
          </SignupOption>
          <MessageWrapper>
            <Message error show={error}>
              {error}
            </Message>
          </MessageWrapper>
        </FormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth }) => ({
  loading: auth.loading,
  error: auth.error
});

const mapDispatchToProps = {
  signUp: actions.signUp,
  cleanUp: actions.clean,
  googleSignUp: actions.googleSignUp,
  facebookSignUp: actions.facebookSignUp,
  twitterSignUp: actions.twitterSignUp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SignUpForm));
