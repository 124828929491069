import React, { useState } from "react";
import { SideWrapper } from "../../../hoc/layout/elements";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect, actionTypes } from "react-redux-firebase";

import Button from "../../UI/Forms/Button/Button";
import Img from "../../UI/Img";
import PostABet from "../../../containers/Explore/PostABet/PostABet";
import styled from "styled-components";
import { RoundAvatar } from "../../BetSlipMini/BetSlipMini";
import Trending from "../../Trending/Trending";
import { populateTagsArray } from "../../../utils/utilFile";
import MakeBet from "../../../assets/makeabet.png";
import DefaultAvatar from "../../../assets/default-avatar.png";
import * as actions from "../../../store/actions";
import ConfirmBet from "../../BetSlipView/ConfirmBet";
import FinalConfirmation from "../../BetSlipView/FinalConfirmation";
import {Avatar} from "@material-ui/core";
import LogoutIcon from '../../../assets/exit.png';

const TopSideRight = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 118%;

  @media ${props => props.theme.mediaQueries.biggerMedium} {
    margin-bottom: 90%;
  }
  @media ${props => props.theme.mediaQueries.biggerLarge} {
    margin-bottom: 85%;
  }
`;

const ButtonRight = styled(Button)`
  display: flex;
  align-items: center;
  padding: 1rem 2.5rem;
  margin-top: 0;

  i {
    margin-right: 1rem;
  }
`;

const SideRight = ({
  location,
  loggedIn,
  bets,
  signOut,
  emailVerified,
  users,
  usernameExists,
  profileIsEmpty,
  profileIsLoaded,
  profile
}) => {
  const [isPosting, setIsPosting] = useState(false);
  const [isConfirmBet, setIsConfirmBet] = useState(false);
  const [isFinalConfirmation, setIsFinalConfirmation] = useState(false);
  const [postedBet, setPostedBet] = useState(false);
  let tagsArray = [];

  if (bets) {
    tagsArray = populateTagsArray(bets);
  }

  return loggedIn &&
    emailVerified &&
    (location.pathname === "/" ||
      location.pathname.toLowerCase() === "/mybets" ||
      location.pathname === "/explore" ||
      location.pathname.toLowerCase().includes("/explore/") ||
      location.pathname === "/notifications" ||
      location.pathname.toLowerCase().includes( "/profile/") ||
      location.pathname === "/rankings" ||
      location.pathname === "/settings" || location.pathname === "/basicSettings" || location.pathname === "/advancedSettings" ||
      location.pathname === "/betSlipWallet" || location.pathname === "/reportBug" || location.pathname === "/tokenWallet") ? (
    <SideWrapper right color={"#f3f4f6"}>
      <TopSideRight>
        <Link to="/settings">
          <RoundAvatar>
            <img src={profile.photoUrl?.length > 0 ? profile.photoUrl : DefaultAvatar} alt="Avatar" style={{width: '35px', height: '35px', borderRadius: '50%'}}/>
          </RoundAvatar>
        </Link>
        <div
          onClick={async () => await signOut()}
          style={{ marginRight: '15px' }}
        >
          <Img
            dimensions={{
              width: '1.8rem',
              height: '1.8rem',
            }}
            src={LogoutIcon}
          />
        </div>
        {
          <ButtonRight
            color="secondary"
            contain
            onClick={() => setIsPosting(true)}
          >
            <i className="fas fa-plus" />
            <p>Make&nbsp;a&nbsp;bet</p>
          </ButtonRight>
        }
      </TopSideRight>

      {isPosting ? (
          <>
            <PostABet
              opened={isPosting}
              close={() => setIsPosting(false)}
              type={'add'}
              setPostedBet={setPostedBet}
              setIsConfirmBet={setIsConfirmBet}
            />
          </>
        ) : null}
        {isConfirmBet ? (
          <ConfirmBet
            opened={isConfirmBet}
            close={() => setIsConfirmBet(false)}
            type={'add'}
            newBet={postedBet}
            setIsFinalConfirmation={setIsFinalConfirmation}
            users={users}
          />
        ) : null}
        {isFinalConfirmation ? (
          <FinalConfirmation
            opened={isFinalConfirmation}
            close={() => setIsFinalConfirmation(false)}
            type={'add'}
          />
        ) : null}


    </SideWrapper>
  ) : null;
};

const mapStateToProps = ({ firebase, firestore }) => ({
  userId: firebase.auth.uid,
  profile: firebase.profile,
  bets: firestore.ordered.bets,
  requesting: firestore.status.requesting,
  requested: firestore.status.requested,
  users: firestore.ordered.users,
});

const mapDispatchToProps = {
  signOut: actions.signOut
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  firestoreConnect(["bets","users"])
)(withRouter(SideRight));
