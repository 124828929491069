import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Li = styled.li`
  display: flex;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  border-bottom: ${(props) =>
    props.mobile ? '1px solid transparent' : '2px solid transparent;'};
  font-size: 1.2rem;
  padding: ${(props) => (props.side ? '1rem' : '0rem')};
  text-align: center;
  font-weight: 400;
  color: ${({ color }) =>
    color === 'grey' ? 'var(--color-greyColor)' : 'var(--color-mainDark)'};

  transition: all 0.2s;
  flex-direction: column;

  svg {
    color: var(--color-mainDark);
  }

  &.active {
    color: ${(props)=> !props.color ? "#723eff": 'black'};

    svg {
      color: ${(props)=> !props.color ? "#723eff": 'black'};
    }
  }
`;

const NavItem = ({
  link,
  children,
  mobile,
  clicked,
  color,
  footer,
  css,
  side,
}) => {
  return (
    <Li>
      <StyledNavLink
        onClick={clicked}
        exact
        activeClassName="active"
        mobile={mobile ? 1 : 0}
        to={link}
        color={color}
        footer={footer}
        css={css}
        side={side}
      >
        {children}
      </StyledNavLink>
    </Li>
  );
};

export default NavItem;
