import React, { useState } from "react";
import styled from "styled-components";

import Button from "../UI/Forms/Button/Button";
import Heading from "../UI/Heading";

const RadioButton = styled.div`
  width: 100%;
`;

const ButtonType = styled(Button)`
  align-self: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const HeadingType = styled(Heading)`
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
`;

const Label = styled.label`
  display: flex;
  color: var(--color-secondaryColor);
  font-size: 2rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;

  display: flex;
  align-items: center;

  border-bottom: ${({ count }) => {
    if (count === "2") {
      return "2px solid var(--color-tertiaryColor)";
    } else {
      return "0";
    }
  }};

  border-top: ${({ count }) => {
    if (count === "2") {
      return "2px solid var(--color-tertiaryColor)";
    } else {
      return "0";
    }
  }};
`;

const RadioInput = styled.input`
  margin-right: 1rem;
`;

const RadioForm = ({ close, setSendBet, type }) => {
  const [selected, setChecked] = useState("all");

  return (
    <>
      <HeadingType size="h3" noMarginTop bold type={type}>
        SORT BETS BY
      </HeadingType>
      <RadioButton>
        <Label count="1">
          <RadioInput
            type="radio"
            name="radio"
            value="direct"
            checked={selected === "direct"}
            onChange={() => {
              setChecked("direct");
              setSendBet("Direct");
            }}
          />
          Direct
        </Label>
      </RadioButton>
      <RadioButton>
        <Label count="2">
          <RadioInput
            type="radio"
            name="radio"
            value="global"
            checked={selected === "global"}
            onChange={() => {
              setChecked("global");
              setSendBet("Global");
            }}
          />
          Global
        </Label>
      </RadioButton>
      <RadioButton>
        <Label count="3">
          <RadioInput
            type="radio"
            name="radio"
            value="all"
            checked={selected === "all"}
            onChange={() => {
              setChecked("all");
              setSendBet("all");
            }}
          />
          All
        </Label>
      </RadioButton>
      <ButtonType color="secondary" contain onClick={close}>
        Close
      </ButtonType>
    </>
  );
};

export default RadioForm;
