import React from "react";
import styled, { css } from "styled-components";

const baseStyle = css`
  color: ${({ color }) =>
    color === "white"
      ? "var(--color-white)"
      : color === "secondary"
      ? "var(--color-secondaryColor)"
      : "var(--color-textColor)"};
  font-weight: ${({ bold }) => (bold ? "700" : "300")};
  margin-top: ${({ noMarginTop, privacy }) =>
    noMarginTop ? "0rem" : privacy ? "1rem" : "2rem"};

  letter-spacing: cp 1px;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0rem" : "2rem")};
  text-align: ${({ privacy }) => privacy && "center"};
`;

const Heading1 = styled.h1`
  font-size: 4.5rem;
  ${baseStyle}
`;

const Heading2 = styled.h2`
  font-size: 2.5rem;
  ${baseStyle}

  @media ${props => props.theme.mediaQueries.smallerSmallishHeight} {
    font-size: 2rem;
  }
 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 1.5rem;
  } */
`;

const Heading3 = styled.h3`
  font-size: 1.5rem;
  ${baseStyle}

  /* @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: ${({ register }) => (register ? "1rem" : "1.5rem")};
    margin-top: ${({ register }) => (register ? "0" : "0.5rem")};
    margin-bottom: ${({ register }) => (register ? "0" : "0.5rem")};
    
  } */
`;

const Heading4 = styled.h4`
  font-size: 1.3rem;
  ${baseStyle}
`;
const Heading5 = styled.h5`
  font-size: 1.2rem;
  ${baseStyle}
`;
const Heading6 = styled.h6`
  font-size: 1rem;

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    font-size: 0.5rem;
    margin-top: ${({ register }) => (register ? "0rem" : "0.5rem")};
    margin-bottom: ${({ register }) => (register ? "0rem" : "0.5rem")};
  } */
  ${baseStyle}
`;

const Heading = ({
  children,
  color,
  noMargin,
  noMarginTop,
  bold,
  size,
  privacy,
  type,
  css,
  register,
  className
}) => {
  if (size === "h1")
    return (
      <Heading1
        noMargin={noMargin}
        noMarginTop={noMarginTop}
        bold={bold}
        color={color}
        className={className}
        register={register}
      >
        {children}
      </Heading1>
    );

  if (size === "h2")
    return (
      <Heading2
        noMargin={noMargin}
        noMarginTop={noMarginTop}
        bold={bold}
        color={color}
        className={className}
      >
        {children}
      </Heading2>
    );

  if (size === "h3")
    return (
      <Heading3
        noMargin={noMargin}
        noMarginTop={noMarginTop}
        bold={bold}
        color={color}
        className={className}
        register={register}
      >
        {children}
      </Heading3>
    );

  if (size === "h4")
    return (
      <Heading4
        noMargin={noMargin}
        noMarginTop={noMarginTop}
        bold={bold}
        color={color}
        className={className}
      >
        {children}
      </Heading4>
    );
  if (size === "h5")
    return (
      <Heading5
        noMargin={noMargin}
        noMarginTop={noMarginTop}
        bold={bold}
        color={color}
        className={className}
      >
        {children}
      </Heading5>
    );
  if (size === "h6")
    return (
      <Heading6
        noMargin={noMargin}
        noMarginTop={noMarginTop}
        bold={bold}
        color={color}
        privacy={privacy}
        className={className}
        register={register}
      >
        {children}
      </Heading6>
    );
};

export default Heading;
