import styled from "styled-components";
import { Form } from "formik";

export const Container = styled.div`
  width: 100%;
  max-width: 140rem;
  margin: 0 auto;
  height: 100%;
  position: relative;
`;

export const FormWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledForm = styled(Form)`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const SocialContainer = styled.div`
  display: flex;

  img:nth-child(2) {
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export const SideWrapper = styled.div`
  flex-basis: 25%;
  background-color: ${({ color }) =>
    color === "white" ? "var(--color-white)" : "var(--color-main)"};
  @media ${props => props.theme.mediaQueries.smallerSmall} {
    display: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 2rem;
  padding-right: ${({ right }) => (right ? "3rem" : "0rem")};
`;

export const Wrapper = styled.div`
  width: 100%;
  align-self: flex-start;
  height: 100%;
  /*  min-height: calc(100vh - 6rem); */
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 2rem;
  position: relative;
  /*  overflow: hidden; */

  #container2 {
    #arrow {
      display: none;
    }

    span {
      display: none;
    }
  }

  .pullToRefresh {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  //max-width: 60rem;
  flex-direction: column;
  margin-top: 2rem;
`;
export const ContentBetSlipMini = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  flex-direction: column;
  margin-top: 2rem;

  &:last-child {
    margin-bottom: 9rem;
  }
`;

export const Error = styled.div`
  color: var(--color-errorRed);
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transform: translateY(${({ show }) => (show ? "20px" : "10px")});
  transition: all 0.1s;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0rem 2rem;
  font-weight: 500;
  font-size: 1.2rem;
`;
