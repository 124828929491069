import React, {useEffect, useState} from 'react';
import theme from '../../utils/theme'
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import {makeStyles} from '@material-ui/core/styles';
import {SettingsOptionsLine} from "./SettingsOptionsLine";
import {Button} from "semantic-ui-react";
import {Link} from "react-router-dom";
import * as actions from "../../store/actions";
import FinalConfirmation from "../../components/BetSlipView/FinalConfirmation";
import {countries, countriesForDropdown} from "../../utils/utilFile";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import {addUserCoins} from "../../store/actions";
import {getCoinsFromCompleteProfile} from "../Calculations/Gamification";

const useStyles = makeStyles({
    container: {
        width: '100%',
        height: '100%'
    },
    backButton: {
      marginTop: '20px',
      backgroundColor: theme.colors.main,
      color: theme.colors.secondaryColor,
      fontSize: '12px',
      border: 'none'
    },
    globalTextH1: {
        color: theme.colors.secondaryColor,
        textAlign: 'center',
        fontSize: '18px',
        marginTop: '-25px'
    },
    saveButtonContainer: {
      float: 'right'
    },
    saveButton: {
        marginTop: '-40px',
        backgroundColor: theme.colors.main,
        color: theme.colors.secondaryColor,
        fontSize: '12px',
        border: 'none',
        width: 'auto',
        marginRight: '0px',
        paddingRight: '0px'
    },
    pinkBarContainer: {
        backgroundColor: theme.colors.secondaryColor,
        width: '100%',
        height: '40px',
        alignItems: 'center',
        display: 'flex'
    },
    pinkBarText: {
        color: theme.colors.main,
        fontSize: '16px',
        marginLeft: '10px'
    },
    lineContainer: {
        width: '90%',
        margin: 'auto'
    },
    lineWithTextContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10px',
        marginBottom: '10px'
    },
    lineText: {
        marginLeft: '5px'
    },
    lineIconContainer: {
        height: '100%'
    },
    input: {
        border: '1px',
        borderStyle: 'solid',
        borderColor: theme.colors.fourthColor,
        marginRight: '5px',
        borderRadius: '3px',
        height: '30px',
        width: '150px',
        display: 'flex',
        alignItems: 'center',
    },
    dropdownContainer: {
        width: '150px',
        marginRight: '5px',
        border: '1px',
        borderStyle: 'solid',
        borderRadius: '3px',
        borderColor: theme.colors.fourthColor,
    },
    dropdown: {
        border: 'none !important',
        backgroundColor: '#f3f4f6 !important'
    },
});

const BasicSettings = ({profile, userId, updateProfile, usernames, uploadProfilePicture, addUserCoins, setBasicSettingsProfileComplete}) => {
    const classes = useStyles();

    const [firstName, setFirstName] = useState(profile.firstName);
    const [lastName, setLastName] = useState(profile.lastName);
    const [username, setUsername] = useState(profile.username);
    const [country, setCountry] = useState(profile.country);
    const [hasChanges, setHasChanges] = useState(false);
    const [firstNameIsTooShort, setFirstNameIsTooShort] = useState(false);
    const [lastNameIsTooShort, setLastNameIsTooShort] = useState(false);
    const [usernameIsTooShort, setUsernameIsTooShort] = useState(false);
    const [usernameAlreadyExists, setUsernameAlreadyExists] = useState(false);
    const [hasProfilePicToUpload, setHasProfilePicToUpload] = useState(false);
    const [profilePic, setProfilePic] = useState(null);
    const [updateProfileModal, setUpdatedProfileModal] = useState(false);
    const [upDateProfilePicModal, setUpDateProfilePicModal] = useState(false);


    useEffect(()=>{
        if((firstName.length > 2 && lastName.length > 2 && username.length > 2 && country.length > 2) &&
            (firstName !== profile.firstName || lastName !== profile.lastName || username !== profile.username || country !== profile.country || hasProfilePicToUpload) &&
            (!usernameAlreadyExists && !usernameIsTooShort)){
            setFirstNameIsTooShort(false);
            setLastNameIsTooShort(false);
            setUsernameIsTooShort(false);
            setHasChanges(true);
        }else{
            (firstName.length < 3 && firstName.length !== 0) ? setFirstNameIsTooShort(true) : setFirstNameIsTooShort(false);
            (lastName.length < 3 && lastName.length !== 0) ? setLastNameIsTooShort(true) : setLastNameIsTooShort(false);
            (username.length < 3 && username.length !== 0) ? setUsernameIsTooShort(true) : setUsernameIsTooShort(false);

            setHasChanges(false);
        }
    }, [profile, firstName, lastName, username, country, hasProfilePicToUpload]);

    const save = async ()=>{
        let editedProfile = {firstName: firstName, lastName: lastName, username: username, country: country, oldUsername: profile.username};
        await updateProfile(editedProfile, "basicSettings");

        setUpdatedProfileModal(true);
        if(hasProfilePicToUpload){
            uploadProfilePicture(profilePic);
            setUpDateProfilePicModal(true);
            setHasProfilePicToUpload(false);
            setProfilePic(null);
        }

        if(profile.firstName && profile.lastName && profile.username && profile.country && profile.email && !profile.hasBasicSettingsProfileComplete){
            addUserCoins(userId, getCoinsFromCompleteProfile(profile.userNumber));
            setBasicSettingsProfileComplete();
        }
    };

    const onFieldChanges = async (field, e) => {
        switch (field) {
            case 'firstName':
                setFirstName(e.target.value);
                break;
            case 'lastName':
                setLastName(e.target.value);
                break;
            case 'username':
                setUsername(e.target.value);
                if(usernames.filter(username => username.id === e.target.value).length !== 0){
                    setUsernameAlreadyExists(true);
                }else{
                    setUsernameAlreadyExists(false);
                }
                break;
            case 'country':
                setCountry(e.target.innerText);
                break;
            case 'profilePic':
                if(e.target.files[0]){
                    setHasProfilePicToUpload(true);
                    setProfilePic(e.target.files[0]);
                }else{
                    setHasProfilePicToUpload(false);
                }
                break;
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.backButton}>
                <Link to={"/settings"}> {'< Back'} </Link>
            </div>
            <h1 className={classes.globalTextH1}>Basic Settings</h1>
            {
                hasChanges ?
                    <div className={classes.saveButtonContainer}>
                        <Button className={classes.saveButton} onClick={()=>save()}> Save </Button>
                    </div>
                    :
                    <></>
            }

            <div className={classes.pinkBarContainer}>
                <h1 className={classes.pinkBarText}> @{profile.username} </h1>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>First Name</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input type="text" value={firstName} placeholder={profile.firstName} style={{marginLeft: '10px', borderStyle: 'none', backgroundColor: theme.colors.main}} onChange={(e)=> onFieldChanges('firstName', e)}/>
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Last Name</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input type="text" value={lastName} placeholder={profile.lastName} style={{marginLeft: '10px', borderStyle: 'none', backgroundColor: theme.colors.main}} onChange={(e)=> onFieldChanges('lastName', e)}/>
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Username</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input type="text" value={username} placeholder={profile.username} style={{marginLeft: '10px', borderStyle: 'none', backgroundColor: theme.colors.main}} onChange={(e)=> onFieldChanges('username', e)}/>
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Country</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.dropdownContainer}>
                            <Dropdown
                                clearable
                                fluid
                                search
                                selection
                                options={countriesForDropdown}
                                value={country}
                                placeholder={profile.country || "Select country.."}
                                className={classes.dropdown}
                                onChange={(event)=>onFieldChanges('country', event)}
                            />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            {
                !profile.email || profile.email === null || profile.email === "" ?
                    <></>
                    :
                    <div className={classes.lineContainer}>
                        <div className={classes.lineWithTextContainer}>
                            <div className={classes.lineText}>
                                <label style={{fontSize: '16px'}}>Email</label>
                            </div>
                            <div style={{flexDirection: 'row', display: 'flex'}}>
                                <div className={classes.input}>
                                    <input type="text" disabled placeholder={profile.email} style={{width: '130px', marginLeft: '10px', borderStyle: 'none'}}/>
                                </div>
                            </div>
                        </div>
                        <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
                    </div>
            }



            <div className={classes.lineContainer}>
                <div className={classes.lineWithTextContainer}>
                    <div className={classes.lineText}>
                        <label style={{fontSize: '16px'}}>Profile Pic</label>
                    </div>
                    <div style={{flexDirection: 'row', display: 'flex'}}>
                        <div className={classes.input}>
                            <input type="file" onChange={(e)=> onFieldChanges('profilePic', e)} size="120" style={{ marginLeft: '5px' }} />
                        </div>
                    </div>
                </div>
                <hr  style={{backgroundColor: '#eeeeee', height: .1, borderColor : 'transparent'}}/>
            </div>

            { firstNameIsTooShort ?
                <div style={{textAlign: 'center', color: 'red', marginTop: '10px'}}> First name: Minimum 3 characters </div>
                :
                <></>
            }
            { lastNameIsTooShort ?
                <div style={{textAlign: 'center', color: 'red', marginTop: '10px'}}> Last name: Minimum 3 characters </div>
                :
                <></>
            }
            { usernameIsTooShort ?
                <div style={{textAlign: 'center', color: 'red', marginTop: '10px'}}> Username: Minimum 3 characters </div>
                :
                <></>
            }
            { usernameAlreadyExists ?
                <div style={{textAlign: 'center', color: 'red', marginTop: '10px'}}> Username already exists </div>
                :
                <></>
            }

            {updateProfileModal ? (
                <FinalConfirmation
                    opened={updateProfileModal}
                    close={() => setUpdatedProfileModal(false)}
                    type={'updatedProfile'}
                />
            ) : null}

            {upDateProfilePicModal ? (
                <FinalConfirmation
                    opened={upDateProfilePicModal}
                    close={() => setUpDateProfilePicModal(false)}
                    type={'updatedProfilePic'}
                />
            ) : null}
        </div>
    )
};

const mapStateToProps = ({firebase, firestore}) => ({
    userId: firebase.auth.uid,
    profile: firebase.profile,
    usernames: firestore.ordered.usernames,
});

const mapDispatchToProps = {
    updateProfile: actions.updateProfile,
    uploadProfilePicture: actions.uploadProfilePicture,
    addUserCoins: actions.addUserCoins,
    setBasicSettingsProfileComplete: actions.setBasicSettingsProfileComplete
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect(['usernames']),
)(BasicSettings);
