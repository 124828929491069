import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import {
  // FormWrapper,
  StyledForm,
  SocialContainer
} from "../../../hoc/layout/elements";
// import InputCheckbox from "../../../components/UI/Forms/Input/InputCheckbox";
import InputAuth from "../../../components/UI/Forms/Input/InputAuth";
import Button from "../../../components/UI/Forms/Button/Button";
import Heading from "../../../components/UI/Heading";
import Message from "../../../components/UI/Message";
import CustomLink from "../../../components/UI/CustomLink";
// import { InsideWrapper } from "../../../components/UI/Modal/Modal";
import Img from "../../../components/UI/Img";

import facebook from "../../../assets/facebook.png";
import google from "../../../assets/google_icon.svg";
import twiiter from "../../../assets/twitter.png";

import {sharedBetId} from "./LoginSignup";

const LoginSignupFormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  background-color: var(--color-mainDark) !important;
  color: var(--color-white);
  border-radius: 1rem;

  display: flex;
`;

const LoginOptions = styled.div`
  display: flex;
  flex-direction: horizontal;
  justify-content: flex-end;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 2rem;

  @media ${props => props.theme.mediaQueries.biggerSmall} {
    width: 50%;
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    width: 60%;
  } */
`;

const RememberMe = styled.div`
  display: flex;

  align-items: center;
  justify-content: flex-start;

  input {
    margin-right: 0.5rem;
  }
`;

const Icon = styled.i`
  font-weight: 900;
  position: absolute;
  top: 37%;
  right: 10%;

  @media ${props => props.theme.mediaQueries.biggerSmall} {
    right: 30%;
  }
  color: var(--color-mainDark);
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const ButtonLogin = styled(Button)`
  margin-bottom: 0rem;
  /* @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-top: 0rem;
    margin-bottom: 0rem;
    padding: 0.5rem 1rem;
  } */
`;

const MessageWrapper = styled.div`
  position: absolute;
  bottom: -10rem;
`;

export const SignupOption = styled.div`
  display: flex;
  align-self: center;
  margin-top: 15px;
  a {
    margin-left: 1rem;
  }

 /*  @media ${props => props.theme.mediaQueries.smallerSmallestHeight} {
    margin-top: ${({ register }) => (register ? "0rem" : "0.5rem")};
    margin-bottom: ${({ register }) => (register ? "0rem" : "0.5rem")};

    h4 {
      font-size: 1rem;
    }

    a {
      margin-left: 0.2rem;
      margin-top: 0rem;
    }
  } */
`;

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email.")
    .required("The email is required."),
  password: Yup.string()
    .required("The passoword is required.")
    .min(8, "Too short.")
});

const LoginForm = ({
  login,
  loading,
  error,
  errorAuth,
  cleanUp,
  twitterSignUp,
  googleSignUp,
  facebookSignUp,
  // location,
  history
}) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    return () => {
      cleanUp();
    };
  }, [cleanUp]);

  if (error === false) {
    history.push("/explore");
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: ""
      }}
      validationSchema={LoginSchema}
      onSubmit={async (values, { setSubmitting }) => {
        await login(values, sharedBetId);

        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <LoginSignupFormWrapper>
          {
            <StyledForm>
              <Heading
                size="h3"
                className="h3register"
                noMarginTop
                bold
                color="white"
              >
                Log in
              </Heading>

              <Field
                type="email"
                name="email"
                placeholder="Your email..."
                component={InputAuth}
              />
              <Field
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Your password..."
                showPassword
                component={InputAuth}
              />

              <Icon
                show={!showPassword}
                onClick={() => {
                  return setShowPassword(true);
                }}
                className="fas fa-eye"
              />
              {
                <Icon
                  show={showPassword}
                  onClick={() => {
                    return setShowPassword(false);
                  }}
                  className="fas fa-eye-slash"
                />
              }
              {
                <LoginOptions>
                  {/*   <RememberMe>
                    <Field
                      type="checkbox"
                      name="rememberme"
                      component={InputCheckbox}
                      noMargin
                    />
                    <Heading noMargin noMarginTop bold size="h5" color="main">
                      Remember&nbsp;me
                    </Heading>
                  </RememberMe> */}
                  {
                    <CustomLink link="/recover" color="white" noMarginBottom>
                      Forgot your password?
                    </CustomLink>
                  }
                </LoginOptions>
              }
              {
                <ButtonLogin
                  /* disabled={!isValid || isSubmitting} */
                  loading={loading ? "Logging in..." : null}
                  type="submit"
                  contain
                  auth
                  color="secondary"
                  classname="buttonlogin"
                  margin
                >
                  Login
                </ButtonLogin>
              }

              <Heading bold size="h3" color="main">
                - Or login with -
              </Heading>

              <SocialContainer>
                <Img
                  onClick={twitterSignUp}
                  src={twiiter}
                  dimensions={{ height: "2.5rem", width: "2.5rem" }}
                />
                <Img
                  onClick={googleSignUp}
                  src={google}
                  dimensions={{ height: "2.5rem", width: "2.5rem" }}
                />
                <Img
                  onClick={facebookSignUp}
                  src={facebook}
                  dimensions={{ height: "2.5rem", width: "2.5rem" }}
                />
              </SocialContainer>

              <MessageWrapper>
                <Message error show={error}>
                  {error}
                </Message>
              </MessageWrapper>

              <MessageWrapper>
                <Message error show={errorAuth}>
                  {errorAuth && errorAuth.message}
                </Message>
              </MessageWrapper>
            </StyledForm>
          }

          <SignupOption>
            <Heading bold size="h4" color="white" noMargin noMarginTop>
              Don't have an account ?
            </Heading>
            <CustomLink link="/signup" color="white" sharedBetId={sharedBetId}>
              Signup
            </CustomLink>
          </SignupOption>
        </LoginSignupFormWrapper>
      )}
    </Formik>
  );
};

const mapStateToProps = ({ auth, firebase }) => ({
  loading: auth.loading,
  error: auth.error,
  errorAuth: firebase.authError
});

const mapDispatchToProps = {
  login: actions.signIn,
  cleanUp: actions.clean,
  googleSignUp: actions.googleSignUp,
  facebookSignUp: actions.facebookSignUp,
  twitterSignUp: actions.twitterSignUp
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginForm));
