import styled from "styled-components";

const ItemLink = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: ${({ side }) => (side ? "3rem" : "0rem")};

  ::before {
    content: "${({ active }) => (active ? "\u2022" : "")}";
position
: absolute;
    font-size: 2rem;
   /*  margin-right: 0.6rem; */
    color: var(--color-secondaryColor);
height: 0rem;
 
    height: 0rem;
    top: 7%;
    left: -14%;
  
  }
`;

export default ItemLink;
